import { http } from '../config/vue-axios';

export async function getTiers(signal) {
  const response = await http.get(`/tiers.json`, {
    signal,
  });

  return response.data;
}

export async function getTierBySlug(tierSlug, signal) {
  const response = await http.get(`/tiers/${tierSlug}.json`, {
    signal,
  });

  return response.data;
}
