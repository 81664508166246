import { computed, toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from './query-keys';
import { http } from '@/config/vue-axios';

/**
 *
 * @param {number | Ref<number>} locationId
 * @param {string | Ref<string>} productType
 * @param {boolean | Ref<boolean>} selfServable
 * @param {boolean | Ref<boolean>} includesMediaAddOns
 * @param {number | Ref<number>} orderId
 */
export function useProductsSearch({
  locationId,
  productType,
  selfServable,
  includesMediaAddOns,
  orderId,
}) {
  return useQuery({
    queryKey: queryKeys.products({
      locationId,
      productType,
      selfServable,
      includesMediaAddOns,
      orderId,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (toValue(locationId)) params['location_id'] = toValue(locationId);
      if (toValue(productType)) params['product_type'] = toValue(productType);
      if (toValue(selfServable))
        params['self_servable'] = toValue(selfServable);
      if (toValue(includesMediaAddOns))
        params['includes_media_add_ons'] = toValue(includesMediaAddOns);
      if (toValue(orderId)) params['order_id'] = toValue(orderId);

      const response = await http.get(`products/search`, {
        params,
        signal,
      });

      return response.data;
    },
    enabled: computed(() => toValue(locationId) !== null),
  });
}
