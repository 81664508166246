<script setup>
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

import LiveAssetPrice from '../LiveAssetPrice.vue';
import LiveMobileFullscreenAsset from './LiveMobileFullscreenAsset.vue';
import LiveMobileDACommonActions from './LiveMobileDACommonActions.vue';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';

const liveShootStore = useLiveShootStore();
const { assetAccountId, assetId, isNotesVisible } = storeToRefs(liveShootStore);

const { title } = useMediaEditorDigitalAsset(assetAccountId, assetId);
</script>

<template>
  <div class="live-mobile-expanded-asset">
    <div class="live-mobile-expanded-asset__details">
      <div class="live-mobile-expanded-asset__asset-title">
        <h4 class="u-badge--small">{{ title }}</h4>
        <LiveAssetPrice />
      </div>

      <LiveMobileDACommonActions>
        <LiveMobileFullscreenAsset />
      </LiveMobileDACommonActions>
    </div>

    <DigitalAssetNotesList
      v-if="isNotesVisible"
      newest-first
      :digital-asset-id="assetId"
      :account-id="assetAccountId"
    >
    </DigitalAssetNotesList>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-mobile-expanded-asset {
  gap: 0.5rem;
  display: flex;
  padding: 1rem 0.5rem;
  min-height: 4.5rem;
  flex-direction: column;

  &__details {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__asset-title {
    display: flex;
    overflow: hidden;
    align-items: start;
    min-height: 1.75rem;
    flex-direction: column;
    color: variables.$gray-50;
    justify-content: space-between;

    h4 {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
