import { useReservation as useReservationQuery } from '@/queries/useReservation';
import { useReservationTags } from 'src/queries/reservation-tags/useReservationTags';
import {
  convertToWeekdayMMDDYYYYmonthAbr,
  convertToHHMM12,
  browserTimeZone,
  timeZoneAbbr,
} from 'src/lib/date-formatters';
import { computed } from 'vue';
import truncate from 'lodash/truncate';
import unescape from 'lodash/unescape';
import sortBy from 'lodash/sortBy';

export function useReservation(reservationId) {
  const {
    data: reservation,
    error,
    isLoading,
    isSuccess,
  } = useReservationQuery(reservationId, {
    enabled: computed(() => !!reservationId.value),
  });
  const { data: numberOfPhotoData } = useReservationTags(
    reservationId,
    'photo-quantity'
  );
  const numberOfPhotoOptions = computed(() => {
    return numberOfPhotoData.value?.options || [];
  });

  const { data: videoTypeData } = useReservationTags(
    reservationId,
    'video-type'
  );
  const videoTypeOptions = computed(() => {
    return videoTypeData.value?.options || [];
  });

  const currentLocationId = computed(() => reservation.value?.location?.id);
  const estimatedPhotosNeeded = computed(
    () => numberOfPhotoOptions.value?.find(tag => tag.selected)?.tag.title
  );
  const hasUnassignedLocation = computed(
    () => reservation.value?.location?.location_type === 'unassigned'
  );
  const isAnytime = computed(
    () => reservation.value?.reservation_type === 'anytime'
  );
  const isFree = computed(() => {
    return (
      reservation.value?.reservation_type === 'headshots_for_all' ||
      reservation.value?.reservation_type === 'internal' ||
      reservation.value?.reservation_type === 'surprise'
    );
  });
  const isDownPaymentZero = computed(() => {
    if (
      (reservation.value?.studio_access?.level == 'tier-one' ||
        reservation.value?.studio_access?.level == 'tier-two') &&
      reservation.value?.studio_access?.billing_cycle != null
    ) {
      return false;
    }

    return parseFloat(reservation.value?.down_payment_order_total) === 0;
  });
  const isHeadshotsForAll = computed(
    () => reservation.value?.reservation_type === 'headshots_for_all'
  );
  const downPaymentOrderTotal = computed(
    () => reservation.value?.down_payment_order_total
  );
  const isInStudio = computed(
    () => reservation.value?.reservation_type === 'in_studio'
  );
  const isInternal = computed(
    () => reservation.value?.reservation_type === 'internal'
  );
  const isSurprise = computed(
    () => reservation.value?.reservation_type === 'surprise'
  );
  const isContinuation = computed(() => !!reservation.value?.previous?.id);
  const isSuggested = computed(
    () => reservation.value?.draft_type === 'suggested_draft'
  );
  const isRetainer = computed(
    () => reservation.value?.draft_type === 'retainer_draft'
  );
  const isCompedDraft = computed(
    () => reservation.value?.draft_type === 'comped_draft'
  );
  const reservationLocation = computed(() => reservation.value?.location);
  const shootType = computed(() => {
    const type_tag = reservation.value?.tags?.find(t =>
      ['photo', 'video'].includes(t.title.toLowerCase())
    );
    if (type_tag) return type_tag.title.toLowerCase();
    return 'unknown';
  });

  const shootComplexity = computed(() => {
    return reservation.value?.shoot_complexity;
  });

  const isFirstBooking = computed(() => reservation.value?.is_first_booking);

  const isNoShow = computed(() => reservation.value?.is_no_show);

  const isWrapped = computed(() => reservation.value?.is_wrapped);

  const isPack = computed(() => {
    return !!reservation.value?.pack_configuration;
  });

  const packDetails = computed(() => {
    return reservation.value?.pack_configuration;
  });

  const isPromotionalPack = computed(() => {
    const promoPacks = ['try soona free', 'welcome back pack'];
    const isPromo = reservation?.value?.reservation_tags?.some(rt =>
      promoPacks.includes(rt.title)
    );

    return isPromo;
  });

  const lengthOfShoot = computed(() => {
    if (shootType.value === 'video') {
      let videoType = videoTypeOptions.value?.filter(
        vt => vt.selected === true
      );
      if (videoType?.length > 0) {
        return videoType[0].label === 'talking head'
          ? {
              display: 'up to 4 hours',
              displaySingular: '4 hour',
              duration: 14400,
            }
          : {
              display: 'up to 2 hours',
              displaySingular: '2 hour',
              duration: 7200,
            };
      } else if (isPack.value) {
        return {
          display: 'up to 2 hours',
          displaySingular: '2 hour',
          duration: 7200,
        };
      } else {
        return null;
      }
    }
    // the second clause if for the try soona free pack - can be removed when that isn't live anymore
    if (isHeadshotsForAll.value || isPromotionalPack?.value) {
      return {
        display: 'up to 30 min',
        displaySingular: '30 minute',
        duration: 1800,
      };
    }
    switch (
      numberOfPhotoOptions.value?.find(tag => tag.selected)?.tag.sub_title
    ) {
      case 'up to 1 hour shoot':
        return {
          display: 'up to 1 hour',
          displaySingular: '1 hour',
          duration: 3600,
        };
      case 'up to 2 hour shoot':
        return {
          display: 'up to 2 hours',
          displaySingular: '2 hour',
          duration: 7200,
        };
      case 'up to 3 hour shoot':
        return {
          display: 'up to 3 hours',
          displaySingular: '3 hour',
          duration: 10800,
        };
      case 'up to 4 hour shoot':
        return {
          display: 'up to 4 hours',
          displaySingular: '4 hour',
          duration: 14400,
        };
      default:
        return {
          display: 'up to 2 hours',
          displaySingular: '2 hour',
          duration: 7200,
        };
    }
  });
  const nameTruncated = computed(() =>
    truncate(unescape(reservation.value?.name), { length: 50 })
  );
  const reservationLineItems = computed(() =>
    sortBy(reservation.value?.reservation_line_items, ['product_id'])
  );

  const tagRequirements = computed(() => {
    return reservation.value?.reservation_tags?.flatMap(
      rt => rt.tag_requirements
    );
  });

  const requiredTags = computed(() => {
    return reservation.value?.reservation_tags?.flatMap(rt => rt.required_tags);
  });

  const requiredTagIds = computed(() => {
    return reservation.value?.reservation_tags?.flatMap(rt =>
      rt.required_tags?.map(t => t.id)
    );
  });

  const recommendedTags = computed(() => {
    return reservation.value?.reservation_tags?.flatMap(
      rt => rt.recommended_tags
    );
  });

  const recommendedTagIds = computed(() => {
    return reservation.value?.reservation_tags?.flatMap(rt =>
      rt.recommended_tags?.map(t => t.id)
    );
  });

  const recommendedTileVariation = computed(() => {
    return reservation.value?.recommended_tile_variation;
  });

  const photographer = computed(() => reservation.value?.photographer);

  const sceneTags = computed(() => {
    const tags = reservation.value?.reservation_tags?.filter(
      rt =>
        rt.tag_category_slug === 'scenes' ||
        rt.tag_category_slug === 'trend-sets' ||
        rt.tag_category_slug === 'video-trend-sets'
    );
    return tags;
  });
  const scheduledDateFormatted = computed(() =>
    convertToWeekdayMMDDYYYYmonthAbr(
      reservation.value?.scheduled_reservation_date
    )
  );
  const scheduledTime = computed(() => {
    if (reservation.value?.scheduled_reservation_date == null) {
      return null;
    }
    if (isInStudio.value || isHeadshotsForAll.value || isSurprise.value) {
      return (
        convertToHHMM12(
          reservation.value?.scheduled_reservation_date,
          reservation.value?.location.timezone
        ) +
        ' ' +
        timeZoneAbbr(reservation.value?.location.timezone)
      );
    }
    return (
      convertToHHMM12(reservation.value?.scheduled_reservation_date) +
      ' ' +
      browserTimeZone()
    );
  });

  const scheduledStartTime = computed(() => {
    if (reservation.value?.start === null) {
      return null;
    }
    if (isInStudio.value || isHeadshotsForAll.value || isSurprise.value) {
      return convertToHHMM12(
        reservation.value?.start,
        reservation.value?.location.timezone
      );
    }
    return convertToHHMM12(reservation.value?.start);
  });
  const scheduledEndTime = computed(() => {
    if (reservation.value?.end === null) {
      return null;
    }
    if (isInStudio.value || isHeadshotsForAll.value || isSurprise.value) {
      return (
        convertToHHMM12(
          reservation.value?.end,
          reservation.value?.location.timezone
        ) +
        ' ' +
        timeZoneAbbr(reservation.value?.location.timezone)
      );
    }
    return convertToHHMM12(reservation.value?.end) + ' ' + browserTimeZone();
  });

  const isLessThanTwoBusinessDaysBeforeShoot = computed(() => {
    if (reservation.value?.start === null) {
      return null;
    }

    return reservation.value?.is_less_than_two_business_days_before_shoot;
  });

  const targetPlatforms = computed(() => reservation.value?.target_platforms);

  const videoType = computed(() =>
    videoTypeOptions.value?.find(tag => tag.selected)?.tag.title.toLowerCase()
  );

  const PRO_SERVICE_SIZE_LOOKUP = {
    '1 - 5': 'pro-service-sm',
    '6 - 10': 'pro-service-md',
    '11 - 15': 'pro-service-lg',
    '16 - 25': 'pro-service-xl',
  };

  const VIDEO_SIZE_LOOKUP = {
    'How-To': 'pro-service-md-video',
    'ecommerce Spin': 'pro-service-md-video',
    Unboxing: 'pro-service-md-video',
    Testimonial: 'pro-service-md-video',
    'About Us': 'pro-service-md-video',
    'Social Promo': 'pro-service-md-video',
    'product b-roll': 'pro-service-md-video',
    'product unboxing': 'pro-service-md-video',
    'lifestyle showcase': 'pro-service-md-video',
    'how-to demo': 'pro-service-md-video',
    'product spin': 'pro-service-md-video',
    'talking head': 'pro-service-xl-video',
  };

  const proServiceSlugForSize = computed(() => {
    const sizeTag = reservation.value?.reservation_tags?.find(
      rt => rt.tag_category_slug === 'photo-quantity'
    );
    const videoTypeTag = reservation.value?.reservation_tags?.find(
      rt => rt.tag_category_slug === 'video-type'
    );

    if (videoTypeTag) return VIDEO_SIZE_LOOKUP[videoTypeTag.title];
    if (sizeTag) return PRO_SERVICE_SIZE_LOOKUP[sizeTag.title];

    return undefined;
  });

  const primaryContact = computed(() => {
    const contact_id = reservation.value?.point_of_contact_user_id;
    if (!contact_id) return undefined;

    return reservation.value.account.users.find(u => u.id === contact_id);
  });

  const isConcierge = computed(() => {
    return !reservation.value?.account || primaryContact.value?.soft_sign_up;
  });

  const isCompleted = computed(() => {
    return reservation.value?.status === 'completed';
  });

  const isEditsRequired = computed(() => {
    return reservation.value?.status === 'edits_required';
  });

  const isInProgress = computed(() => {
    return reservation.value
      ? reservation.value.status === 'in_progress'
      : false;
  });

  const isPreShoot = computed(() => {
    return reservation.value ? reservation.value.status === 'preshoot' : false;
  });

  const isPendingShotlist = computed(() => {
    return reservation.value
      ? reservation.value.status === 'pending_shotlist'
      : false;
  });

  const notStarted = computed(
    () => isPreShoot.value || isPendingShotlist.value
  );

  const isSendingPackage = computed(
    () =>
      (reservation.value?.package_option &&
        reservation.value?.package_option !== 'no_products_to_send') ??
      false
  );

  const downPaymentDiscount = computed(() => {
    return reservation.value?.down_payment_order?.discount;
  });

  // pro services
  const groupedPurchasedProServices = computed(() => {
    let services = {};
    reservation.value?.purchased_pro_services.forEach(service => {
      if (services[service.name]) {
        services[service.name].quantity += service.quantity;
      } else {
        services[service.name] = { ...service };
        services[service.name].quantity = service.quantity;
      }
    });
    return services;
  });

  const hasContentCreatorService = computed(() => {
    return (
      reservation.value?.purchased_pro_services_names?.some(psp =>
        psp.name.includes('content creator')
      ) ?? false
    );
  });

  const hasProServices = computed(() => {
    return (
      reservation.value?.purchased_pro_services &&
      reservation.value?.purchased_pro_services.length > 0
    );
  });

  const hasPickedProServicesPreferences = computed(() => {
    if (!reservation.value?.purchased_pro_services) {
      return true;
    }
    let modelServices = [];
    let modelProviderChoices = [];
    reservation.value?.purchased_pro_services.forEach(ps => {
      if (ps.typeform && ps.typeform.submissionId !== null) {
        modelProviderChoices.push(ps);
      }
      if (ps.name.includes('model')) {
        for (let i = 1; i <= ps.quantity; i++) {
          modelServices.push(ps.id);
        }
      }
    });
    reservation.value?.reservation_line_items.forEach(rli => {
      if (modelServices.includes(rli.product_id)) {
        rli.pro_service_requirements.forEach(requirement => {
          if (requirement.choices.length > 0) {
            modelProviderChoices.push(rli);
          }
        });
      }
    });
    return modelProviderChoices.length >= modelServices.length;
  });

  const totalProServices = computed(() => {
    let total = 0;

    if (!reservation.value?.purchased_pro_services) return total;

    reservation.value?.purchased_pro_services.forEach(service => {
      total += service.quantity;
    });

    return total;
  });
  // end pro services

  const hasAddOnOrders = computed(
    () => reservation.value?.add_on_orders?.length > 0
  );

  // re-edits
  const activeReEditsCollectionId = computed(() => {
    return reservation.value?.active_re_edits_collection_id ?? null;
  });
  // end re-edits

  const hasSchedulingPreference = computed(() => {
    return reservation.value?.has_scheduling_preference;
  });

  const reservationAccountId = computed(() => {
    return reservation.value?.account_id;
  });

  const shotListNote = computed(() => reservation.value?.shot_list_note);

  return {
    activeReEditsCollectionId,
    currentLocationId,
    downPaymentDiscount,
    estimatedPhotosNeeded,
    error,
    hasAddOnOrders,
    hasContentCreatorService,
    hasPickedProServicesPreferences,
    hasProServices,
    hasSchedulingPreference,
    hasUnassignedLocation,
    groupedPurchasedProServices,
    isAnytime,
    isConcierge,
    isCompedDraft,
    isCompleted,
    isContinuation,
    isEditsRequired,
    isFirstBooking,
    isFree,
    isDownPaymentZero,
    isHeadshotsForAll,
    isInProgress,
    isInStudio,
    isInternal,
    isLoading,
    isLessThanTwoBusinessDaysBeforeShoot,
    isNoShow,
    isPack,
    isPendingShotlist,
    isPreShoot,
    isSendingPackage,
    isSuccess,
    isSuggested,
    isRetainer,
    isSurprise,
    isWrapped,
    downPaymentOrderTotal,
    lengthOfShoot,
    nameTruncated,
    notStarted,
    proServiceSlugForSize,
    reservation,
    reservationLineItems,
    requiredTags,
    tagRequirements,
    packDetails,
    photographer,
    requiredTagIds,
    recommendedTagIds,
    recommendedTags,
    recommendedTileVariation,
    reservationAccountId,
    reservationLocation,
    targetPlatforms,
    sceneTags,
    scheduledDateFormatted,
    scheduledTime,
    scheduledStartTime,
    scheduledEndTime,
    shootComplexity,
    shootType,
    shotListNote,
    totalProServices,
    videoType,
    VIDEO_SIZE_LOOKUP,
  };
}
