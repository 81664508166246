<script setup>
import { computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import useActionBarMediaQuery from '../reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

const props = defineProps({
  buttonCopy: {
    type: String,
    required: true,
  },
  buttonIcon: {
    type: String,
    required: true,
  },
  error: {
    type: String,
    default: null,
  },
  isUpdating: {
    type: Boolean,
    required: true,
  },
  selectedProducts: {
    type: Array,
    required: true,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['cancelMultiSelect', 'actionSelected']);

const { actionBarIsMobile } = useActionBarMediaQuery();
const numberOfProductsSelected = computed(() => props.selectedProducts.length);

const cancel = () => {
  emit('cancelMultiSelect');
};

const actionSelected = () => {
  emit('actionSelected');
};
</script>

<template>
  <SoonaActionBar
    :display="numberOfProductsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #close-button>
      <SoonaTooltip placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="setRef"
            variation="icon-transparent"
            :aria-describedby="ariaDescribedby"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @on-click="cancel"
          >
            <SoonaIcon name="xmark" />
            <span class="u-visually-hidden">cancel</span>
          </SoonaButton>
        </template>

        <template #tooltip-content>cancel</template>
      </SoonaTooltip>
    </template>

    <template #content>
      <p v-if="!error" class="multiselect-action-bar__text u-label--heavy">
        {{ numberOfProductsSelected }} product{{
          numberOfProductsSelected > 1 ? 's' : ''
        }}
        selected
      </p>
      <SoonaError v-if="error" class="multiselect-action-bar__error">
        {{ error }}
      </SoonaError>
    </template>

    <template #actions>
      <SoonaButton
        v-if="!error"
        :disabled="isUpdating"
        :variation="actionBarIsMobile ? 'icon-primary' : 'primary'"
        @on-click="actionSelected"
      >
        <SoonaIcon :name="buttonIcon" />
        <span :class="{ 'u-visually-hidden': actionBarIsMobile }">
          {{ buttonCopy }}
        </span>
      </SoonaButton>
    </template>
  </SoonaActionBar>
</template>
