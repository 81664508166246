<script setup>
import { ref, computed } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useRegexHelper } from 'src/composables/useRegexHelper';
import SoonaToggle from 'src/components/ui_library/SoonaToggle.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['close']);
const { fileNameRegex } = useRegexHelper();
const accountId = computed(() => props.accountId);
const selectedAssets = computed(() => props.selectedAssets);
const showCustomFileNameField = ref(false);
const resolutionOptions = ref([
  {
    value: 'web',
    label: 'web',
    selected: true,
    description: 'up to 2048x2048',
  },
  {
    value: 'hi_res',
    label: 'hi-res',
    selected: false,
    description: 'full resolution',
  },
  {
    value: 'RAW',
    label: 'raw',
    selected: false,
    description: 'typically .CR2 files (when available)',
  },
]);

const selectedResolutions = computed(() => {
  return resolutionOptions.value.filter(x => x.selected === true);
});
const customFileName = ref('');
const downloadLink = computed(() => {
  const url = `/api/accounts/${accountId.value}/digital_assets/bulk_download.zip`;
  const params = new URLSearchParams();

  selectedAssets.value.forEach(digitalAsset => {
    params.append('digital_asset_ids[]', digitalAsset.id);
  });

  selectedResolutions.value.forEach(resolution => {
    params.append('resolutions[]', resolution.value);
  });

  if (customFileName.value) {
    params.append('custom_file_name', customFileName.value);
  }

  return `${url}?${params.toString()}`;
});

const handleDownload = (e, close) => {
  e.preventDefault();
  window.open(downloadLink.value, '_blank');
  close();
};

const fileNameExample = computed(() => {
  if (customFileName.value !== '') {
    return `${customFileName.value}-0003.jpeg`;
  } else {
    return `${selectedResolutions.value[0]?.value}-0003.jpeg`;
  }
});

const validDownloadOptions = computed(() => {
  if (selectedAssets.value.length > 100) {
    return false;
  }

  if (selectedResolutions.value.length < 1) {
    return false;
  }

  if (customFileName.value === '') {
    return true;
  }

  return (
    fileNameRegex.test(customFileName.value) &&
    customFileName.value.length <= 50
  );
});

const resetCustomFileNameToggle = active => {
  if (!active) customFileName.value = '';
};
</script>

<template>
  <SoonaDialog @close="emit('close')">
    <template #heading>
      <header>
        <h2 class="u-title--heavy">download options</h2>
        <p class="u-small--regular">
          <span class="u-small--heavy">download limit: </span>up to 100 assets
          per download session.
        </p>
        <p class="u-small--regular">
          <span class="u-small--heavy">file size limit: </span>each download
          must not exceed 1GB.
        </p>
      </header>
    </template>
    <template #default="{ close }">
      <SoonaForm id="download-form" @submit="e => handleDownload(e, close)">
        <h3 class="u-subheader--heavy form-field-title">resolution</h3>
        <div
          v-for="option in resolutionOptions"
          :key="option.value"
          class="checkbox-wrapper"
        >
          <label class="checkbox">
            <input v-model="option.selected" type="checkbox" />
            <span class="checkbox__content">
              <span class="checkbox__title">{{ option.label }}</span>
              <span class="checkbox__description">{{
                option.description
              }}</span>
            </span>
          </label>
        </div>
        <div class="toggle-wrapper">
          <h3 class="u-subheader--heavy form-field-title">custom filenames</h3>
          <SoonaToggle
            v-model="showCustomFileNameField"
            type="switch"
            @update:model-value="newVal => resetCustomFileNameToggle(newVal)"
          />
        </div>
        <div v-if="showCustomFileNameField">
          <p class="u-small--regular custom-file-name-rules">
            custom naming convention can only use letters, numbers, and -_
            symbols.
          </p>
          <SoonaTextfield
            v-model="customFileName"
            label="add custom naming convention"
            name="custom naming convention"
            :required="false"
            autocomplete="off"
            maxlength="50"
            placeholder="e.g. my-custom-filename"
            :rules="['filename']"
            :show-counter="false"
            class="custom-textfield-padding"
            cypress-name="input-custom-file-name"
          />
          <div v-if="selectedResolutions.length > 0 || customFileName !== ''">
            <p class="form-field-subtitle">example output</p>
            <p>{{ fileNameExample }}</p>
          </div>
        </div>
      </SoonaForm>
    </template>
    <template #footer="{ close }">
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        form="download-form"
        type="submit"
        data-cypress="button-confirm-download-options"
        :disabled="!validDownloadOptions"
      >
        download ({{ selectedAssets.length }})
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }
}

.organize-menu {
  margin-left: auto;
}

.toggle-wrapper {
  display: flex;
  margin-top: 2rem;

  h3 {
    margin-right: 0.5rem;
  }
}

.form-field-title {
  color: variables.$black-default;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 0.4375rem;
  label {
    padding-top: 0.1875rem;
  }
  .select-clear-button {
    min-width: initial;
  }
}

.form-field-subtitle {
  @include variables_fonts.u-label--heavy;
}

.custom-file-name-rules {
  margin-bottom: 1rem;
}

.custom-textfield-padding {
  padding-bottom: 1rem;
}

.checkbox-wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  input[type='checkbox'] {
    accent-color: variables.$periwink-blue-70;
    height: 1.125rem;
    width: 1.125rem;
    margin-right: 0.5rem;
  }

  &__content {
    display: flex;
    flex-direction: column;
  }

  &__title {
    font-weight: bold;
    font-size: 0.875rem;
    color: #000;
  }

  &__description {
    font-size: 12px;
    color: #6b7280;
  }
}
</style>
