<script setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useQueryClient } from '@tanstack/vue-query';

import { queryKeys } from '@/queries/query-keys';
import { useLiveShootStore } from '../useLiveShootStore';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useRecommendAnimatedCollection } from '@/queries/animated_collections/useRecommendAnimatedCollection';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';

const { addToast } = useSoonaToast();
const queryClient = useQueryClient();
const liveShootStore = useLiveShootStore();
const { assetId, assetAccountId } = storeToRefs(liveShootStore);

const showRecommendGifModal = ref(false);

const {
  isGif,
  reservationId,
  animatedCollectionId,
  gifHasAlreadyBeenRecommended,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { mutate: recommendAnimatedCollection, isPending: isRecommendingGif } =
  useRecommendAnimatedCollection(assetAccountId, animatedCollectionId);

const recommendGif = () => {
  if (!isGif.value) return;

  recommendAnimatedCollection(
    {},
    {
      onError: () => {
        addToast(`error recommending gif`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
      onSuccess: () => {
        showRecommendGifModal.value = false;

        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationDigitalAssetByDigitalAssetId(
            reservationId,
            assetId
          ),
        });
      },
    }
  );
};

const animatedCollectionUrl = computed(() => {
  const queries = reservationId.value
    ? `?reservation_id=${reservationId.value}`
    : '';

  return `/account/${assetAccountId.value}/animation/${animatedCollectionId.value}${queries}`;
});
</script>
<template>
  <SoonaButton
    v-if="isGif"
    class="recommend-animation"
    variation="secondary-gray"
    :loading="isRecommendingGif"
    :disabled="isRecommendingGif || gifHasAlreadyBeenRecommended"
    @click="() => (showRecommendGifModal = true)"
  >
    {{ gifHasAlreadyBeenRecommended ? 'recommended' : 'recommend gif' }}
  </SoonaButton>
  <!-- edit gif (temporary placement) -->
  <SoonaButton
    v-if="isGif"
    element="router-link"
    :to="animatedCollectionUrl"
    variation="secondary-gray"
    size="large"
  >
    edit gif
  </SoonaButton>

  <SoonaDialog
    v-if="showRecommendGifModal"
    @close="() => (showRecommendGifModal = false)"
  >
    <template #heading>holler at the client</template>
    <div class="body">
      <p class="explanation">
        an email will be sent to the client to let them know about this exciting
        new creation! click 'email' if you'd like them to be notified or cancel
        if you are not quite ready yet.
      </p>
    </div>
    <template #footer="{ close }">
      <SoonaButton
        variation="tertiary"
        data-cypress="button-dialog-cancel"
        @on-click="close"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        data-cypress="button-dialog-confirm"
        @on-click="recommendGif"
      >
        {{ isRecommendingGif ? 'recommending' : 'email' }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>
