<script setup>
import { computed } from 'vue';
const props = defineProps({
  title: {
    type: String,
    required: false,
    default: '',
  },
  textColor: {
    type: String,
    required: false,
    default: '#000000',
  },
  backgroundColor: {
    type: String,
    required: false,
    default: '#FFFFFF',
  },
  fontSize: {
    type: String,
    required: false,
    default: '0.625rem',
  },
  fontWeight: {
    type: String,
    required: false,
    default: 'bold',
    validator(value) {
      return ['light', 'normal', 'bold', 'bolder'].includes(value);
    },
  },
  borderColor: {
    type: String,
    required: false,
    default: '',
  },
  lowercase: {
    type: Boolean,
    default: false,
  },
  paddingSize: {
    type: String,
    default: null,
  },
  type: {
    type: String,
    required: false,
    default: 'flag',
    validator(value) {
      return ['flag', 'pill'].includes(value);
    },
  },
  isPizzazz: {
    type: Boolean,
    default: false,
  },
});

const textWeight = computed(() => {
  switch (props.fontWeight) {
    case 'light':
      return '300';
    case 'normal':
      return '400';
    case 'bold':
      return '700';
    case 'bolder':
      return '900';
    default:
      return '400';
  }
});

const isPizzazz = computed(() => props.isPizzazz);

const paddingSize = computed(() => {
  if (props.type === 'pill') {
    return props.paddingSize ?? '0.25rem 0.5rem';
  }
  return props.paddingSize ?? '0.25rem 0.375rem';
});
</script>
<template>
  <div
    class="soona-flag"
    :class="{
      'soona-flag__pill': type === 'pill',
      'soona-flag__lowercase': lowercase,
      'soona-flag--pizzazz': isPizzazz,
    }"
  >
    <slot name="icon-left" />
    <template v-if="title">
      <span class="soona-flag__title">{{ title }}</span>
    </template>
    <slot v-else name="title" />
    <slot name="icon-right" />
  </div>
</template>

<style lang="scss" scoped>
.soona-flag {
  --flag-color: v-bind('textColor');
  --flag-background-color: v-bind('backgroundColor');
  --flag-border-color: v-bind('borderColor');
  display: flex;
  width: fit-content;
  height: fit-content;
  gap: 0.25rem;
  box-sizing: border-box;
  text-transform: uppercase;
  color: var(--flag-color);
  background-color: var(--flag-background-color);
  font-size: v-bind('fontSize');
  font-weight: v-bind('textWeight');
  letter-spacing: 0.0625rem;
  padding: v-bind('paddingSize');
  border-radius: 0.1875rem;
  align-items: center;
  border: 0.0625rem solid var(--flag-border-color);

  &__lowercase {
    .soona-flag__title {
      text-transform: lowercase;
    }
  }

  &--pizzazz {
    background-image: linear-gradient(62.59deg, #3c51f1 2.88%, #f03742 98.95%);
  }

  &__pill {
    border-radius: 1.25rem;
  }

  &__title {
    height: 1rem;
    line-height: 1.0625rem;
    margin-right: 0;
  }
}
</style>
