<script setup>
import { ref, computed, toRefs, watch } from 'vue';
import { isEmptyValue } from '@/lib/global/is-empty-value';
import SoonaToggle from '@/components/ui_library/SoonaToggle.vue';
import { usePrices } from '@/queries/prices/usePrices';

const props = defineProps({
  fastPassPrice: {
    required: true,
    type: [Object, null],
    default: () => ({}),
  },
});

const emit = defineEmits(['update']);

const { fastPassPrice } = toRefs(props);

const enterpriseIncluded = ref(true);
const fastPassIncluded = ref(!isEmptyValue(fastPassPrice.value));

const { data: enterpriseData } = usePrices({
  priceType: 'recurring',
  productName: 'enterprise',
  productType: 'subscription',
  recurringInterval: 'month',
});
const enterprisePriceObj = computed(() => enterpriseData.value?.prices?.[0]);

const { data: fastPassData } = usePrices({
  priceType: 'recurring',
  productName: 'intro membership',
  productType: 'subscription',
  recurringInterval: 'month',
});
const fastPassPriceObj = computed(() => fastPassData.value?.prices?.[0]);

const handleUpdate = () => {
  emit('update', {
    enterprisePrice: enterpriseIncluded.value ? enterprisePriceObj.value : null,
    fastPassPrice: fastPassIncluded.value ? fastPassPriceObj.value : null,
  });
};

watch(enterprisePriceObj, () => {
  emit('update', {
    enterprisePrice: enterpriseIncluded.value ? enterprisePriceObj.value : null,
    fastPassPrice: null,
  });
});
</script>

<template>
  <div class="create-subscription-info">
    <div>
      <p class="u-title--heavy">Subscription info</p>
      <p class="u-label--regular create-subscription-info__subtitle">
        Selecting ‘Yes’ will display the inclusion on the contract overview, but
        the membership must still be activated through crew tools.
      </p>
    </div>
    <div>
      <p class="create-subscription-info__label">Enterprise included?</p>

      <fieldset class="create-subscription-info__radio-group">
        <SoonaToggle
          v-model="enterpriseIncluded"
          type="radio"
          label="Yes"
          name="enterprise-included"
          :native-value="true"
          @update:model-value="handleUpdate"
        />
        <SoonaToggle
          v-model="enterpriseIncluded"
          type="radio"
          label="No"
          name="enterprise-included"
          :native-value="false"
          @update:model-value="handleUpdate"
        />
      </fieldset>
    </div>
    <div>
      <p class="create-subscription-info__label">Fast Pass included?</p>
      <fieldset class="create-subscription-info__radio-group">
        <SoonaToggle
          v-model="fastPassIncluded"
          type="radio"
          label="Yes"
          name="fastpass-included"
          :native-value="true"
          @update:model-value="handleUpdate"
        />
        <SoonaToggle
          v-model="fastPassIncluded"
          type="radio"
          label="No"
          name="fastpass-included"
          :native-value="false"
          @update:model-value="handleUpdate"
        />
      </fieldset>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.create-subscription-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__subtitle {
    color: variables.$gray-60;
    padding-top: 0.5rem;
  }

  &__label {
    @include variables_fonts.u-label--heavy;

    margin-bottom: 0.5rem;
  }

  &__radio-group {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
      padding-bottom: 0.5rem;
    }
  }
}
</style>
