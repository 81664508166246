<template>
  <router-link
    v-if="element === 'router-link'"
    v-slot="{ href: slotHref, navigate }"
    :to="to"
    :custom="true"
    :replace="replace"
  >
    <a
      v-bind="$attrs"
      ref="ref"
      :class="classNames"
      :href="slotHref"
      @click="onRouterLinkClick($event, navigate)"
    >
      <span class="soona-button__content">
        <slot name="default" />
      </span>

      <beat-loader
        v-if="isLoading || loading"
        class="button-loader"
        :color="loaderColor"
        :size="isIcon ? '0.5em' : '0.75em'"
      />
    </a>
  </router-link>
  <component
    v-bind="$attrs"
    :is="element"
    v-else
    ref="ref"
    :href="element === 'a' && !disabled ? href : null"
    :type="element === 'button' ? type : undefined"
    :class="classNames"
    :disabled="
      element === 'button' ? disabled || isLoading || loading : undefined
    "
    tabindex="0"
    @click="event => !disabled && click(event)"
    @keydown="event => !disabled && onKeydown && onKeydown(event)"
  >
    <span class="soona-button__content">
      <slot name="default" />
    </span>

    <beat-loader
      v-if="isLoading || loading"
      class="button-loader"
      :color="loaderColor"
      :size="isIcon ? '0.5em' : '0.75em'"
    />
  </component>
</template>

<script>
import { computed } from 'vue';
import { mapMutations } from 'vuex';
import * as types from 'src/store/mutation-types';

export default {
  expose: ['focus', 'elementRef', 'contains'],
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    element: {
      default: 'button',
      type: String,
      validator: function (value) {
        return ['button', 'a', 'router-link'].includes(value);
      },
    },
    href: {
      default: '#',
      type: String,
    },
    loaderColor: {
      default: 'var(--soona-button-loader-color, #fff)',
      required: false,
      type: String,
    },
    loading: {
      default: false,
      required: false,
      type: Boolean,
    },
    isSubmit: {
      default: false,
      type: Boolean,
    },
    onClick: {
      default: undefined,
      type: Function,
    },
    onKeydown: {
      default: undefined,
      type: Function,
    },
    replace: {
      default: false,
      type: Boolean,
    },
    size: {
      default: 'large',
      type: String,
      validator: function (value) {
        return ['large', 'medium', 'small'].includes(value);
      },
    },
    to: {
      default: undefined,
      type: [String, Object],
    },
    type: {
      type: String,
      default: rawProps => {
        return rawProps.element === 'button' ? 'button' : undefined;
      },
      validator: function (value, rawProps) {
        if (rawProps.element === 'button') {
          return ['button', 'reset', 'submit'].includes(value);
        }

        // mime type on a link
        return !value || (typeof value === 'string' && value.includes('/'));
      },
    },
    variation: {
      default: 'primary',
      type: String,
      validator: function (value) {
        return [
          'filter',
          'icon-gray-outline',
          'icon-plain-gray',
          'icon-primary',
          'icon-transparent',
          'icon-inherit',
          'pizzazz',
          'primary',
          'secondary-black',
          'secondary-gray',
          'secondary-periwink',
          'secondary-transparent',
          'solid-black',
          'tertiary',
          'tertiary-white',
        ].includes(value);
      },
    },
  },
  emits: ['onClick'],
  setup(props, { emit }) {
    const onRouterLinkClick = (event, navigate) => {
      emit('onClick', event);

      navigate(event);
    };

    const isIcon = computed(() => props.variation.startsWith('icon-'));

    return {
      isIcon,
      onRouterLinkClick,
    };
  },
  data: () => ({
    isLoading: false,
  }),
  computed: {
    elementRef() {
      return this.$refs.ref;
    },
    classNames() {
      let classes = `soona-button soona-button--${this.variation} soona-button--${this.size}`;

      if (this.isLoading || this.loading) {
        classes += ' soona-button--loading';
      }

      return classes;
    },
  },
  methods: {
    ...mapMutations('errors', [types.RESET_ERRORS]),
    async click(event) {
      this.$emit('onClick', event);

      if (
        !this.isSubmit &&
        this.onClick &&
        typeof this.onClick?.then === 'function' &&
        typeof this.onClick?.catch === 'function'
      ) {
        // this catches unhandled promise rejections, can override in parent file's onClick method
        return this.onClick(event).catch(() => {});
      } else if (!this.isSubmit && this.onClick) return this.onClick(event);

      if (this.onClick && !this.isLoading) {
        this.RESET_ERRORS();

        const result = this.onClick(event);
        let isComplete = false;
        if (result && result.then) {
          result
            .then(() => {
              isComplete = true;
              var self = this;
              //we delay this for 500 ms so if we're redirecting on success the button doesn't re-enable before the new page
              setTimeout(() => (self.isLoading = false), 500);
            })
            .catch(() => {
              isComplete = true;
              this.isLoading = false;
            });
          if (!isComplete) {
            this.isLoading = true;
          }
        }
      }
    },
    contains(node) {
      return this.$refs.ref.contains(node);
    },
    focus() {
      this.$refs.ref.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

@property --pizzazz-grad-angle {
  syntax: '<angle>';
  inherits: false;
  initial-value: 62.59deg;
}
@property --pizzazz-grad-color-1-stop {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 2.88%;
}
@property --pizzazz-grad-color-2-stop {
  syntax: '<percentage>';
  inherits: false;
  initial-value: 98.95%;
}

.soona-button {
  background-color: transparent;
  border: 0.0625rem solid transparent;
  border-radius: 0.3125rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition:
    background-color 0.1s ease-out,
    color 0.1s ease-out,
    border-color 0.1s ease-out,
    color 0.1s ease-out,
    --pizzazz-grad-angle 0.1s ease-out,
    --pizzazz-grad-color-1-stop 0.1s ease-out,
    --pizzazz-grad-color-2-stop 0.1s ease-out;
  user-select: none;
  outline-width: 0.125rem;
  outline-color: variables.$periwink-blue-60;
  /* clip buttons that overflow */
  overflow: hidden;
  color: variables.$white-default;
  --soona-button-loader-color: #{variables.$white-default};

  &__content {
    display: inline-flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;

    .soona-button--large & {
      gap: 0.375rem;
    }

    /*
     * prevent children from shrinking (notably icons) when overflowing.
     * this should make text wrap to get more space, and keep icons and
     * things inline
     */
    & > :slotted(*) {
      flex: 0 0 auto;
    }
  }

  &:focus-visible {
    outline-style: solid;
  }

  /* backwards compatibility, remove when old icons are not used */
  path {
    fill: currentColor;
    stroke: currentColor;
  }

  &:disabled {
    &:not(.soona-button--loading) {
      background: variables.$gray-20;
      border-color: variables.$gray-20;
      color: variables.$gray-50;
      cursor: not-allowed;

      & :slotted(svg) {
        color: variables.$gray-50;
      }

      &:hover,
      &:active {
        background: variables.$gray-20;
        border-color: variables.$gray-20;
        color: variables.$gray-50;
      }
    }
  }

  &--loading {
    cursor: wait;

    & .soona-button__content {
      opacity: 0;
    }
  }

  > .button-loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    display: inline-flex;
  }

  /* sizes */
  /*
   * everything is a little under the even sizes to account for the border,
   * which adds 0.0625rem of space on each side
   */
  &--secondary-black,
  &--secondary-gray,
  &--secondary-periwink,
  &--secondary-transparent {
    &.soona-button--large {
      @include variables_fonts.u-body--heavy;
      padding: 0.4375rem 0.9375rem;

      & :slotted(svg) {
        height: 1.25rem;
        width: 1.25rem;
        margin: -0.0625rem;
      }
    }

    &.soona-button--medium {
      @include variables_fonts.u-label--heavy;
      padding: 0.375rem 0.9375rem;

      & :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }

    &.soona-button--small {
      @include variables_fonts.u-label--heavy;
      padding: 0.125rem 0.6875rem;

      & :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  /* variations without borders */
  &--primary,
  &--solid-black,
  &--pizzazz {
    border: none;

    &.soona-button--large {
      @include variables_fonts.u-body--heavy;
      padding: 0.5rem 1rem;

      & :slotted(svg) {
        height: 1.25rem;
        width: 1.25rem;
        margin: -0.0625rem;
      }
    }

    &.soona-button--medium {
      @include variables_fonts.u-label--heavy;
      padding: 0.4375rem 1rem;

      & :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }

    &.soona-button--small {
      @include variables_fonts.u-label--heavy;
      padding: 0.1875rem 0.75rem;

      & :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  &--filter {
    &.soona-button--large {
      padding: 0.4375rem 0.6875rem;
    }

    &.soona-button--medium {
      padding: 0.375rem 0.6875rem;
    }

    &.soona-button--small {
      padding: 0.1875rem 0.5rem;
    }
  }

  &--filter,
  &--tertiary {
    &.soona-button--large {
      @include variables_fonts.u-body--regular;

      & :slotted(svg) {
        height: 1.25rem;
        width: 1.25rem;
      }
    }

    &.soona-button--medium {
      @include variables_fonts.u-label--regular;

      & :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }

    &.soona-button--small {
      @include variables_fonts.u-small--regular;

      & :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  &[class*='soona-button--icon'] {
    /* prevent a button from shrinking and becoming non-circle when used in flex */
    flex-shrink: 0;
    border-radius: 10rem;

    /* text isn't intended to be used with these variants, but it's not too
     * difficult to add here, and makes the buttons nice and even if it's ever
     * the case.
     */
    &.soona-button--large {
      padding: 0.4375rem;
      /* multiplied to equal 1.5 */
      font-size: 1rem;
      line-height: 1.5;

      :slotted(svg) {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
    &.soona-button--medium {
      padding: 0.3125rem;
      /* multiplied to equal 1.25 */
      font-size: 0.875rem;
      line-height: 1.4286;

      :slotted(svg) {
        height: 1.25rem;
        width: 1.25rem;
      }
    }
    &.soona-button--small {
      padding: 0.1875rem;
      /* multiplied to equal 1 */
      font-size: 0.75rem;
      line-height: 1.3333;

      :slotted(svg) {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  /* variations */
  &--primary {
    background-color: variables.$friendly-red-50;

    &:not(:disabled):hover {
      background-color: variables.$friendly-red-60;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$friendly-red-70;
    }
  }

  &--filter {
    background-color: variables.$white-default;
    border-color: variables.$gray-30;
    border-radius: 10rem;
    color: variables.$black-default;
    --soona-button-loader-color: #{variables.$black-default};

    &:not(:disabled):hover {
      background-color: variables.$friendly-red-10;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$friendly-red-20;
    }

    &[aria-pressed='true'] {
      border-color: variables.$friendly-red-40;
    }
  }

  &--secondary-black {
    background-color: variables.$white-default;
    border-color: variables.$black-default;
    color: variables.$black-default;
    --soona-button-loader-color: #{variables.$black-default};

    &:not(:disabled):hover {
      background-color: variables.$gray-20;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$gray-30;
    }
  }

  &--secondary-gray {
    background-color: variables.$white-default;
    border-color: variables.$gray-30;
    color: variables.$black-default;
    --soona-button-loader-color: #{variables.$black-default};

    &:not(:disabled):hover {
      background-color: variables.$gray-20;
      border-color: variables.$gray-40;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$gray-30;
      border-color: variables.$gray-50;
    }
  }

  &--secondary-periwink {
    background-color: variables.$white-default;
    border-color: variables.$gray-30;
    color: variables.$black-default;
    --soona-button-loader-color: #{variables.$black-default};

    &:not(:disabled)[data-has-value='true'] {
      background-color: variables.$periwink-blue-20;
      border-color: variables.$periwink-blue-40;
    }

    &:not(:disabled):hover {
      background-color: variables.$periwink-blue-20;
      border-color: variables.$periwink-blue-20;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$periwink-blue-30;
      border-color: variables.$periwink-blue-30;
    }

    &:not(:disabled):active,
    &[aria-expanded='true'] {
      background-color: variables.$periwink-blue-20;
      border-color: variables.$periwink-blue-20;
    }
  }

  &--secondary-transparent,
  &--icon-transparent {
    background-color: transparent;
    border-color: transparent;

    &:not(:disabled):hover {
      background-color: variables.$white-translucent-12;
      border-color: transparent;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$white-translucent-35;
      border-color: transparent;
    }
  }

  &--solid-black {
    background-color: variables.$black-default;
    border-color: variables.$black-default;

    &:not(:disabled):hover {
      background-color: rgba(0, 0, 0, 0.83);
      border-color: rgba(0, 0, 0, 0.83);
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: rgba(0, 0, 0, 0.68);
      border-color: rgba(0, 0, 0, 0.68);
    }
  }

  &--pizzazz {
    --pizzazz-grad-angle: 62.59deg;
    --pizzazz-grad-color-1-stop: 2.88%;
    --pizzazz-grad-color-2-stop: 98.95%;

    background-image: linear-gradient(
      var(--pizzazz-grad-angle),
      variables.$periwink-blue-70 var(--pizzazz-grad-color-1-stop),
      variables.$roses-60 var(--pizzazz-grad-color-2-stop)
    );

    &:not(:disabled):hover {
      --pizzazz-grad-angle: 67.25deg;
      --pizzazz-grad-color-1-stop: 2.94%;
      --pizzazz-grad-color-2-stop: 214.86%;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      --pizzazz-grad-angle: 68.26deg;
      --pizzazz-grad-color-1-stop: 2.95%;
      --pizzazz-grad-color-2-stop: 431.76%;
    }
  }

  &--tertiary {
    background-color: transparent;
    color: variables.$black-default;
    --soona-button-loader-color: #{variables.$black-default};
    padding: 0;
    text-decoration: underline;

    /* for tertiary buttons, disable underline if there is an icon */
    &:has(svg) {
      text-decoration: none;
    }

    &:not(:disabled):hover {
      color: variables.$periwink-blue-70;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      color: variables.$periwink-blue-80;
    }
  }

  &--tertiary-white {
    background-color: transparent;
    color: variables.$white-default;
    --soona-button-loader-color: #{variables.$white-default};
    padding: 0;
    text-decoration: underline;

    /* for tertiary buttons, disable underline if there is an icon */
    &:has(svg) {
      text-decoration: none;
    }

    &:not(:disabled):hover {
      color: variables.$black-default;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      color: variables.$black-default;
    }
  }

  &--icon-primary {
    background-color: variables.$friendly-red-50;
    outline-color: variables.$black-default;

    &:not(:disabled):hover {
      background-color: variables.$friendly-red-60;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$friendly-red-70;
    }
  }

  &--icon-plain-gray {
    background-color: transparent;
    color: variables.$gray-60;
    --soona-button-loader-color: #{variables.$gray-60};

    &:not(:disabled):hover {
      background-color: variables.$black-translucent-04;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$black-translucent-08;
    }
  }

  &--icon-gray-outline {
    border-color: variables.$gray-30;
    background-color: variables.$white-default;
    color: variables.$gray-60;
    --soona-button-loader-color: #{variables.$gray-60};

    &:not(:disabled):hover {
      background-color: variables.$gray-10;
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      background-color: variables.$gray-20;
    }
  }

  &--icon-inherit {
    color: inherit;
    background-color: transparent;
    --soona-button-loader-color: currentColor;

    &:not(:disabled):hover {
      mix-blend-mode: difference;
      // 15% opacity applied to support light & dark backgrounds
      background-color: rgba(
        red(variables.$gray-40),
        green(variables.$gray-40),
        blue(variables.$gray-40),
        0.15
      );
    }

    &:not(:disabled):active,
    &[aria-pressed='true'] {
      // 30% opacity applied to support light & dark backgrounds
      background-color: rgba(
        red(variables.$gray-40),
        green(variables.$gray-40),
        blue(variables.$gray-40),
        0.3
      );
    }
  }
}
</style>
