<script setup>
import {
  computed,
  ref,
  shallowRef,
  toRefs,
  useId,
  watch,
  watchEffect,
} from 'vue';
import { onKeyStroke, useElementBounding } from '@vueuse/core';
import resizeToFit from 'intrinsic-scale';
import annotateCursor from './annotate-cursor.svg';
import {
  fullSizeToScaledCoords,
  scaledToFullSizeCoords,
  useDigitalAssetAnnotate,
} from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';
import { useDialogContext } from '@/composables/useDialog';
import AnnotateNote from '@/components/notes/digital-asset-notes/AnnotateNote.vue';
import { useNotes } from '@/queries/notes/useNotes';
import AnnotateNoteCreate from '@/components/notes/digital-asset-notes/AnnotateNoteCreate.vue';
import { useDigitalAssetNotesAreReadonly } from '@/components/notes/digital-asset-notes/useDigitalAssetNotesAreReadonly';
import { useNonCreatorEditingAllowed } from '@/components/notes/useNonCreatorEditingAllowed';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: {
    type: [Number, String],
    default: null,
  },
  digitalAssetId: {
    type: [Number, String],
    default: null,
  },
  height: {
    type: Number,
    default: undefined,
  },
  loaded: {
    type: Boolean,
    required: true,
  },
  rotation: {
    type: Number,
    default: undefined,
  },
  width: {
    type: Number,
    default: undefined,
  },
});

const { accountId, digitalAssetId, loaded, rotation, width } = toRefs(props);

const id = useId();
const { start, stop } = useDialogContext({
  id,
  modal: false,
  immediate: false,
});

const imgEl = shallowRef(null);

function setImgRef(el) {
  imgEl.value = el;
}

const readonly = useDigitalAssetNotesAreReadonly(accountId, digitalAssetId);
const nonCreatorEditingAllowed = useNonCreatorEditingAllowed('digital_assets');

const disabled = computed(
  () =>
    !digitalAssetId.value ||
    !loaded.value ||
    !width.value ||
    !imgEl.value ||
    (readonly.value && !nonCreatorEditingAllowed.value)
);

// offsets so that the bottom left is the center, ignoring borders
const cursorCssUrl = `url("${annotateCursor}") 2 19, pointer`;

/**
 * @type {import('vue').Ref<null | { x: number, y: number }>}
 */
const nativePoint = ref(null);

watch(nativePoint, (newVal, oldVal) => {
  // regardless of what used to be, if there's nothing now, stop it
  if (!newVal) {
    stop();
    // if there is something now, but there wasn't before, start it
  } else if (!oldVal) {
    start();
  }
  // does nothing if the point changes, but is still open the whole time
});

onKeyStroke('Escape', () => {
  if (nativePoint.value) {
    nativePoint.value = null;
  }
});

// todo, this might not be accurate all of the time
const {
  top: imgTop,
  left: imgLeft,
  height: imgHeight,
  width: imgWidth,
  update: updateElementBounding,
} = useElementBounding(imgEl);

const containedBounds = computed(() => {
  const source = {
    width: props.width,
    height: props.height,
  };
  const target = {
    width: imgWidth.value,
    height: imgHeight.value,
  };
  const {
    width: rWidth,
    height: rHeight,
    x: rX,
    y: rY,
  } = resizeToFit('contain', source, target);

  return {
    height: rHeight,
    width: rWidth,
    x: Math.round(rX),
    y: Math.round(rY),
  };
});

const imgPoint = computed(() => {
  const point = nativePoint.value;
  return point
    ? fullSizeToScaledCoords({
        x: point.x,
        y: point.y,
        fullSizeWidth: width,
        scaledWidth: imgWidth,
      })
    : null;
});

const { resetActiveNoteId } = useDigitalAssetAnnotate();

watchEffect(() => {
  if (imgPoint.value) {
    resetActiveNoteId();
  }
});

function createAnnotation(e) {
  // work around scenarios where element bounds do not initially get set.
  updateElementBounding();

  const el = e.target;
  // only look at actual img element events, just in case
  if (disabled.value || el.tagName !== 'IMG') return;

  // fetch layout in handler for the most up-to-date info
  const { left, top } = el.getBoundingClientRect();
  const elementPositionX = left + window.scrollX;
  const elementPositionY = top + window.scrollY;

  const elX = Math.round(e.clientX - elementPositionX);
  const elY = Math.round(e.clientY - elementPositionY);

  nativePoint.value = scaledToFullSizeCoords({
    x: elX - containedBounds.value.x,
    y: elY - containedBounds.value.y,
    fullSizeWidth: width,
    scaledWidth: containedBounds.value.width,
  });
}

const { data: notesData, error: notesError } = useNotes(
  'digital_assets',
  digitalAssetId,
  {
    currentPage: 1,
    itemsPerPage: 50,
    withLocationMetadata: true,
  }
);

const { addToast } = useSoonaToast();

watch(notesError, newVal => {
  if (newVal) {
    addToast(
      `There was an error loading annoations. Please refresh the page and try again.`,
      {
        variation: 'error',
      }
    );
  }
});

const annotations = computed(() => {
  return (
    notesData.value?.notes?.map(note => {
      return {
        id: note.id,
        creatorName: note.creator.name,
        creatorAvatarSrc: note.creator.avatar,
        fullSizeX: note.location_metadata.x,
        fullSizeY: note.location_metadata.y,
      };
    }) ?? []
  );
});
</script>

<template>
  <div
    class="digital-asset-annotate"
    :data-disabled="disabled"
    :style="!disabled ? { cursor: cursorCssUrl } : undefined"
    @click="createAnnotation"
  >
    <slot :set-img-ref="setImgRef" />
    <AnnotateNote
      v-for="annotation in annotations"
      :key="annotation.id"
      :full-size-width="width"
      :full-size-height="height"
      :full-size-x="annotation.fullSizeX"
      :full-size-y="annotation.fullSizeY"
      :img-el="imgEl"
      :note-create-name="annotation.creatorName"
      :note-id="annotation.id"
      :note-creator-avatar-src="annotation.creatorAvatarSrc"
      :rotation="rotation"
    />
    <AnnotateNoteCreate
      v-if="imgPoint && nativePoint"
      :digital-asset-id="digitalAssetId"
      :full-size-height="height"
      :full-size-width="width"
      :full-size-x="nativePoint.x"
      :full-size-y="nativePoint.y"
      :rotation="rotation"
      :style="{
        top: imgPoint.y + containedBounds.y + imgTop - 29 + 'px',
        left: imgPoint.x + containedBounds.x + imgLeft + 'px',
      }"
      @clear="nativePoint = null"
    />
  </div>
</template>

<style lang="scss" scoped>
.digital-asset-annotate {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
}
</style>
