<script setup>
import { computed, watchEffect } from 'vue';
import { useProductsSearch } from '@/queries/useProductsSearch';
import MiscellaneousServiceCard from '@/components/user/anytime/add_on_order/MiscellaneousServiceCard.vue';
import { useReservationAdditionalOrderStore } from '@/components/user/anytime/billing_and_orders/store/useReservationAdditionalOrderStore';

const emits = defineEmits(['select', 'de-select']);
const reservationAdditionalOrderStore = useReservationAdditionalOrderStore();

const { data: productsData } = useProductsSearch({
  productType: ['custom_service_non_taxed', 'custom_service'],
});

const products = computed(() => {
  return [...productsData.value].sort((a, b) => {
    // show custom price products first
    if (a.price_info.default_price?.id) return 1;
    return a.name.localeCompare(b.name);
  });
});

watchEffect(() => {
  if (products.value) {
    reservationAdditionalOrderStore.setMiscellaneousProducts(products.value);
  }
});
</script>
<template>
  <div class="miscellaneous-services">
    <MiscellaneousServiceCard
      v-for="product in products"
      :key="product.id"
      :product="product"
      @select="emits('select', { value: product.id })"
      @de-select="emits('de-select', { value: product.id })"
    />
  </div>
</template>
<style scoped lang="scss">
.miscellaneous-services {
  margin-bottom: 1.5rem;
}
</style>
