<script setup>
import { computed, toRefs } from 'vue';
import { useAccount } from 'src/composables/useAccount';
import { useFlag } from '@/composables/useFlag';
import { useRetainer } from '@/queries/retainers/useRetainer';
import { useReservation } from 'src/composables/useReservation';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import { usePriorityError } from 'src/composables/usePriorityError';
import { useIncompleteSteps } from 'src/components/crew/booking/useIncompleteSteps.js';
import { toCurrency } from '@/lib/currency';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaClickableCard from 'src/components/booking/v3/p2/SoonaClickableCard.vue';

const props = defineProps({
  reservationId: String,
  slug: String,
});
const { reservationId, slug } = toRefs(props);

const {
  reservation,
  isConcierge,
  isContinuation,
  isLoading: loadingReservation,
  error: reservationLoadingError,
} = useReservation(reservationId);

const accountId = computed(() => reservation.value?.account_id);

const { data: retainer } = useRetainer({ accountId });

const currentRetainerAmount = computed(
  () => retainer.value?.current_amount || 0
);
const selectedBookingType = computed(
  () => reservation.value?.draft_type ?? reservation.value?.reservation_type
);
const reservationType = computed(() => reservation.value?.reservation_type);
const paymentsRetainerMvpFlag = useFlag('payments_retainer_mvp');
const { isActiveRetainerClient } = useAccount(accountId);

const customerBookingTypes = computed(() => {
  let bookingTypes = [
    {
      value: 'suggested_draft',
      label: 'suggested (customer pays later)',
      description: [
        'booking credit applied',
        'customer charged full amount',
        'booking appears as "suggested" on customer account',
      ],
    },
    {
      value: 'comped_draft',
      label: 'comped down payment',
      description: [
        'no booking credit, no charge',
        'no charge for pro services (add as an additional order on crew page if you want to charge)',
        'booking appears as "upcoming" on customer account',
      ],
    },
  ];

  if (!isConcierge.value && !isContinuation.value) {
    bookingTypes.push({
      value: 'surprise',
      label: 'surprise',
      description: [
        'no booking credit, no charge',
        'no charge for pro services (add as an additional order on crew page if you want to charge)',
        'customer is not invited to booking',
      ],
    });
  }

  if (paymentsRetainerMvpFlag.value && isActiveRetainerClient.value) {
    bookingTypes.push({
      value: 'retainer_draft',
      label: 'deduct from account balance',
      description: [
        `current account balance: ${toCurrency(currentRetainerAmount.value)}`,
        'no booking credit',
        'order will be deducted from customer account balance',
        'booking appears as “upcoming” on customer account',
      ],
    });
  }
  return bookingTypes;
});

// update reservation
const {
  mutate: mutateReservation,
  error: mutateError,
  isPending: isMutatingReservation,
} = useUpdateReservation(reservationId);

const onOptionChange = value => {
  let rt = 'anytime';
  let draftType = null;

  if (value.value === 'surprise') {
    rt = value.value;
  } else {
    draftType = value.value;
    rt =
      reservationType.value === 'surprise' ? 'anytime' : reservationType.value;
  }

  mutateReservation({
    reservation_type: rt,
    draft_type: draftType,
  });
};

// loading/error handing
const { stepIsIncomplete } = useIncompleteSteps(reservation, slug);

const priorityError = usePriorityError(
  reservationLoadingError,
  mutateError,
  stepIsIncomplete
);

const isBusy = computed(
  () => loadingReservation.value || isMutatingReservation.value
);
</script>

<template>
  <div class="customer-booking-type">
    <SoonaClickableCard
      v-for="booking in customerBookingTypes"
      :key="booking.value"
      :has-small-body-text="true"
      :card-data="booking"
      :is-disabled="isBusy"
      :is-selected="booking.value === selectedBookingType"
      :on-card-click="onOptionChange"
      :title="booking.label"
    >
      <template #body>
        <ul class="pack-card__includes">
          <li v-for="(item, i) in booking.description" :key="i">
            {{ item }}
          </li>
        </ul>
      </template>
    </SoonaClickableCard>
  </div>

  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
</template>

<style lang="scss" scoped>
@use '@/variables';

.customer-booking-type {
  :deep(.soona-clickable-card) {
    margin-bottom: 1.5rem;
  }
  .pack-card {
    &__includes {
      list-style: disc;
      padding-left: 0.9375rem;
    }
  }
}
</style>
