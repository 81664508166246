<script setup>
import { computed } from 'vue';
import OverlayButtonToggle from '@/components/infinite_asset_gallery/OverlayButtonToggle.vue';
import { useDigitalAssetCommonActions } from '@/composables/digital_assets/useDigitalAssetCommonActions';

const props = defineProps({
  digitalAsset: {
    type: Object,
    required: true,
  },
  isHovered: {
    type: Boolean,
    default: false,
  },
});

const digitalAsset = computed(() => props.digitalAsset);

const {
  isOwnedBySoona,

  canBagAsset,
  isAddedToBag,
  toggleAddToBag,
  isAddToBagLoading,
  isAddToBagDisabled,

  isFavorited,
  toggleFavorite,
  canFavoriteAsset,
  isFavoriteLoading,
  isFavoriteDisabled,

  isStaffPicked,
  toggleStaffPick,
  isStaffPicksLoading,
  isStaffPicksDisabled,
  canManageCustomerCollections,
} = useDigitalAssetCommonActions(digitalAsset);
</script>

<template>
  <OverlayButtonToggle
    v-if="canManageCustomerCollections && isOwnedBySoona && isHovered"
    data-cypress="button-toggle-expert-pick"
    :disabled="isStaffPicksDisabled"
    :loading="isStaffPicksLoading"
    :icon-name="isStaffPicked ? 'trophy-solid' : 'trophy'"
    :pressed="isStaffPicked"
    visually-hidden-text="expert pick"
    @click="toggleStaffPick"
  >
    <template #tooltip-content>
      {{ isStaffPicked ? 'remove from expert picks' : 'add to expert picks' }}
    </template>
  </OverlayButtonToggle>

  <OverlayButtonToggle
    v-if="
      (canFavoriteAsset && (isHovered || isFavorited)) ||
      (!canFavoriteAsset && isFavorited)
    "
    :disabled="isFavoriteDisabled"
    :loading="isFavoriteLoading"
    :icon-name="isFavorited ? 'heart-solid' : 'heart'"
    :pressed="isFavorited"
    visually-hidden-text="favorite"
    @click="toggleFavorite"
  >
    <template #tooltip-content>
      {{ isFavorited ? 'remove from favorites' : 'add to favorites' }}
    </template>
  </OverlayButtonToggle>

  <OverlayButtonToggle
    v-if="
      isOwnedBySoona &&
      ((canBagAsset && (isHovered || isAddedToBag)) ||
        (!canBagAsset && isAddedToBag))
    "
    data-cypress="button-toggle-bag"
    :disabled="isAddToBagDisabled"
    :loading="isAddToBagLoading"
    :icon-name="isAddedToBag ? 'soona-bag-2-solid-check' : 'soona-bag'"
    :pressed="isAddedToBag"
    visually-hidden-text="bag"
    @click="toggleAddToBag"
  >
    <template #tooltip-content>
      {{ isAddedToBag ? 'remove from bag' : 'add to bag' }}
    </template>
  </OverlayButtonToggle>
</template>
