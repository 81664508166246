<template>
  <div class="live-gallery-empty">
    <img src="@images/booking/live_shoot_empty_gallery.svg" alt="" />

    <h3 class="u-title--heavy">
      The fun is about to start. Content from your shoot will show here.
    </h3>
  </div>
</template>

<style lang="scss" scoped>
.live-gallery-empty {
  flex: 1;
  width: 100%;
  gap: 2.5rem;
  display: flex;
  margin: 0 auto;
  max-width: 32rem;
  text-align: center;
  align-items: center;
  padding: 2.5rem 0.5rem;
  flex-direction: column;

  h3 {
    max-width: 24rem;
  }
}
</style>
