import { useFlag } from '@/composables/useFlag';

const BATCH_RESIZE_ACTION = Symbol('batch-resize-action');
const BATCH_REMOVE_BACKGROUND_ACTION = Symbol('batch-remove-background-action');
const BATCH_CHANGE_BACKGROUND_COLOR_ACTION = Symbol(
  'batch-change-background-color-action'
);

export function useBatchEditTools() {
  const enableBatchBackgroundColor = useFlag(
    'pegasus_enable_batch_background_color'
  );

  const batch_edit_tools = [
    {
      icon: 'resize',
      title: 'crop / resize',
      slug: 'resize',
      description: 'change the scale and/or cropping of your images',
      symbol: BATCH_RESIZE_ACTION,
      active: true,
    },
    {
      icon: 'scissors',
      title: 'remove background',
      slug: 'remove_background',
      description: 'create a transparent background PNG file after you save',
      symbol: BATCH_REMOVE_BACKGROUND_ACTION,
      active: true,
    },
    {
      icon: 'paint-roller',
      title: 'change background color',
      slug: 'change_background_color',
      description: 'change background color',
      symbol: BATCH_CHANGE_BACKGROUND_COLOR_ACTION,
      active: enableBatchBackgroundColor.value,
    },
  ];

  return {
    BATCH_RESIZE_ACTION,
    BATCH_REMOVE_BACKGROUND_ACTION,
    BATCH_CHANGE_BACKGROUND_COLOR_ACTION,
    batch_edit_tools,
  };
}
