<script setup>
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

import { component as Viewer } from 'v-viewer';
import 'viewerjs/dist/viewer.css';

const liveShootStore = useLiveShootStore();
const { assetAccountId, assetId, selectedRDA, selectedAssetVariation } =
  storeToRefs(liveShootStore);

const { imageVariations, mediaUrl, title, assetType, assetLoading } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const previewUrl = computed(
  () => selectedRDA.value?.digital_asset.preview?.url
);
const url = computed(() => {
  return (
    imageVariations.value?.find(v => v.value === selectedAssetVariation.value)
      ?.value ?? mediaUrl.value
  );
});

const viewerInstance = ref();
const toggleZoom = () => {
  viewerInstance.value.show();
};
</script>

<template>
  <div ref="containerRef" class="live-mobile-fullscreen-asset">
    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          size="large"
          variation="icon-gray-outline"
          :loading="assetLoading"
          :aria-describedby="ariaDescribedby"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
          @click="toggleZoom"
        >
          <SoonaIcon name="closeup" />
          <span class="u-visually-hidden">zoom in</span>
        </SoonaButton>
      </template>

      <template #tooltip-content>zoom in</template>
    </SoonaTooltip>

    <Viewer
      v-show="false"
      ref="viewerRef"
      class="viewer"
      :options="{
        toolbar: false,
        navbar: false,
        backdrop: true,
        maxZoomRatio: 2,
        minZoomRatio: 0.5,
        className: 'live-mobile-fullscreen-asset-viewer',
      }"
      @inited="viewerI => (viewerInstance = viewerI)"
    >
      <DigitalAssetImage
        :src="url"
        :alt="title"
        :asset-type="assetType"
        :preview-src="previewUrl"
      />
    </Viewer>
  </div>
</template>

<style lang="scss">
@use '@/variables';

.live-mobile-fullscreen-asset-viewer {
  cursor: zoom-out;
  background: variables.$black-default;

  .viewer-close {
    top: 0.5rem;
    right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background: variables.$white-default;
    outline: 1px solid variables.$gray-30;

    &:before {
      left: 0.625rem;
      bottom: 0.625rem;
      transform: scale(1.5);
      // turn the white icon into a gray one
      filter: invert(1) brightness(2) contrast(0.2);
    }
  }
}
</style>
