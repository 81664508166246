<script setup>
import { storeToRefs } from 'pinia';
import { useElementSize } from '@vueuse/core';
import { computed, ref, useTemplateRef } from 'vue';

import { useLiveShootStore } from './useLiveShootStore';
import { useCapability } from '@/composables/useCapability';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

import LiveAssetPrice from './LiveAssetPrice.vue';
import LiveDACommonActions from './LiveDACommonActions.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import LiveStaffActionBar from './live-staff-action-bar/LiveStaffActionBar.vue';
import DigitalAssetImage from '@/components/infinite_asset_gallery/DigitalAssetImage.vue';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';
import DigitalAssetCommonStatuses from '@/components/infinite_asset_gallery/DigitalAssetCommonStatuses.vue';

import { component as Viewer } from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import DigitalAssetAnnotate from '@/components/notes/digital-asset-notes/DigitalAssetAnnotate.vue';
import { useDigitalAssetAnnotateProvider } from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';

defineEmits(['close']);

const liveShootStore = useLiveShootStore();
const {
  assetId,
  selectedRDA,
  assetAccountId,
  isNotesVisible,
  selectedAssetVariation,
} = storeToRefs(liveShootStore);

const {
  assetType,
  imageVariations,
  mediaUrl,
  title,
  mediaWidth,
  mediaHeight,
  rotation,
  isPhoto,
  isGif,
  isVideo,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const imageWrapper = useTemplateRef('imageWrapper');
const { width, height } = useElementSize(imageWrapper);
const wrapperAspectRatio = computed(() => width.value / height.value);
const imageAspectRatio = computed(
  () => selectedRDA.value?.digital_asset.preview?.ratio ?? 1
);
const isSameAspectRatio = computed(() => {
  const imageRatio = parseFloat((imageAspectRatio.value ?? 0).toFixed(2));
  const wrapperRatio = parseFloat((wrapperAspectRatio.value ?? 0).toFixed(2));
  return imageRatio / wrapperRatio === 1;
});

const assetWidthValue = computed(() =>
  imageAspectRatio.value > wrapperAspectRatio.value ? '100%' : 'auto'
);
const assetHeightValue = computed(() =>
  assetWidthValue.value === '100%' ? 'auto' : '100%'
);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const previewUrl = computed(
  () => selectedRDA.value?.digital_asset.preview?.url
);
const url = computed(() => {
  return (
    imageVariations.value?.find(v => v.value === selectedAssetVariation.value)
      ?.value ?? mediaUrl.value
  );
});

const largeImageLoaded = ref(false);
useDigitalAssetAnnotateProvider(assetId);
</script>

<template>
  <div class="live-focused-asset">
    <h3 class="u-visually-hidden">viewing asset {{ title }}</h3>

    <LiveStaffActionBar
      v-if="isFtSoonaStaff"
      :selected-variation="url"
      :variations="imageVariations"
      @update:selected-variation="
        variation => liveShootStore.setSelectedAssetVariation(variation)
      "
    />

    <div class="live-focused-asset__image-positioner">
      <div ref="imageWrapper" class="live-focused-asset__image-wrapper">
        <SoonaButton
          size="large"
          variation="icon-gray-outline"
          class="live-focused-asset__image-wrapper--close"
          @click="$emit('close')"
        >
          <SoonaIcon name="xmark" />
          <span class="u-visually-hidden">close</span>
        </SoonaButton>

        <template v-if="isPhoto || isGif">
          <DigitalAssetAnnotate
            v-if="isNotesVisible && isPhoto"
            :account-id="assetAccountId"
            :digital-asset-id="assetId"
            :loaded="largeImageLoaded"
            :width="mediaWidth"
            :height="mediaHeight"
            :rotation="rotation"
            class="live-focused-asset__img"
          >
            <template #default="{ setImgRef }">
              <img :ref="setImgRef" :src="url" :alt="title" />
            </template>
          </DigitalAssetAnnotate>
          <Viewer
            v-else
            class="live-focused-asset__viewer"
            :options="{
              toolbar: false,
              navbar: false,
              backdrop: true,
              maxZoomRatio: 2,
              minZoomRatio: 0.5,
              container: imageWrapper,
            }"
          >
            <DigitalAssetImage
              v-show="largeImageLoaded"
              :src="url"
              :alt="title"
              :asset-type="assetType"
              :class="{
                'live-focused-asset__img--no-bottom-border': isSameAspectRatio,
              }"
              class="live-focused-asset__img"
              @image-loaded="largeImageLoaded = true"
              @image-loading="largeImageLoaded = false"
            />
            <DigitalAssetImage
              v-if="!largeImageLoaded"
              :src="previewUrl"
              :alt="title"
              :asset-type="assetType"
              :class="{
                'live-focused-asset__img--no-bottom-border': isSameAspectRatio,
              }"
              class="live-focused-asset__img"
            />
          </Viewer>
        </template>
        <template v-else-if="isVideo">
          <video
            :key="url"
            :poster="previewUrl"
            controls
            class="live-focused-asset__img"
          >
            <source :key="url" :src="url" type="video/mp4" />
          </video>
        </template>
        <div class="live-focused-asset__image-wrapper--statuses">
          <DigitalAssetCommonStatuses
            size="medium"
            :digital-asset="selectedRDA.digital_asset"
          />
        </div>

        <div class="live-focused-asset__image-wrapper--actions">
          <div class="live-focused-asset__asset-title">
            <h4 class="u-badge--small">{{ title }}</h4>
            <LiveAssetPrice />
          </div>

          <LiveDACommonActions />

          <div />
        </div>
      </div>
    </div>

    <div
      class="live-focused-asset__details"
      :class="{ 'live-focused-asset__details--notes': isNotesVisible }"
    >
      <DigitalAssetNotesList
        v-if="isNotesVisible"
        newest-first
        :digital-asset-id="assetId"
        :account-id="assetAccountId"
      >
      </DigitalAssetNotesList>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-focused-asset {
  gap: 1rem;
  height: 100%;
  display: flex;
  overflow: auto;
  padding-bottom: 1rem;
  flex-direction: column;

  &__image-positioner {
    width: 100%;
    max-height: 100%;
    position: relative;

    // ensures a 3:2 image ratio, and space for the actions
    &::before {
      content: '';
      display: block;
      padding-bottom: calc((2 / 3) * 100% + 6.25rem);
    }
  }

  &__image-wrapper {
    inset: 0;
    display: grid;
    position: absolute;
    place-items: center;
    border-radius: 0.625rem;
    padding-bottom: 6.25rem;
    background: variables.$white-default;

    &--statuses {
      top: 0;
      left: 0;
      gap: 0.25rem;
      display: flex;
      padding: 0.5rem;
      position: absolute;
      flex-direction: column;
      justify-content: flex-start;
    }

    &--actions {
      left: 0;
      right: 0;
      bottom: 0;
      gap: 0.5rem;
      display: flex;
      height: 6.25rem;
      padding: 0 1rem;
      position: absolute;
      align-items: center;
      justify-content: space-between;

      & > * {
        flex: 1 0 0;
      }
    }

    &--close {
      z-index: 2;
      top: 0.5rem;
      right: 0.5rem;
      position: absolute;
    }

    video {
      width: 100%;
    }
  }

  &__img {
    min-height: 0;
    cursor: zoom-in;
    max-height: 100%;
    object-fit: contain;
    border-radius: 0.625rem;
    width: v-bind(assetWidthValue);
    height: v-bind(assetHeightValue);
    box-shadow: variables.$elevation-0;

    &--no-bottom-border {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__asset-title {
    display: flex;
    overflow: hidden;
    align-items: start;
    min-height: 1.75rem;
    flex-direction: column;
    color: variables.$gray-50;
    justify-content: space-between;

    h4 {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__details {
    position: relative;

    &--notes {
      padding: 2rem 1.5rem 2rem 1.5rem;
      background: variables.$white-default;
      border-radius: 0.625rem;
    }
  }

  &__viewer {
    display: contents;

    // fallbacks
    min-height: 0;
    max-height: 100%;
    width: v-bind(assetWidthValue);
    height: v-bind(assetHeightValue);
  }

  &:deep(.viewer-backdrop) {
    cursor: zoom-out;
    background: variables.$black-default;
  }

  &:deep(.viewer-close) {
    top: 0.5rem;
    right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    background: variables.$white-default;
    outline: 1px solid variables.$gray-30;

    &:before {
      left: 0.625rem;
      bottom: 0.625rem;
      transform: scale(1.5);
      // turn the white icon into a gray one
      filter: invert(1) brightness(2) contrast(0.2);
    }
  }
}
</style>
