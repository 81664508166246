<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';

const { addToast } = useSoonaToast();
const liveShootStore = useLiveShootStore();
const { assetId, assetAccountId } = storeToRefs(liveShootStore);

const { assetArchivedAt } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { mutate: updateDigitalAsset, isPending: isUpdatingDigitalAsset } =
  useUpdateDigitalAsset(assetAccountId);

const isLoading = computed(() => !!isUpdatingDigitalAsset.value);

const unarchive = () => {
  updateDigitalAsset(
    {
      assetId: assetId.value,
      body: {
        archived_at: null,
      },
    },
    {
      onError: () => {
        addToast(`error unarchiving asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>
<template>
  <SoonaButton
    v-if="assetArchivedAt"
    :loading="isLoading"
    :disabled="isLoading"
    variation="secondary-gray"
    @on-click="unarchive"
  >
    unarchive
  </SoonaButton>
</template>
