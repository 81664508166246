<script setup>
import { computed, ref } from 'vue';
import { usePriorityError } from '@/composables/usePriorityError';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUploader } from '@/composables/useUploader.js';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaUploadForm from '@/components/uploader/SoonaUploadForm.vue';
import SoonaUploadProgressModal from '@/components/uploader/SoonaUploadProgressModal.vue';

const props = defineProps({
  uploadedFiles: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['update', 'remove']);

const uploadedFiles = computed(() => props.uploadedFiles);

const { addToast } = useSoonaToast();

const maxConcurrent = ref(1);
const uploadInterval = ref(100);
const fileSizeLimit = ref(50);
const activeUploadsLocal = ref([]);
const cancelUploadsLocal = ref(() => {});
const errorMessage = ref({});
const acceptedFileTypes = ref('.pdf');
const attachmentComplete = ref(false);

const handleUploadError = errMsg => {
  cancelUploadsLocal.value();
  errorMessage.value.message = errMsg;
};

const handleUploadComplete = blob => {
  if (uploadedFiles.value?.includes(blob.id)) return;

  let updatedFileList = [];

  updatedFileList.push({
    id: blob.id,
    filename: blob.filename,
    byte_size: blob.byte_size,
    signed_id: blob.signed_id,
  });

  emit('update', {
    uploadedFiles: updatedFileList,
  });

  attachmentComplete.value = true;
};

const removeUpload = fileId => {
  const updatedFileList = uploadedFiles.value.filter(
    file => file.id !== fileId
  );

  emit('update', {
    uploadedFiles: updatedFileList,
  });
};

const { handleUpload, handleDrop, activeUploads, startUpload, cancelUploads } =
  useUploader(
    maxConcurrent,
    uploadInterval,
    fileSizeLimit,
    null,
    handleUploadError,
    handleUploadComplete,
    null
  );

activeUploadsLocal.value = activeUploads.value;
cancelUploadsLocal.value = cancelUploads;

const completedUploads = computed(() =>
  activeUploads.value.filter(upload => upload.completed)
);

const handleCancelUploads = () => {
  cancelUploads();
  attachmentComplete.value = false;

  if (completedUploads.value.length === 0) {
    addToast('Upload canceled', {
      variation: 'info',
      iconName: 'xmark',
    });
  }
};

const isUploading = computed(() => activeUploads.value.length > 0);
const priorityError = usePriorityError(errorMessage);
</script>

<template>
  <div class="upload-contract">
    <h3 class="u-title--heavy">Upload contract</h3>
    <SoonaUploadForm
      :accept="acceptedFileTypes"
      :active-uploads="activeUploads"
      is-multiple
      label="Upload contract"
      @drop="
        $event => {
          errorMessage = {};
          handleDrop($event);
        }
      "
      @upload="
        $event => {
          errorMessage = {};
          handleUpload($event);
        }
      "
    />
    <SoonaUploadProgressModal
      v-if="isUploading"
      :active-uploads="activeUploadsLocal"
      :is-multiple="false"
      label="PDF"
      @cancel-uploads="handleCancelUploads"
      @start-upload="startUpload"
    />
    <SoonaError v-if="priorityError">
      {{ priorityError }}
    </SoonaError>
    <div
      v-if="attachmentComplete || uploadedFiles?.length > 0"
      class="upload-contract__completed-uploads"
    >
      <div
        v-for="file in uploadedFiles"
        :key="file.id"
        class="upload-contract__uploaded"
      >
        <div class="upload-contract__details">
          <SoonaIcon name="file" />
          <p class="u-body--heavy upload-contract__filename">
            {{ file.filename }}
          </p>
          <p class="u-body--regular upload-contract__size">
            {{ Math.floor(+file.byte_size / 1000) }}KB
          </p>
          <SoonaTooltip>
            <template
              #default="{ setRef, mouseenter, focus, mouseleave, blur }"
            >
              <SoonaButton
                :ref="el => setRef(el)"
                variation="icon-plain-gray"
                size="small"
                @on-click="() => removeUpload(file.id)"
                @mouseenter="mouseenter"
                @focus="focus"
                @mouseleave="mouseleave"
                @blur="blur"
              >
                <SoonaIcon name="xmark" />
              </SoonaButton>
            </template>
            <template #tooltip-content>Remove this file</template>
          </SoonaTooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.upload-contract {
  h3 {
    padding-bottom: 0.5rem;
  }

  &__completed-uploads {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-top: 1rem;
  }

  &__uploaded {
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding: 0.75rem;

    svg {
      flex-shrink: 0;
    }
  }

  &__details {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    width: 100%;
  }

  &__size {
    color: variables.$gray-60;
  }

  &__filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
</style>
