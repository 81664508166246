<template>
  <div>
    <fieldset class="product-handling">
      <section>
        <div class="product-handling__header">
          <p class="product-handling__subtitle">
            what should we do with your items after the shoot?
          </p>
        </div>
      </section>
      <section
        id="producthandlingoptions"
        ref="handlingOptions"
        class="u-drawer product-handling__options"
        :class="{ 'u-drawer--open': productHandlingOpen }"
        aria-labelledby="producthandlingheader"
      >
        <PackageHandlingOption
          v-for="option in accountPostShootOptions"
          :key="option.firstInfo"
          v-model="postShootOption"
          :option="option"
          :editable="
            defaultPostShootOption
              ? defaultPostShootOption === option.value
              : true
          "
          :disabled="disableStoreAtSoonaOptionAsStaff(option.value)"
          :parent-drawer-closed="!productHandlingOpen"
          :has-soona-storage="hasSoonaStorage"
        />
      </section>
      <PackageHandlingOption
        v-if="postShootOption && !productHandlingOpen"
        :option="selectedOption"
        :read-only="true"
        aria-hidden="true"
        :has-soona-storage="hasSoonaStorage"
      />
    </fieldset>
    <SoonaError v-if="showError">
      click above to view and select a product handling option to confirm your
      booking.
    </SoonaError>
    <div
      v-if="showStoreItConfirm || showSendItBackConfirm"
      class="product-handling__confirm"
    >
      <p v-if="showStoreItConfirm">
        YAY! let’s get you on the fast track! we’ll get you set up after you
        book.
      </p>
      <p v-else-if="showSendItBackConfirm">
        we’ll send your items back to you! check out our
        <SoonaLink
          to="https://support.soona.co/post-shoot-handling-and-policies"
          target="_blank"
          >post-shoot handling and policies</SoonaLink
        >.
      </p>
      <SoonaTooltip placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="el => setRef(el)"
            variation="icon-transparent"
            size="medium"
            class="product-handling__confirm-dismiss"
            :aria-describedby="ariaDescribedby"
            @on-click="confirmationDismissed = true"
            @mouseenter="mouseenter"
            @focus="focus"
            @mouseleave="mouseleave"
            @blur="blur"
          >
            <SoonaIcon name="xmark" />
            <span class="u-visually-hidden">dismiss</span>
          </SoonaButton>
        </template>
        <template #tooltip-content>dismiss</template>
      </SoonaTooltip>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import * as types from 'src/store/mutation-types';
import { mapGetters, mapMutations } from 'vuex';
import isNil from 'lodash/isNil';
import PackageHandlingOption from './PackageHandlingOption.vue';
import drawerToggle from 'src/mixins/drawerToggleMixin';
import { useAccount } from '@/composables/useAccount';
import { useInventory } from '@/composables/useInventory';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from 'src/composables/useCapability';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaLink from '@/components/ui_library/SoonaLink.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

export default {
  name: 'PackageHandling',
  components: {
    SoonaTooltip,
    SoonaLink,
    SoonaButton,
    SoonaIcon,
    SoonaError,
    PackageHandlingOption,
  },
  mixins: [drawerToggle],
  props: {
    accountId: [Number, String],
    packageOption: String,
    isReservationPackage: Boolean,
    hasSoonaStorage: Boolean,
    onChange: Function,
    defaultPostShootOption: String,
    displayOrder: [String, Number],
  },
  setup(props) {
    const { inputChanged, buttonClicked } = useBaseEvents();

    const order = computed(() => {
      if (props.displayOrder) {
        return `"${props.displayOrder}"`;
      }

      return null;
    });

    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    const { accountFlags } = useAccount(computed(() => props.accountId));

    // existing logic from old component
    // disable store at soona option
    const disableStoreAtSoonaOptionAsStaff = pso => {
      const hasManualFastPass = accountFlags.value?.some(
        flag => flag.slug === 'manually_invoiced_fast_pass'
      );
      return (
        !hasManualFastPass &&
        pso === 'store_at_soona' &&
        !props.hasSoonaStorage &&
        isFtSoonaStaff.value
      );
    };
    const { packageHandlingOptions } = useInventory();

    return {
      disableStoreAtSoonaOptionAsStaff,
      inputChanged,
      buttonClicked,
      order,
      packageHandlingOptions,
    };
  },
  data() {
    return {
      productHandlingOpen: true,
      confirmationDismissed: false,
    };
  },
  computed: {
    ...mapGetters('flash', ['errorMessages']),
    postShootOption: {
      get: function () {
        return this.packageOption;
      },
      set: function (value) {
        if (value !== this.packageOption) {
          this.confirmationDismissed = false;

          // clear out flash error below, in the payment section
          if (this.showError) {
            this.REMOVE_FLASH_MESSAGE(
              this.errorMessages.find(
                ({ text }) =>
                  text === 'select a post shoot option to confirm your booking.'
              )
            );
          }

          this.onChange(value);
        }
      },
    },
    showStoreItConfirm() {
      if (
        this.postShootOption === 'store_at_soona' &&
        !this.hasSoonaStorage &&
        !this.confirmationDismissed
      ) {
        return true;
      }

      return false;
    },
    showSendItBackConfirm() {
      if (
        this.postShootOption === 'return_shipping' &&
        !this.confirmationDismissed
      ) {
        return true;
      }

      return false;
    },
    accountPostShootOptions() {
      let options = this.isReservationPackage
        ? this.packageHandlingOptions
        : this.packageHandlingOptions.filter(
            pso =>
              pso.value !== 'already_stored' &&
              pso.value !== 'no_products_to_send'
          );

      if (this.hasSoonaStorage) {
        // Fast-pass member, when arriving
        let memberObject = {
          value: 'store_at_soona',
          addPackageTitle: 'store this package',
          emoji: '📦',
          price: null,
          priceNote: null,
          firstInfo: 'you’re in the fast pass program!',
          secondInfo: 'soona will store items from this shoot',
          tooltipTitleMobile: 'you’re in the fast pass program!',
          tooltipContent:
            'any items you send us for this shoot we’ll store. don’t want to store them? change your preferences by clicking on the edit icon above.',
        };

        // Fast-pass member, when editing selection
        if (this.productHandlingOpen) {
          memberObject = {
            ...memberObject,
            firstInfo: 'store it using your fast pass!',
            secondInfo: 'we’ll keep storing until you want to have it returned',
            tooltipTitleMobile: 'store it using your fast pass!',
            tooltipContent:
              'you’re in the fast pass program! soona will store any items you send us. we’ll keep storing until you want to have it returned.',
          };
        }

        let replaceNonMemberOption = options.map(a => {
          return a.value !== 'store_at_soona' ? a : memberObject;
        });
        return replaceNonMemberOption;
      } else if (!this.isReservationPackage) {
        let withTooltipPosition = options.map(a => {
          return a.value === 'return_shipping'
            ? {
                ...a,
                tooltipPosition: 'above',
              }
            : a;
        });
        return withTooltipPosition;
      } else {
        return options;
      }
    },
    selectedOption() {
      return this.accountPostShootOptions.find(
        ({ value }) => value === this.postShootOption
      );
    },
    showError() {
      if (this.postShootOption !== '') {
        return false;
      }

      return !isNil(
        this.errorMessages.find(
          ({ text }) =>
            text === 'select a post shoot option to confirm your booking.'
        )
      );
    },
  },
  mounted() {
    if (this.defaultPostShootOption) {
      this.postShootOption = this.defaultPostShootOption;
    } else if (this.hasSoonaStorage) {
      this.postShootOption = 'store_at_soona';
    }
  },
  methods: {
    ...mapMutations('flash', [types.REMOVE_FLASH_MESSAGE]),
    handleToggleDrawer() {
      this.expandSection(this.$refs.handlingOptions, 'productHandlingOpen');
      this.buttonClicked({
        context: 'booking',
        subContext: 'down payment',
        buttonLabel: 'toggle product handling options',
        buttonAction: 'toggle product handling options drawer',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.product-handling {
  position: relative;
  border: 1px solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 0;
  background-color: variables.$periwink-blue-10;

  &::before {
    @include variables_fonts.u-body--heavy;

    content: v-bind('order');
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -1.25rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid variables.$gray-30;
    background-color: variables.$white-default;
    color: variables.$gray-60;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 0.75rem 1.25rem;

    &:hover {
      color: variables.$black-default;
    }
  }

  &__header-icon {
    flex: 0 0 1.5rem;
    margin-left: 0.5rem;
    transition: color 0.1s ease-out;
    color: variables.$gray-60;

    &--caret {
      .product-handling__header--open & {
        transform: rotate(180deg);
      }
    }
  }

  &__header {
    &:hover,
    &:focus-visible {
      .product-handling__header-icon {
        color: variables.$black-default;
      }
    }
  }

  &__title {
    @include variables_fonts.u-title--heavy;

    text-transform: lowercase;
  }

  &__subtitle {
    margin-top: 0.25rem;
  }

  &__options > *:last-child {
    margin-bottom: 0.25rem;
  }

  &__confirm {
    @include variables_fonts.u-label--regular;

    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0.5rem;
    color: variables.$black-default;
    border: 0.0625rem solid variables.$avo-toast-30;
    border-radius: 0.3125rem;
    background-color: variables.$avo-toast-10;
    padding: 0.625rem 0.625rem 0.625rem 0.75rem;
    margin: 0.5rem 0;

    a {
      display: inline;
    }
  }

  &__confirm-dismiss {
    flex: 0 0 2rem;
    color: variables.$gray-60;

    &:hover {
      color: variables.$black-default;
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .product-handling {
    &::before {
      @include variables_fonts.u-subheader--heavy;

      color: variables.$gray-60;
      top: -1.5625rem;
      width: 3.125rem;
      height: 3.125rem;
    }

    &__header {
      padding: 1.5rem;
    }

    &__title {
      @include variables_fonts.u-headline--heavy;
    }
  }
}
</style>
