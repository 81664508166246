<script setup>
import { computed, ref, watch } from 'vue';
import { useVirtualizer } from '@tanstack/vue-virtual';

const props = defineProps({
  height: {
    type: Number,
    required: true,
  },
  initialScrollPosition: {
    type: Number,
    required: false,
  },
  scrollBehavior: {
    type: String,
    default: 'auto',
    validator: function (value) {
      return ['auto', 'smooth'].includes(value);
    },
  },
  rows: {
    type: Array,
    required: true,
  },
  gap: {
    type: Number,
    required: true,
  },
  overscan: {
    type: Number,
    default: 5,
  },
});

const expandedRow = defineModel('expanded-row');

const scrollBehavior = computed(() => props.scrollBehavior);
const initialScrollPosition = computed(() => props.initialScrollPosition);
const rows = computed(() => props.rows);

const parentRef = ref(null);

const getItemKey = ix => {
  return computed(() => rows.value?.at(ix)?.at(0)?.id);
};

const rowVirtualizerOptions = computed(() => {
  const sizeEstimate = props.height;
  return {
    count: rows.value.length,
    estimateSize: () => sizeEstimate,
    overscan: props.overscan,
    getItemKey,
    getScrollElement: () => parentRef.value,
    gap: props.gap,
  };
});

const rowVirtualizer = useVirtualizer(rowVirtualizerOptions);
const virtualRows = computed(() => rowVirtualizer.value.getVirtualItems());
const totalSize = computed(() => `${rowVirtualizer.value?.getTotalSize()}px`);
const gapSize = computed(() => `${props.gap}px`);

const measureElement = el => {
  if (!el) return;
  rowVirtualizer.value.measureElement(el);
};

defineExpose({ parentRef, rowVirtualizer });

watch([initialScrollPosition], ([newPosition]) => {
  if (newPosition === undefined || newPosition === null) return;

  rowVirtualizer.value?.scrollToIndex(newPosition, {
    align: 'start',
    behavior: scrollBehavior.value,
  });
});
</script>
<template>
  <div ref="parentRef" class="scroller">
    <div class="scroller__liner">
      <div
        class="scroller__row-positioner"
        :style="{
          transform: `translateY(${virtualRows[0]?.start ?? 0}px)`,
        }"
      >
        <div
          v-for="virtualRow in virtualRows"
          :ref="measureElement"
          :key="virtualRow.key"
          class="scroller__row"
          :style="{
            height:
              expandedRow === virtualRow.index
                ? 'auto'
                : `${virtualRow.size}px`,
          }"
        >
          <slot
            :index="virtualRow.index"
            :data="rows[virtualRow.index]"
            :is-expanded="expandedRow === virtualRow.index"
          >
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/variables';

.scroller {
  min-height: 250px;
  max-height: 500px;
  overflow: auto;
  width: 100%;

  &__liner {
    height: v-bind(totalSize);

    width: 100%;
    position: relative;
  }

  &__row-positioner {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
  }

  &__row {
    gap: v-bind(gapSize);

    width: 100%;
    display: flex;
    margin-bottom: 0.75rem;
    align-items: flex-start;
  }
}
</style>
