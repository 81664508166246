<script setup>
import { computed, ref, toRef } from 'vue';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useGetHighlightedPacks } from 'src/queries/useGetHighlightedPacks';
import BookingLayout from './BookingLayout.vue';
import BookingTypeCustomOptions from 'src/components/booking/v3/p2/BookingTypeCustomOptions.vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SoonaPackCard from 'src/components/SoonaPackCard.vue';
import SoonaHeading from 'src/components/ui_library/SoonaHeading.vue';
import SoonaLoading from 'src/components/SoonaLoading.vue';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaFaq from 'src/components/ui_library/SoonaFaq.vue';
import faqData from 'src/components/booking/v3/p2/content-type/faq.json';
import { useReservation } from 'src/queries/useReservation';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  nextButtonCopy: String,
  backButtonCopy: String,
  onBack: Function,
  onNext: Function,
  reservationId: String,
  stepName: String,
  transitionName: String,
});

const emit = defineEmits(['setNextTransition']);

const { data: reservation, isLoading } = useReservation(
  toRef(props, 'reservationId')
);

const { currentAccountId } = useMe();
const { account: currentAccount } = useAccount(currentAccountId);

const {
  data: highlightedPacks,
  error,
  isLoading: isLoadingHighlightedPacks,
} = useGetHighlightedPacks({
  reservationId: computed(() => props.reservationId),
  industrySlug: computed(
    () => currentAccount.value?.industry?.value?.slug || null
  ),
});

const { linkClicked } = useBaseEvents();
const bookingTypeCustomOptionsReady = ref(true);

const showPackSection = computed(
  () => !currentAccount.value?.is_active_retainer_client
);

const isBusy = computed(() => {
  return isLoading.value || !bookingTypeCustomOptionsReady.value;
});

let currentStep = computed(() => {
  return reservation.value?.steps?.find(s => s.slug === props.stepName);
});

const isStepValid = computed(() => {
  return currentStep.value?.complete && !isBusy.value;
});

// methods
function onBackClick() {
  props.onBack();
}

function onNextClick() {
  props.onNext();
}

function handlePackClick(pack) {
  emit('setNextTransition');
  linkClicked({
    context: 'booking',
    subContext: 'type',
    linkLabel: pack.name,
    linkHref: `/booking/packs/${pack.id}`,
  });
}

function handleAllPacksClick(label) {
  emit('setNextTransition');
  linkClicked({
    context: 'booking',
    subContext: 'type',
    linkLabel: label,
    linkHref: `/booking/packs`,
  });
}

let hasPhotoOrVideoTag = computed(() => {
  return !!reservation.value?.reservation_tags?.find(
    t => t.title === 'Photo' || t.title === 'Video'
  );
});
</script>

<template>
  <transition :name="transitionName">
    <BookingLayout
      :back-button-copy="backButtonCopy"
      :next-button-copy="nextButtonCopy"
      :on-back-click="onBackClick"
      :on-next-click="onNextClick"
      :reservation-id="reservationId"
      :is-step-valid="isStepValid"
      :show-next="hasPhotoOrVideoTag"
      no-sidecart
    >
      <div class="booking-type prebooking-transition__content">
        <SoonaLoading
          v-if="isLoadingHighlightedPacks"
          :is-loading="true"
          :is-dark="false"
          is-contained
          loading-text="loading"
        />
        <SoonaHeading
          tooltip-copy="pack shoots include predetermined shot lists and services. looking for a more custom option? you can also build your own shoot for full creative flexibility!"
          tooltip-title="pack or custom shoot"
        >
          plan your shoot
          <template #subtitle>
            build a custom shoot
            <span v-if="showPackSection">or browse our discounted packs</span>
          </template>
        </SoonaHeading>
        <SoonaError v-if="error && error.message">
          We are unable to load your recommended packs at this time.<br />
          You can
          <router-link
            to="./packs"
            @click="handleAllPacksClick('explore all our packs here.')"
            >explore all our packs here.</router-link
          >
        </SoonaError>
        <BookingTypeCustomOptions
          :on-next="onNext"
          :reservation-id="reservationId"
          @on-ready="bookingTypeCustomOptionsReady = $event"
        />
        <template v-if="showPackSection">
          <div class="discounted-packs-header">
            <h4 class="u-body--heavy">shop our discounted packs</h4>
            <router-link
              to="./packs"
              data-cypress="router-link-see-all"
              @click="handleAllPacksClick('see all')"
              >see all</router-link
            >
            <p class="u-body--regular">
              our pre-priced packs are tried-and-true options if you’re trying
              us out for the first time or if you want a little extra guidance
            </p>
          </div>
          <div class="booking-type__pack-cards">
            <div v-for="pack in highlightedPacks" :key="pack.id">
              <SoonaPackCard
                :pack="pack"
                :to="`/booking/${reservationId}/packs/${pack.id}`"
                @on-click="() => handlePackClick(pack)"
              />
            </div>
          </div>
          <SoonaButton
            element="router-link"
            to="./packs"
            variation="secondary-black"
            data-cypress="router-link-all-packs"
            @on-click="handleAllPacksClick('see all packs')"
          >
            see all packs
          </SoonaButton>
        </template>
        <SoonaFaq :faq-tab-list="faqData" />
      </div>
    </BookingLayout>
  </transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.booking-type {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &__pack-cards {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(min(14rem, 100%), 1fr));
    width: 100%;
    margin-bottom: 2rem;
  }

  &__includes {
    list-style: disc;
    padding-left: 1rem;
  }

  .discounted-packs-header {
    width: 100%;
    padding: 0 0 1.25rem;
    position: relative;

    & > a {
      position: absolute;
      right: 0;
      top: 0;
      text-decoration: underline;
    }
  }
}
</style>
