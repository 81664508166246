import { http } from '@/config/vue-axios';

export async function publishDigitalAssetsToDropbox(
  accountId,
  assetIds,
  resolutions,
  customFileName
) {
  const response = await http.post('/integrations/dropbox/publish', {
    account_id: accountId,
    asset_ids: assetIds,
    resolutions: resolutions,
    custom_file_name: customFileName,
  });

  return response.data;
}
