<script setup>
import { computed, onMounted, ref, toRefs, watchEffect } from 'vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

const props = defineProps({
  preferredTimezone: {
    type: [String],
  },
  initialData: {
    required: true,
    type: [Object, null],
    default: () => ({}),
  },
});
const emit = defineEmits(['update']);
dayjs.extend(utc);
dayjs.extend(timezone);

const { initialData } = toRefs(props);

const duration = ref(initialData.value?.duration);
const endDate = ref(initialData.value?.endDate);
const retainerAmount = ref(initialData.value?.retainerAmount);
const startDate = ref(initialData.value?.startDate);
const totalAmount = ref(initialData.value?.totalAmount);

const contractTimezone = computed(
  () => props.preferredTimezone || dayjs.tz.guess()
);

const onBlur = () => {
  emit('update', {
    duration: duration.value,
    endDate: endDate.value,
    retainerAmount: retainerAmount.value,
    startDate: startDate.value,
    totalAmount: totalAmount.value,
    utcEndDate: dayjs(endDate.value)
      .tz(contractTimezone.value)
      .endOf('date')
      .utc()
      .format(),
    utcStartDate: dayjs(startDate.value)
      .tz(contractTimezone.value)
      .endOf('date')
      .utc()
      .format(),
  });
};

onMounted(() => {
  if (!initialData.value?.startDate) {
    startDate.value = dayjs().format('YYYY-MM-DD');
  }
});

watchEffect(() => {
  if (startDate.value) {
    endDate.value = dayjs(startDate.value)
      .add(duration.value, 'M')
      .format('YYYY-MM-DD');
  }
});
</script>

<template>
  <div class="contract-info">
    <SoonaTextfield
      v-model="totalAmount"
      class="contract-info__textfield"
      label="Total contract value"
      placeholder="0.00"
      type="number"
      :rules="['required']"
      @on-blur="onBlur"
    >
      <template #subtext>
        <p class="contract-info__subtext">
          Total committed spend, including subscription fees, custom service
          fees, and account balance.
        </p>
      </template>
      <template #icon-left>
        <SoonaIcon size="medium" name="dollar-sign" />
      </template>
    </SoonaTextfield>
    <SoonaTextfield
      v-model="retainerAmount"
      class="contract-info__textfield"
      label="Total account value"
      placeholder="0.00"
      type="number"
      :rules="['required']"
      @on-blur="onBlur"
    >
      <template #subtext>
        <p class="contract-info__subtext">
          Total amount client can spend on assets, pro services, etc. (excludes
          subscription and custom service fees) as part of this contract.
        </p>
      </template>
      <template #icon-left>
        <SoonaIcon size="medium" name="dollar-sign" />
      </template>
    </SoonaTextfield>
    <SoonaTextfield
      v-model="startDate"
      class="contract-info__textfield"
      label="Contract start date"
      name="startDate"
      type="date"
      :rules="['required']"
      @on-blur="onBlur"
    />
    <SoonaTextfield
      v-model="duration"
      class="contract-info__textfield"
      label="Duration (in months)"
      type="number"
      placeholder="3 months"
      :rules="['integer', 'required']"
      @on-blur="onBlur"
    >
    </SoonaTextfield>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-info {
  display: grid;
  gap: 1.5rem 0.5rem;

  &__subtext {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-60;
  }

  &__textfield {
    padding-bottom: 0;

    :deep(.soona-textfield__input-outer) {
      margin-top: auto;
    }
  }

  &__calendar-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__date {
    @include variables_fonts.u-body--regular;
    height: 40px;
    background: variables.$white-default;
    border-color: variables.$gray-30;
    border-radius: 0.3125rem;
    border-style: solid;
    border-width: 0.0625rem;
    padding: 0.5rem;
    transition: 0.1s;
    width: 100%;
    padding-left: 2rem;
    position: relative;

    &::-webkit-input-placeholder {
      // NOTE: Not working
      color: variables.$gray-50;
    }

    &:hover {
      border-color: variables.$gray-50;
    }

    &:focus {
      border-color: variables.$black-default;
    }

    &::-webkit-calendar-picker-indicator {
      position: absolute;
      left: 0.5rem;
      color: variables.$gray-60;
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .contract-info {
    grid-template-columns: 50% 50%;
  }
}
</style>
