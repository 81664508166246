import { computed, toValue } from 'vue';
import { useContract as useContractQuery } from '@/queries/contracts/useContract';
import { toCurrency } from '@/lib/currency';

/**
 *
 * @param {Ref<number|string|undefined>} accountId
 * @param {Ref<number|string|undefined>} contractId
 */
export function useContract({ accountId, contractId }) {
  const {
    data: contract,
    error,
    isLoading,
    status,
    isSuccess,
  } = useContractQuery(
    { accountId, contractId },
    {
      enabled: computed(() => !!toValue(accountId) && !!toValue(contractId)),
    }
  );

  const contractTotal = computed(() => contract.value?.total_amount);
  const contractTotalDisplay = computed(() =>
    toCurrency(contractTotal.value, 'USD')
  );

  const accountCoordinator = computed(
    () => contract.value?.account_coordinator
  );

  const accountManager = computed(() => contract.value?.account_manager);

  const hasAccountManager = computed(() => !!accountManager.value?.id);

  const contractItems = computed(() => contract.value?.contract_items);

  const createdAt = computed(() => contract.value?.created_at);

  const discount = computed(() => contract.value?.discount);

  const endDate = computed(() => contract.value?.end_date);

  const enterpriseContractItem = computed(() =>
    contractItems.value?.find(ci => ci.subscription_type === 'business')
  );
  const fastPassContractItem = computed(() =>
    contractItems.value?.find(ci => ci.subscription_type === 'fast_pass')
  );

  const contractPrices = computed(() => contract.value?.contract_prices);

  const photoUnit = computed(() =>
    contractPrices.value?.find(cp => cp.unit_rate_type === 'photo')
  );
  const photoUnitRate = computed(() => photoUnit.value?.price);
  const photoUnitRateDisplay = computed(
    () =>
      toCurrency(photoUnitRate.value?.rate, photoUnitRate.value?.currency, 0) ??
      ''
  );

  const retainerAmount = computed(() => contract.value?.retainer_amount);
  const retainerAmountDisplay = computed(() =>
    toCurrency(retainerAmount.value, 'USD')
  );

  const videoUnit = computed(() =>
    contractPrices.value?.find(cp => cp.unit_rate_type === 'video')
  );
  const videoUnitRate = computed(() => videoUnit.value?.price);
  const videoUnitRateDisplay = computed(
    () =>
      toCurrency(videoUnitRate.value?.rate, videoUnitRate.value?.currency, 0) ??
      ''
  );

  const gifUnit = computed(() =>
    contractPrices.value?.find(cp => cp.unit_rate_type === 'gif')
  );
  const gifUnitRate = computed(() => gifUnit.value?.price);
  const gifUnitRateDisplay = computed(
    () =>
      toCurrency(gifUnitRate.value?.rate, gifUnitRate.value?.currency, 0) ?? ''
  );

  const startDate = computed(() => contract.value?.start_date);

  const ugcUnit = computed(
    () => contractPrices.value?.find(cp => cp.unit_rate_type === 'ugc') ?? ''
  );
  const ugcUnitRate = computed(() => ugcUnit.value?.price);
  const ugcUnitRateDisplay = computed(
    () => toCurrency(ugcUnitRate.value?.rate, ugcUnitRate.value?.currency) ?? ''
  );

  return {
    contract,
    accountCoordinator,
    accountManager,
    createdAt,
    contractPrices,
    contractTotal,
    contractTotalDisplay,
    discount,
    endDate,
    enterpriseContractItem,
    fastPassContractItem,
    gifUnit,
    gifUnitRate,
    gifUnitRateDisplay,
    hasAccountManager,
    photoUnit,
    photoUnitRate,
    photoUnitRateDisplay,
    retainerAmount,
    retainerAmountDisplay,
    startDate,
    ugcUnit,
    ugcUnitRate,
    ugcUnitRateDisplay,
    videoUnit,
    videoUnitRate,
    videoUnitRateDisplay,
    error,
    isLoading,
    status,
    isSuccess,
  };
}
