<script setup>
import { computed, useTemplateRef, watchEffect } from 'vue';
import { useMe } from '@/composables/user/useMe';
import { useCreateNote } from '@/queries/notes/useCreateNote';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useDigitalAssetAnnotate } from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import AnnotationPin from '@/components/notes/digital-asset-notes/AnnotationPin.vue';
import NoteComposer from '@/components/notes/NoteComposer.vue';

const props = defineProps({
  digitalAssetId: {
    type: [Number, String],
    default: null,
  },
  fullSizeHeight: {
    type: Number,
    defautl: null,
  },
  fullSizeWidth: {
    type: Number,
    defautl: null,
  },
  rotation: {
    type: Number,
    defautl: null,
  },
  fullSizeX: {
    type: Number,
    required: true,
  },
  fullSizeY: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['clear']);

const digitalAssetId = computed(() => props.digitalAssetId);

const createPin = useTemplateRef('create-pin');
const { me } = useMe();

const {
  mutate: createNote,
  isPending: isCreatingNote,
  error: createNoteError,
} = useCreateNote('digital_assets', digitalAssetId);

const priorityErrors = usePriorityErrors(createNoteError);

const { setActiveNoteId } = useDigitalAssetAnnotate();

const x = computed(() => {
  if (props.rotation === 90) {
    // if the image is rotated 90 degrees unrotated x is
    // now y pixels from the top edge of the rotated image
    return props.fullSizeY;
  } else if (props.rotation === 180) {
    // if the image is rotated 180 degrees unrotated x is
    // now x pixels from the right edge of the image
    return props.fullSizeWidth - props.fullSizeX;
  } else if (props.rotation === 270) {
    // if the image is rotated 270 degrees unrotated x is
    // now y pixels from the bottom edge of the image
    return props.fullSizeHeight - props.fullSizeY;
  }

  return props.fullSizeX;
});

const y = computed(() => {
  if (props.rotation === 90) {
    // if the image is rotated 90 degrees unrotated y is
    // now x pixels from the right edge of the rotated image
    return props.fullSizeWidth - props.fullSizeX;
  } else if (props.rotation === 180) {
    // if the image is rotated 180 degrees unrotated y is
    // now y pixels from the bottom edge of the image
    return props.fullSizeHeight - props.fullSizeY;
  } else if (props.rotation === 270) {
    // if the image is rotated 270 degrees unrotated y is
    // now x pixels from the left edge of the image
    return props.fullSizeX;
  }

  return props.fullSizeY;
});

function submitNote(e) {
  const form = e.target;
  const data = new FormData(form);
  const note = data.get('note')?.trim();

  if (!note) {
    return;
  }

  createNote(
    {
      content: note,
      location_metadata: {
        type: 'point',
        x: x.value,
        y: y.value,
      },
    },
    {
      onSuccess: data => {
        form?.reset();
        if (Number.isInteger(data.id)) {
          setActiveNoteId(data.id);
        }
        emit('clear');
      },
    }
  );
}

watchEffect(() => {
  if (createPin.value?.popover) {
    createPin.value.showPopover();
  }
});
</script>

<template>
  <div ref="create-pin" class="annotate-note-create" popover v-bind="$attrs">
    <AnnotationPin :avatar-src="me?.avatar_url" :name="me?.name" />
    <NoteComposer
      :is-loading="isCreatingNote"
      :priority-errors="priorityErrors"
      auto-focus
      :show-avatar="false"
      placeholder="create note"
      class="annotate-note-create__composer"
      @submit="submitNote"
    >
      <template #actions="{ isLoading, submitDisabled }">
        <SoonaButton
          type="button"
          variation="tertiary"
          size="medium"
          :disabled="isLoading"
          @click="$emit('clear')"
        >
          cancel
        </SoonaButton>
        <SoonaButton
          type="submit"
          variation="solid-black"
          size="medium"
          :loading="isLoading"
          :disabled="submitDisabled"
        >
          post
        </SoonaButton>
      </template>
    </NoteComposer>
  </div>
</template>

<style lang="scss" scoped>
.annotate-note-create {
  width: min(18rem, 100%);
  cursor: default;
  display: flex;
  gap: 0.25rem;
  margin: 0;
  padding: 0;
  border: none;
  background: none;

  /* start to remove (when dropping support for Safari < 17) */
  position: fixed;
  z-index: 2;
  &:popover-open {
    z-index: unset;
    position: unset;
  }
  /* end to remove */

  &__composer {
    flex: 1 1 auto;
  }
}
</style>
