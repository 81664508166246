<script setup>
import { computed, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import BeatLoader from '@/components/shared/BeatLoader.vue';

import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

import { useCapability } from '@/composables/useCapability';
import { useGetUser } from '@/queries/users/useGetUser';

import { useCreateStaffPickCollectionDigitalAsset } from '@/queries/staff_picks_collection_digital_assets/useCreateStaffPicksCollectionDigitalAsset';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import { useDeleteStaffPickCollectionDigitalAsset } from '@/queries/staff_picks_collection_digital_assets/useDeleteStaffPicksCollectionDigitalAsset';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

// props
const { disabled } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId } = storeToRefs(mediaEditorStore);

const {
  ownedBySoona,
  visibilityAll,
  rdaLoading,
  staffPickCDAId,
  expertPickId,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const route = useRoute();
const router = useRouter();

// capabilities
const { hasCapability: canManageCustomerCollections } = useCapability({
  capability: 'manage_customer_collections',
});

// picked by
const isExpertPickable = computed(() => {
  return (
    canManageCustomerCollections.value &&
    visibilityAll.value &&
    ownedBySoona.value
  );
});

const { data: user } = useGetUser(expertPickId, {
  enabled: computed(() => !!expertPickId.value && isExpertPickable.value),
});

// mutate
const { data: staffPicksCollection } = useStaffPicksCollection(assetAccountId, {
  enabled: computed(() => !!assetAccountId.value),
});
const staffPicksCollectionId = computed(() => staffPicksCollection.value?.id);

const {
  mutate: mutateAddtoStaffPicks,
  isPending: mutateAddtoStaffPicksPending,
} = useCreateStaffPickCollectionDigitalAsset(staffPicksCollectionId);

const {
  mutate: mutateRemoveFromStaffPicks,
  isPending: mutateRemoveFromStaffPicksPending,
} = useDeleteStaffPickCollectionDigitalAsset(
  staffPicksCollectionId,
  staffPickCDAId
);

const addToStaffPicks = () => {
  if (!isExpertPickable.value) return;
  if (!staffPicksCollectionId.value) return;

  mutateAddtoStaffPicks({
    digital_asset_id: assetId.value,
  });
};
const removeFromStaffPicks = () => {
  if (!isExpertPickable.value) return;
  if (!staffPicksCollectionId.value || !staffPickCDAId.value) return;

  mutateRemoveFromStaffPicks(
    {
      digital_asset_id: props.asset?.id,
    },
    {
      onSuccess: async () => {
        // if we're in the expert picks collection view, the current asset will start to 404, so we need to go back
        if (route.name === 'expert-picks-assets-media-view') {
          await router.push({
            name: 'expert-picks-assets',
            params: {
              accountId: assetAccountId.value,
            },
          });
        }
      },
    }
  );
};

const isMutating = computed(
  () =>
    rdaLoading.value ||
    mutateAddtoStaffPicksPending.value ||
    mutateRemoveFromStaffPicksPending.value
);
</script>

<template>
  <SoonaTooltip
    v-if="isExpertPickable && ((disabled && staffPickCDAId) || !disabled)"
    placement="top"
  >
    <template
      #default="{
        setRef,
        mouseenter,
        focus,
        mouseleave,
        blur,
        ariaDescribedby,
      }"
    >
      <button
        :ref="el => setRef(el)"
        v-bind="$attrs"
        type="button"
        class="u-button-reset expert-pick expert-pick--staff expert-pick--static"
        :class="{
          'expert-pick--selected': staffPickCDAId,
        }"
        :disabled="disabled"
        :aria-describedby="ariaDescribedby"
        :data-cypress="
          staffPickCDAId ? undefined : `add-${assetId}-to-expert-picks`
        "
        @click="staffPickCDAId ? removeFromStaffPicks() : addToStaffPicks()"
        @mouseenter="!disabled && staffPickCDAId ? mouseenter() : undefined"
        @focus="!disabled && staffPickCDAId ? focus() : undefined"
        @mouseleave="!disabled && staffPickCDAId ? mouseleave() : undefined"
        @blur="!disabled && staffPickCDAId ? blur() : undefined"
      >
        <BeatLoader
          v-if="isMutating"
          class="expert-pick__loader"
          size="0.75em"
        />
        <template v-else>
          <SoonaIcon name="trophy" size="small" />
          expert pick
        </template>
      </button>
    </template>
    <template v-if="user" #tooltip-content>{{ user.name }}’s pick</template>
  </SoonaTooltip>
  <em
    v-if="!isExpertPickable && staffPickCDAId"
    v-bind="$attrs"
    class="expert-pick expert-pick--selected expert-pick--static"
  >
    <SoonaIcon name="trophy-solid" size="small" />
    expert pick
  </em>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.expert-pick {
  @include variables_fonts.u-label--heavy;

  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  min-width: 5.5625rem;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-style: normal;
  background-color: variables.$white-translucent-80;
  color: variables.$black-default;
  padding: 0.375rem 1rem;
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 1.5rem;
  transition:
    background-color 0.1s ease-out,
    color 0.1s ease-out,
    border-color 0.1s ease-out;

  svg {
    color: variables.$gray-60;
    flex-shrink: 0;
    transition: color 0.1s ease-out;
  }

  &--staff {
    pointer-events: auto;

    &:disabled {
      pointer-events: none;
    }
  }

  &--staff:hover,
  &--staff:focus-visible {
    background-color: variables.$gray-10;
    border-color: variables.$gray-40;
  }

  &--selected {
    background-color: variables.$black-translucent-83;
    color: variables.$white-default;
    border-color: variables.$black-translucent-83;

    svg {
      color: variables.$tangerine-40;
    }

    &.expert-pick--staff:hover:not(:disabled),
    &.expert-pick--staff:focus-visible:not(:disabled) {
      background-color: variables.$gray-80;
      border-color: variables.$gray-80;
    }
  }

  &--static {
    position: static;
  }

  &__loader {
    margin: auto;
    width: 50%;
  }
}
</style>
