import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {MaybeRef<number>} [params.accountRequirement]
 * @param {MaybeRef<number>} [params.autoApply]
 * @param {MaybeRef<number>} [params.itemsPerPage]
 * @param {MaybeRef<number>} [params.currentPage]
 * @param {MaybeRef<string>} [params.orderBy]
 * @param {MaybeRef<string>} [params.orderDirection]
 * @param {Object} [queryOptions]
 */
export function useDiscounts(
  {
    accountRequirement,
    autoApply,
    itemsPerPage = 25,
    currentPage = 1,
    orderBy,
    orderDirection,
  } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.discounts({
      accountRequirement,
      autoApply,
      itemsPerPage,
      currentPage,
      orderBy,
      orderDirection,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (accountRequirement) {
        params['account_requirement'] = toValue(accountRequirement);
      }
      if (autoApply) params['auto_apply'] = toValue(autoApply);
      if (orderBy) params['order_by'] = toValue(orderBy);
      if (orderDirection) params['direction'] = toValue(orderDirection);

      const response = await http.get(`/discounts`, {
        params,
        signal,
      });

      return {
        pagination: mapPaginationHeaders(response.headers),
        discounts: response.data?.discounts || [],
      };
    },
    ...queryOptions,
  });
}
