<script setup>
import { computed, toRefs, ref } from 'vue';

import { useMe } from '@/composables/user/useMe';
import { useCapability } from '@/composables/useCapability';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import BatchEditDialog from '@/components/batch_edits/BatchEditDialog.vue';
import SubscriptionsDialog from '@/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['close', 'remove-selected-asset']);
const { selectedAssets, accountId } = toRefs(props);

const showBatchEditDialog = ref(false);
const showSubscriptionsDialog = ref(false);

const { currentAccountId } = useMe();
const { hasLiveSubscription } = useActiveSubscription(accountId);
const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const isMyAccount = computed(() => currentAccountId.value === accountId.value);

// batch
function handleBulkEditClick() {
  if (hasLiveSubscription.value || isSoonaStaff.value) {
    showBatchEditDialog.value = true;
  } else {
    showSubscriptionsDialog.value = true;
  }
}
</script>

<template>
  <SoonaTooltip placement="top">
    <template
      #default="{
        setRef,
        mouseenter,
        focus,
        mouseleave,
        blur,
        ariaDescribedby,
      }"
    >
      <SoonaButton
        v-bind="$attrs"
        :ref="setRef"
        :variation="
          hasLiveSubscription || isSoonaStaff ? 'icon-transparent' : 'pizzazz'
        "
        :aria-describedby="ariaDescribedby"
        :disabled="!isMyAccount"
        @blur="blur"
        @focus="focus"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
        @on-click="handleBulkEditClick"
      >
        <SoonaIcon name="pen-swirl" />
        <span class="u-visually-hidden">batch edit</span>
      </SoonaButton>
    </template>

    <template #tooltip-content>batch edit</template>
  </SoonaTooltip>

  <BatchEditDialog
    v-if="showBatchEditDialog"
    :account-id="accountId"
    :selected-assets="selectedAssets"
    @close="showBatchEditDialog = false"
    @reset-selected-assets="emit('close')"
    @remove-selected-asset="asset => emit('remove-selected-asset', asset)"
  />
  <SubscriptionsDialog
    v-if="showSubscriptionsDialog"
    selected-tier-slug="tier-one"
    @close="showSubscriptionsDialog = false"
  />
</template>
