<script setup>
import {
  computed,
  watch,
  ref,
  provide,
  toRefs,
  useId,
  watchEffect,
  onMounted,
  onUnmounted,
} from 'vue';
import {
  onKeyStroke,
  useMediaQuery,
  useTimeoutFn,
  useTitle,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import TopActionBarBase from '@/components/user/anytime/gallery/media-editor/action-bars/TopActionBarBase.vue';
import MediaAsset from '@/components/user/anytime/gallery/media-editor/MediaAsset.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import { truncate } from '@/lib/text';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';
import DownloadDigitalAsset from '@/components/user/anytime/gallery/media-editor/download/DownloadDigitalAsset.vue';
import FlagsAndActions from '@/components/user/anytime/reservation/crew_edits/asset_view/FlagsAndActions.vue';
import { NOTES_ACTION } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import DigitalAssetNotesList from '@/components/notes/digital-asset-notes/DigitalAssetNotesList.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import DigitalAssetNotesDialog from '@/components/notes/digital-asset-notes/DigitalAssetNotesDialog.vue';
import { useDigitalAssetAnnotateProvider } from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  editsCollectionDigitalAsset: {
    type: Object,
    default: undefined,
  },
  isFileLoading: {
    type: Boolean,
    default: false,
  },
});

const emits = defineEmits(['close']);

const { asset, isFileLoading } = toRefs(props);

const mediaEditorStore = useMediaEditorStore();

onMounted(() => {
  mediaEditorStore.setActiveAction(NOTES_ACTION);
});

onUnmounted(() => {
  mediaEditorStore.forceSetActiveAction(null);
});

const showAnnotations = ref(true);
const setActiveAction = action => {
  if (action === NOTES_ACTION) {
    mediaEditorStore.setActiveAction(action);
  } else {
    mediaEditorStore.forceSetActiveAction(action);
  }

  showAnnotations.value = action === NOTES_ACTION;
};

const assetId = computed(() => asset.value?.id);
const assetAccountId = computed(() => asset.value?.account_id);

const { mediaUrl, title } = useMediaEditorDigitalAsset(assetAccountId, assetId);

const popoverRef = ref();
const id = uniqueId('media-editor-');
const { checkAllowEscapeClose } = useDialogContext({ id });
provide(TELEPORT_TO, popoverRef);

const { isPending: isClosing } = useTimeoutFn(
  e => {
    emits('close', e);
  },
  150,
  { immediate: false }
);

function closePopover(e) {
  emits('close', e);
}

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');
useFocusTrap(popoverRef, { immediate: true });
const fileTitle = computed(() => {
  return truncate(asset.value?.title, 50, true);
});
const tabTitle = computed(() => `${fileTitle.value ?? 'AI studio'} | soona`);
useTitle(tabTitle);
const handleClose = () => {
  closePopover();
};
// lifecycle hooks
onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    handleClose();
  }
});
const { pageViewed } = useBaseEvents();
watch(
  assetId,
  () => {
    pageViewed();
    mediaEditorStore.setAssetId(assetId.value);
    mediaEditorStore.setAssetAccountId(assetAccountId.value);
  },
  { immediate: true }
);

const mobileNotesOpen = ref(false);
watchEffect(() => {
  if (matchMediaIsWide.value) {
    mobileNotesOpen.value = false;
  }
});
const notesSectionHeadingId = useId();
useDigitalAssetAnnotateProvider(assetId);
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="media-editor"
      :class="{
        'media-editor--closing': isClosing,
      }"
      role="dialog"
      aria-modal="true"
    >
      <TopActionBarBase :is-wide="matchMediaIsWide">
        {{ title }}
        <template #actions>
          <div class="media-editor__header-actions">
            <FlagsAndActions
              v-if="asset"
              :asset="asset"
              :edits-collection-digital-asset="editsCollectionDigitalAsset"
            />
            <DownloadDigitalAsset :asset="asset" />
            <SoonaButton
              v-if="!matchMediaIsWide"
              variation="secondary-gray"
              size="medium"
              @on-click="mobileNotesOpen = !mobileNotesOpen"
            >
              <SoonaIcon name="message-circle-lines-alt" />
              notes
            </SoonaButton>
          </div>
        </template>
      </TopActionBarBase>
      <div class="media-editor__editing-panel">
        <div class="media-editor__middle-column">
          <slot name="error" />
          <div class="media-editor__img-wrapper">
            <div class="media-editor__annotate-toggle">
              <SoonaButton
                v-if="showAnnotations"
                variation="icon-gray-outline"
                @click="setActiveAction(null)"
              >
                <SoonaIcon name="search" />
              </SoonaButton>
              <SoonaButton
                v-else
                variation="icon-gray-outline"
                @click="setActiveAction(NOTES_ACTION)"
              >
                <SoonaIcon name="memo-pencil" />
              </SoonaButton>
            </div>
            <MediaAsset
              v-if="asset"
              :is-file-loading="isFileLoading"
              :src-url="mediaUrl"
            />
          </div>
        </div>
        <section
          v-if="matchMediaIsWide"
          class="editors-media-editor-notes-wrap"
          :aria-labelledby="notesSectionHeadingId"
        >
          <h3 :id="notesSectionHeadingId" class="u-subheader--heavy">notes</h3>
          <DigitalAssetNotesList
            v-if="assetId && assetAccountId"
            :digital-asset-id="assetId"
            :account-id="assetAccountId"
          />
        </section>
        <DigitalAssetNotesDialog
          v-if="mobileNotesOpen && assetId && assetAccountId"
          :account-id="assetAccountId"
          :digital-asset-id="assetId"
          @close="mobileNotesOpen = false"
        />
      </div>
      <slot name="carousel" />
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
@use 'src/variables';
@use 'src/variables_fonts';

.media-editor {
  background-color: variables.$gray-10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__header-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  &__editing-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    @media (min-width: variables.$screen-md-min) {
      flex-direction: row;
    }
  }

  &__re-edit-note {
    max-width: 60rem;
  }

  &__middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: padding-right 0.3s cubic-bezier(0.22, 1, 0.36, 1);

    &--no-overflow {
      overflow-y: hidden;
    }
  }

  &__img-wrapper {
    align-items: center;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    justify-items: center;
    min-height: 0;
    padding: 1rem 0 1rem 1rem;
    width: 100%;

    &--dense {
      gap: 0rem;
      padding: 0;
    }
  }

  &__annotate-toggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 0.5;
  }
}

.editors-media-editor-notes-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  width: 20rem;
  overflow: hidden;
  background-color: variables.$white-default;
  position: relative;
  border: solid variables.$gray-30;
  border-width: 0 0 0 0.0625rem;
  overflow-y: auto;
  padding: 1rem;
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
</style>
