<template>
  <div>
    <section class="soona-payment-booking">
      <div
        v-if="isActiveRetainerClient && paymentsRetainerMvpFlag"
        class="soona-payment-booking__stripe_payment"
      >
        <SoonaRetainerPayment
          v-show="!collapsed"
          :account-id="currentAccountId"
          :amount="downPaymentOrderTotal"
          :tax-amount="taxAmount"
          @payment-action="paymentConfirmed"
        />
      </div>
      <div
        v-else-if="stripePaymentRequired"
        class="soona-payment-booking__stripe_payment"
      >
        <h1
          v-if="!suggestedBookingProcess"
          class="soona-payment-booking__title"
        >
          Payment
        </h1>
        <SoonaPaymentMethods
          v-show="!collapsed"
          class="soona-payment-booking__payment-methods"
          :stripe-payment-required="
            stripePaymentRequired && !hasShopifyIntegration
          "
          :account-id="currentAccountId"
          :total="
            phoenixStudioRentalFeeFlag
              ? membershipOrderTotal
              : downPaymentOrderTotal
          "
          :on-payment-action="paymentConfirmed"
          :payment-method-types="['card', 'us_bank_account']"
          :action-text="buttonCopy"
          button-variation="primary"
          has-wide-payment-button
        >
          <template #promo-code-button>
            <PromoCode
              :reservation-id="reservationId"
              :order-id="reservation?.down_payment_order?.id"
            />
          </template>
        </SoonaPaymentMethods>
      </div>
      <div v-else class="soona-payment-booking__shopify-billing">
        <ShopifyIntegrationPrimarySelect
          class="soona-payment-booking__shopify-integration-select"
          :account-id="currentAccountId"
          label="billed Shopify shop"
        />
      </div>
      <PromoCode
        v-if="
          (hasShopifyIntegration || suggestedBookingProcess) &&
          !isHeadshotsForAll
        "
        :reservation-id="reservationId"
        :order-id="reservation?.down_payment_order?.id"
      />
      <SoonaAlert v-if="isHeadshotsForAll">
        headshots for all bookings cannot be rescheduled! we are unable to
        create another complementary headshots for all booking if you fail to
        show up.
      </SoonaAlert>
      <SoonaError v-if="hasReservationAvailabilityErrors">
        <router-link to="./schedule">click here</router-link> to select a new
        day and time for your shoot.
      </SoonaError>
      <template
        v-if="
          !suggestedBookingProcess &&
          (isDownPaymentZero ||
            isFree ||
            isHeadshotsForAll ||
            hasShopifyIntegration) &&
          !isActiveRetainerClient
        "
      >
        <SoonaButton
          type="button"
          :is-submit="true"
          data-cypress="payment-submit"
          :on-click="paymentAction"
          :disabled="
            (showCardForm && stripeErrorText !== undefined) ||
            (!canManageDefaultPayment && !hasDefaultPayment) ||
            !billingAddressId ||
            hasReservationAvailabilityErrors
          "
          class="soona-payment-booking__submit"
        >
          {{ buttonCopy }}
        </SoonaButton>
        <SoonaError v-if="updateError">
          we are having trouble saving this booking as a draft. please try again
          later.
        </SoonaError>
        <div class="soona-payment-booking__draft">
          <button
            class="u-button-reset"
            data-cypress="save-draft"
            :disabled="isDraft || isMutating"
            @click="saveAsDraft"
          >
            save draft
          </button>
          <SoonaHelperText title="save draft">
            this booking will be saved as a draft in your account
          </SoonaHelperText>
        </div>
        <SaveDraft v-if="isDraft" :reservation-id="reservationId" />
        <div
          v-if="showMarketingEmailConsent"
          class="soona-payment-booking__marketing-email-consent"
        >
          <MarketingEmailConsent
            v-model="marketingEmailConsent"
            cypress-name="checkbox-marketing-email-consent"
            @update:model-value="
              inputChanged({
                context: 'booking',
                subContext: 'down payment',
                inputLabel:
                  'receive ecomm tips. inspiration. and special offers from soona. view our privacy policy',
                inputType: 'checkbox',
                inputValue: marketingEmailConsent,
              })
            "
          />
        </div>
      </template>
    </section>
    <small class="soona-payment-booking__commitment">
      <template
        v-if="
          !isHeadshotsForAll &&
          (!isActiveRetainerClient || !paymentsRetainerMvpFlag)
        "
      >
        our crew commitment: we believe in quality content for all. if you don’t
        LOVE your content <b>we make it right</b>. please review our
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.soona.co/refund-policy"
          @click="cancellationAndRefundPolicyClicked"
          >cancellation and refund policy</a
        >. by placing an order with soona you agree to our
        <a
          href="https://soona.co/terms/"
          target="_blank"
          @click="termsOfServiceClicked"
          >terms of service</a
        >

        ({{ termsOfServiceDate }}).
      </template>
    </small>
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapActions, mapGetters, mapMutations, mapState, useStore } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import { useMe } from '@/composables/user/useMe';
import { useAccount } from '@/composables/useAccount';
import * as types from 'src/store/mutation-types';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import SaveDraft from 'src/components/booking/SaveDraft.vue';
import SoonaHelperText from '@/components/ui_library/SoonaHelperText.vue';
import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import { useReservationAvailabilityErrors } from 'src/queries/useReservation';
import { useUpdateUser } from 'src/queries/users/useUpdateUser';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { useReservation } from '@/composables/useReservation';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useUpdateReservation } from 'src/queries/useUpdateReservation';
import SoonaError from 'src/components/ui_library/SoonaError.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaPaymentMethods from 'src/components/SoonaPaymentMethods.vue';
import SoonaRetainerPayment from 'src/components/SoonaRetainerPayment.vue';
import MarketingEmailConsent from 'src/components/shared/MarketingEmailConsent.vue';
import { useIntegrations } from '@/composables/useIntegrations';
import { useFlag } from '@/composables/useFlag';
import PromoCode from '@/components/PromoCode.vue';
import ShopifyIntegrationPrimarySelect from '../user/anytime/products/shopify/ShopifyIntegrationPrimarySelect.vue';

let elementStyles = {
  base: {
    fontFamily: 'Lato, Helvetica Neue, Helvetica, Arial, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '1.5',
    textTransform: 'lowercase',
    color: '#000',
    backgroundColor: '#fff',
    border: '1px solid blue',
    '::placeholder': {
      color: '#70747B',
    },
  },
  invalid: {
    color: '#F03742',
    iconColor: '#F03742',
  },
};

var elementClasses = {
  focus: 'focused',
  empty: 'empty',
  invalid: 'invalid',
};
export default {
  name: 'SoonaPaymentBooking',
  components: {
    SoonaAlert,
    SoonaPaymentMethods,
    SoonaRetainerPayment,
    MarketingEmailConsent,
    SoonaButton,
    SaveDraft,
    SoonaHelperText,
    SoonaError,
    PromoCode,
    ShopifyIntegrationPrimarySelect,
  },
  props: {
    onPaymentAction: Function,
    stripePaymentRequired: Boolean,
    reservationId: {
      required: true,
    },
    taxAmount: {
      type: [String, Number],
      required: false,
    },
    collapsed: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const { me, currentAccountId, currentUserId } = useMe();
    const { hasShopifyIntegration } = useIntegrations(currentAccountId);
    const { account, billingAddressId, isActiveRetainerClient } =
      useAccount(currentAccountId);

    const reservationId = computed(() => props.reservationId);

    const { data: reservationAvailabilityErrors } =
      useReservationAvailabilityErrors(reservationId);

    const {
      reservation,
      reservationAccountId,
      isFree,
      isDownPaymentZero,
      isPack,
      isHeadshotsForAll,
      downPaymentOrderTotal,
    } = useReservation(reservationId);

    const { inputChanged, buttonClicked, linkClicked } = useBaseEvents();

    const {
      mutate,
      isPending: isMutating,
      isError: updateError,
    } = useUpdateReservation(reservationId);

    const { mutate: updateUser } = useUpdateUser(currentUserId);
    const { mutate: updateAccount } = useUpdateAccount(currentAccountId);

    const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;

    const phoenixByoDdpFlag = useFlag('phoenix_byo_ddp');
    const paymentsRetainerMvpFlag = useFlag('payments_retainer_mvp');

    const paymentAccount = computed(
      () => store.state.account.id ?? currentAccountId
    );

    const { hasCapability: canManageDefaultPayment } = useCapability({
      capability: 'manage_default_payment',
      subjectType: 'account',
      subjectId: paymentAccount,
    });

    const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
    const membershipOrderTotal = computed(() => {
      if (
        (reservation.value?.studio_access?.level == 'tier-one' ||
          reservation.value?.studio_access?.level == 'tier-two') &&
        reservation.value?.studio_access?.billing_cycle != null
      ) {
        return 1;
      } else {
        return reservation.value?.down_payment_order_total;
      }
    });

    return {
      account,
      billingAddressId,
      buttonClicked,
      canManageDefaultPayment,
      currentAccountId,
      downPaymentOrderTotal,
      hasShopifyIntegration,
      inputChanged,
      isActiveRetainerClient,
      isDownPaymentZero,
      isFree,
      isHeadshotsForAll,
      isMutating,
      isPack,
      linkClicked,
      me,
      membershipOrderTotal,
      mutate,
      phoenixByoDdpFlag,
      paymentsRetainerMvpFlag,
      phoenixStudioRentalFeeFlag,
      reservation,
      reservationAccountId,
      reservationAvailabilityErrors,
      termsOfServiceDate,
      updateAccount,
      updateError,
      updateUser,
    };
  },
  data() {
    return {
      stripe: null,
      elements: null,
      card: null,
      showCardForm: false,
      isProcessing: false,
      stripeErrorText: undefined,
      isComplete: false,
      isDraft: false,
      isLoaded: false,
      marketingEmailConsent: true,
    };
  },
  computed: {
    ...mapState({
      paymentInfo: state => state.payment.defaultPaymentInfo,
      payment: state => state.payment,
      errors: state => state.errors.errors,
    }),
    ...mapGetters(['suggestedBookingProcess']), // TODO: fold suggestedBookingProcess into useReservation.js (composable)
    ...mapGetters('flash', ['errorMessages']),
    hasReservationAvailabilityErrors() {
      return this.reservationAvailabilityErrors?.schedule_status === 'error';
    },
    buttonCopy() {
      if (
        (this.isPack || !this.phoenixByoDdpFlag) &&
        !this.isFree &&
        !this.isDownPaymentZero
      ) {
        return 'confirm & pay';
      }

      return 'confirm';
    },
    canManageDefaultPaymentDefined() {
      return !isUndefined(this.canManageDefaultPayment);
    },
    hasDefaultPayment() {
      return this.me.accounts.find(
        account => account.id === this.currentAccountId
      )?.saved_default_card;
    },
    showStripeErrorText() {
      return isUndefined(
        this.errorMessages?.find(o => o.text === this.stripeErrorText)
      );
    },
    saveCard: {
      get() {
        return this.requireSavedPayment ? true : this.payment.saveCard;
      },
      set(value) {
        this.requireSavedPayment
          ? this.SET_SAVE_CARD(true)
          : this.SET_SAVE_CARD(value);
      },
    },
    requireSavedPayment() {
      return this.$route.name === 'fast-pass' ? true : false;
    },
    expMonth() {
      //Add leading zero if single digit
      return ('0' + this.paymentInfo.exp_month).slice(-2);
    },
    expYear() {
      //Only show last two year digits
      return this.paymentInfo.exp_year % 100;
    },
    showMarketingEmailConsent() {
      return !this.suggestedBookingProcess && !this.me.marketing_email_consent;
    },
  },
  mounted() {
    this.isLoaded = true;
  },
  methods: {
    ...mapActions(['loadReservation']),
    ...mapMutations('payment', [types.SET_SAVE_CARD]),
    ...mapActions('payment', ['fetchDefaultPayment']),
    ...mapActions('account', ['loadAccount']),
    ...mapActions('flash', ['addFlashMessage']),
    ...mapMutations('flash', [types.REMOVE_FLASH_MESSAGE]),
    cancellationAndRefundPolicyClicked() {
      this.linkClicked({
        context: 'booking',
        subContext: 'down payment',
        linkLabel: 'cancellation and refund policy',
        linkHref: 'https://www.soona.co/refund-policy',
      });
    },
    termsOfServiceClicked() {
      this.linkClicked({
        context: 'booking',
        subContext: 'down payment',
        linkLabel: 'terms of service',
        linkHref: 'https://www.soona.co/terms',
      });
    },
    setupPaymentForm() {
      if (!this.hasDefaultPayment && this.stripePaymentRequired) {
        this.showCardForm = true;
        this.mountStripeElement();
      } else if (this.hasDefaultPayment && this.stripePaymentRequired) {
        this.fetchDefaultPayment(this.currentAccountId);
      }
    },
    toggle() {
      this.mountStripeElement();
      this.showCardForm = true;
      this.removeFlashMessages();
    },
    removeFlashMessages() {
      if (this.errorMessages?.length > 0) {
        const flashMessagesToRemove = this.errorMessages?.filter(
          o => o.timeout
        );
        for (let i = 0; i < flashMessagesToRemove.length; i++) {
          this.REMOVE_FLASH_MESSAGE(flashMessagesToRemove[i]);
        }
      }
    },
    mountStripeElement() {
      if (window.Stripe) {
        this.stripe = window.Stripe(import.meta.env.VITE_STRIPE_PUBLIC_API_KEY);
        this.elements = this.stripe.elements();
        this.card = this.elements.create('card', {
          style: elementStyles,
          classes: elementClasses,
          hidePostalCode: true,
        });
        this.card.mount(this.$refs.card_element);
        this.card.on('change', event => {
          // Clears out the ephemeral 'flash' error messages, to stop any prior error from continuing
          // to "yell" at the user after they've potentially resolved it
          this.removeFlashMessages();

          // Sets immediate JS-caught Stripe validation errors
          // (i.e. not server-returned errors)
          if (event.error) {
            this.stripeErrorText = event.error.message;
          } else {
            this.stripeErrorText = undefined;
          }
          if (event.complete) {
            this.isComplete = true;
            this.stripeErrorText = undefined;
          }
        });
        this.card.on('blur', () => {
          this.inputChanged({
            context: 'booking',
            subContext: 'down payment',
            inputLabel: 'card number',
            inputType: 'input',
          });
        });
      } else {
        console.error(
          'Stripe does not exist on the window. Could not initialize Stripe or elements.'
        );
      }
    },
    async paymentConfirmed(
      paymentMethodId,
      paymentMethodType,
      savePaymentMethod
    ) {
      try {
        if (this.showMarketingEmailConsent) {
          this.updateUser({
            marketing_email_consent: this.marketingEmailConsent,
          });
        }

        await this.onPaymentAction(
          paymentMethodId,
          paymentMethodType,
          savePaymentMethod
        );

        this.buttonClicked({
          context: 'booking',
          subContext: 'down payment',
          buttonLabel: this.buttonCopy,
          buttonAction: 'paymentAction',
        });
      } catch (error) {
        console.error(error);
      }
    },
    async paymentAction() {
      try {
        if (isObject(this.card)) {
          this.card.update({ disabled: true });
        }
        if (this.showMarketingEmailConsent) {
          this.updateUser({
            marketing_email_consent: this.marketingEmailConsent,
          });
        }

        await this.onPaymentAction(this.stripe, this.card, this.saveCard);

        this.buttonClicked({
          context: 'booking',
          subContext: 'down payment',
          buttonLabel: this.buttonCopy,
          buttonAction: 'paymentAction',
        });
      } catch (error) {
        console.error(error);
      } finally {
        if (isObject(this.card)) {
          this.card.update({ disabled: false });
        }
      }
    },
    saveAsDraft() {
      this.linkClicked({
        context: this.$route.meta.context,
        subContext: this.$route.meta.page_title,
        linkLabel: 'save draft',
        linkHref: '#saveDraft',
      });

      const dataToSubmit = {
        draft_type: 'client_draft',
      };

      this.mutate(dataToSubmit, {
        onSuccess: () => {
          this.isDraft = true;
        },
      });
    },
    handleSaveCard() {
      this.inputChanged({
        context: 'booking',
        subContext: 'down payment',
        inputLabel: 'save my payment method for faster check out',
        inputType: 'checkbox',
        inputValue: this.saveCard,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-payment-booking {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-items: center;
  border: 1px solid variables.$gray-30;
  border-radius: 0.625rem;
  padding: 1.5rem 0.75rem;
  background-color: variables.$periwink-blue-10;

  .booking-downpayment__payment--no-frame &,
  .downpayment__payment--no-frame & {
    padding-left: 0;
    padding-right: 0;

    .booking-downpayment__layout--h4a &,
    .downpayment__layout--h4a & {
      padding-top: 0;
    }
  }

  .booking-downpayment__payment--no-frame &,
  .downpayment__payment--no-frame & {
    border: 0;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;

    &::before {
      display: none;
    }

    .booking-downpayment__layout--h4a &,
    .downpayment__layout--h4a & {
      padding-top: 0;
    }
  }

  .booking-downpayment__payment--no-number &,
  .downpayment__payment--no-number & {
    &::before {
      display: none;
    }
  }

  &__stripe_payment {
    width: 100%;
  }

  &__shopify-billing {
    display: flex;
    justify-content: center;
  }

  &__shopify-integration-select {
    width: max-content;
  }

  &__title {
    @include variables_fonts.u-title--heavy;

    color: variables.$black-default;
    text-transform: lowercase;
  }

  &__payment-methods {
    margin-top: 1.25rem;
  }

  &__card {
    border: 0.0625rem solid variables.$gray-30;
    padding: 0.5rem;
    background-color: variables.$white-default;
    border-radius: 0.3125rem;
    transition: border-color 0.1s ease-out;
    margin-top: 1.25rem;

    &:hover {
      border-color: variables.$gray-50;
    }
  }

  &__secure {
    @include variables_fonts.u-label--regular;

    display: flex;
    gap: 0.25rem;
    align-items: center;
    color: variables.$black-default;
    margin: 1.25rem 0;

    > svg {
      display: block;
      flex: 0 0 1rem;
    }
  }

  &__submit {
    display: flex;
    width: fit-content;

    &:first-child {
      margin-top: 1.25rem;
    }
  }

  &__draft {
    display: flex;
    align-items: center;
    justify-content: center;

    > button {
      @include variables_fonts.u-body--regular;

      color: variables.$black-default;
      text-decoration: underline;
      margin-right: 0.5rem;
      transition: color 0.1s ease-out;

      &:hover {
        text-decoration: none;
      }

      &:disabled {
        color: variables.$gray-40;
        cursor: default;
      }
    }
  }

  &__marketing-email-consent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1.25rem auto;
    max-width: 18.75rem;
  }

  &__commitment {
    @include variables_fonts.u-small--regular;

    display: block;
    margin-top: 0.75rem;

    b {
      font-weight: 800;
    }

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: variables.$black-default;
      }
    }

    .booking-downpayment__layout--h4a &,
    .downpayment__layout--h4a & {
      text-align: center;
    }
  }

  &__default-payment {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1.25rem 0;
  }

  &__default-card {
    display: block;
    width: 1.6875rem;
    height: 1.5rem;
    margin: 0 0.75rem 0 0.25rem;
    flex: 0 0 1.6875rem;
  }

  &__card-saved {
    margin-right: auto;
  }

  &__card-masked {
    @include variables_fonts.u-body--heavy;

    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: variables.$black-default;
  }

  &__card-brand {
    margin-right: 0.25rem;
  }

  &__card-exp {
    @include variables_fonts.u-label--regular;

    display: block;
  }

  &__change {
    @include variables_fonts.u-label--regular;

    margin-left: 0.5rem;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.soona-notification + .soona-payment-booking__submit {
  margin-top: 1.25rem;
}

@media (min-width: variables.$screen-sm-min) {
  .soona-payment-booking {
    padding: 1.5rem;

    &__title {
      @include variables_fonts.u-headline--heavy;
    }
  }
}

@media (min-width: variables.$screen-sm-min) {
  .soona-payment-booking {
    .booking-downpayment__payment--no-frame &,
    .booking-downpayment__payment--no-number &,
    .downpayment__payment--no-frame &,
    .downpayment__payment--no-number & {
      margin-top: 0;
    }
  }
}
</style>
