<script setup>
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

import LiveStaffGif from './LiveStaffGif.vue';
import LiveStaffRotate from './LiveStaffRotate.vue';
import LiveStaffHideAsset from './LiveStaffHideAsset.vue';
import LiveStaffUnarchive from './LiveStaffUnarchive.vue';
import LiveStaffUploadRaw from './LiveStaffUploadRaw.vue';
import SoonaSegmentedRadios from '@/components/ui_library/SoonaSegmentedRadios.vue';

const liveShootStore = useLiveShootStore();
const { assetId, assetAccountId } = storeToRefs(liveShootStore);

const selectedVariation = defineModel('selectedVariation');

const { asset, isGif, isBagged, isOriginal, assetLoading, imageVariations } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);
</script>

<template>
  <div class="live-staff-action-bar">
    <div class="live-staff-action-bar__section">
      <SoonaSegmentedRadios
        v-if="(isOriginal && !isGif) || assetLoading"
        class="live-staff-action-bar__variation-picker"
        label="visible variant"
        :label-visually-hidden="true"
        name="visible-variant"
        :options="imageVariations"
        :model-value="selectedVariation"
        @update:model-value="selectedVariation = $event"
      />
    </div>

    <div class="live-staff-action-bar__section">
      <LiveStaffRotate />
      <LiveStaffHideAsset v-if="!isBagged" />
      <LiveStaffUnarchive />
      <LiveStaffUploadRaw
        v-if="asset"
        :digital-asset="asset"
        variation="secondary-gray"
      />
      <LiveStaffGif />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-staff-action-bar {
  display: flex;
  min-height: 2.5rem;
  align-items: center;
  justify-content: space-between;

  &__variation-picker {
    margin-bottom: 0;
  }

  &__section {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }
}
</style>
