import { defineStore } from 'pinia';
import { computed, readonly, ref } from 'vue';
import { RESERVATION_ADDITIONAL_ORDER_STORE_ID } from '@/stores/keys';

export const useReservationAdditionalOrderStore = defineStore(
  RESERVATION_ADDITIONAL_ORDER_STORE_ID,
  () => {
    const additionalChargeTagCategoryProducts = ref([]);
    const miscellaneousProducts = ref([]);
    const miscellaneousProductPrices = ref({});
    const additionalChargeItems = ref({});
    const isCompedOrder = ref(false);
    const discount = ref(null);

    const showCoupon = computed(() => {
      return !!discount.value;
    });

    const productsInCart = computed(() => {
      const pic = [];
      additionalChargeTagCategoryProducts.value.forEach(product => {
        const quantity = additionalChargeItems.value[product.id] || 0; // || 0 prevents undefined keys from being cast to NaN

        if (quantity > 0) {
          pic.push({
            ...product,
            quantity: quantity,
          });
        }
      });

      miscellaneousProducts.value.forEach(product => {
        const quantity = additionalChargeItems.value[product.id] || 0; // || 0 prevents undefined keys from being cast to NaN

        if (quantity > 0) {
          pic.push({
            ...product,
            quantity: quantity,
          });
        }
      });

      return pic;
    });

    const reducedProductsInCart = computed(() => {
      return productsInCart.value.map(p => ({
        id: p.id,
        quantity: p.quantity,
        price_info: p.price_info,
      }));
    });

    // additionalChargeItems
    const addAdditionalChargeItem = (id, newCount = 1) => {
      additionalChargeItems.value[id] = newCount;
    };

    const removeAdditionalChargeItem = id => {
      delete additionalChargeItems.value[id];
    };

    const incrementAdditionalChargeItem = id => {
      const oldCount = additionalChargeItems.value[id] || 0;
      additionalChargeItems.value[id] = oldCount + 1;
    };

    const decrementAdditionalChargeItem = id => {
      const oldCount = additionalChargeItems.value[id];
      additionalChargeItems.value[id] = oldCount - 1;
      if (additionalChargeItems.value[id] === 0) {
        delete additionalChargeItems.value[id];
      }
    };

    const setMiscellaneousProductPrices = ({ id, price }) => {
      miscellaneousProductPrices.value[id] = price;
    };

    const discountAmount = computed(() => {
      if (!discount.value) return 0;
      if (discount.value.amount) return discount.value.amount;
      return 0;
    });

    const preSalesTaxTotal = computed(() => {
      if (isCompedOrder.value) {
        return 0;
      } else {
        const val =
          productsInCart.value?.reduce((acc, product) => {
            const rate = product.price_info.rate;
            const customPrice = miscellaneousProductPrices.value[product.id];
            const defaultPrice = product.price_info.default_price;
            return (
              acc +
              product.quantity *
                Number(customPrice || defaultPrice?.unit_amount || rate)
            );
          }, 0) ?? 0;

        if (!showCoupon.value) return val;
        return val - discountAmount.value;
      }
    });

    // use this every time component that calls this store unmounts
    function $reset() {
      additionalChargeTagCategoryProducts.value = [];
      miscellaneousProducts.value = [];
      miscellaneousProductPrices.value = {};
      additionalChargeItems.value = [];
      isCompedOrder.value = false;
      discount.value = null;
    }

    return {
      additionalChargeTagCategoryProducts: readonly(
        additionalChargeTagCategoryProducts
      ),
      setAdditionalChargeTagCategoryProducts: val =>
        (additionalChargeTagCategoryProducts.value = val),
      additionalChargeItems: readonly(additionalChargeItems),
      miscellaneousProducts: readonly(miscellaneousProducts),
      setMiscellaneousProducts: val => (miscellaneousProducts.value = val),
      miscellaneousProductPrices: readonly(miscellaneousProductPrices),
      setMiscellaneousProductPrices,
      removeMiscellaneousProductPrice: id => {
        delete miscellaneousProductPrices.value[id];
      },
      addAdditionalChargeItem,
      decrementAdditionalChargeItem,
      incrementAdditionalChargeItem,
      removeAdditionalChargeItem,
      discount: readonly(discount),
      setDiscount: val => (discount.value = val),
      isCompedOrder: readonly(isCompedOrder),
      setIsCompedOrder: val => (isCompedOrder.value = val),
      preSalesTaxTotal,
      productsInCart,
      reducedProductsInCart,
      showCoupon,
      $reset,
    };
  }
);
