<script setup>
import { computed } from 'vue';
import CaretViewDetails from '@/components/svgs/CaretViewDetails.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { toCurrency } from '@/lib/currency';
const props = defineProps({
  packPrice: {
    type: String,
    required: false,
  },
  packProduct: {
    type: Object,
    required: true,
  },
  photoSegment: {
    type: String,
    require: false,
  },
  proServiceProducts: {
    type: Array,
    required: true,
  },
  hasProServiceChanges: {
    type: Boolean,
  },
});

const hasProServiceChanges = computed(() => props.hasProServiceChanges);

const products = computed(() => {
  let { name, description, rate, thumbnail_url } = props.packProduct;
  let proServices = props.proServiceProducts?.map(
    ({ product_ids, ...ps }) => ps // eslint-disable-line no-unused-vars
  );
  let tempAdjustedRate = proServices.reduce(
    (total, ps) => total - Number(ps.rate),
    Number(props.packPrice?.replace('$', ''))
  );
  const packProduct = {
    name: name,
    description: description,
    rate: hasProServiceChanges.value ? tempAdjustedRate : rate,
    level: 'pack_product',
    thumbnail_url: thumbnail_url,
  };
  return [...proServices, ...[packProduct]];
});

const packTotal = computed(() => {
  return products.value.reduce(
    (total, product) => total + Number(product.rate),
    0
  );
});

const photoSegment = computed(() => props.photoSegment);

const shootLength = computed(() => {
  switch (photoSegment.value) {
    case '1 - 5':
      return 'up to 1 hour';
    case '6 - 10':
      return 'up to 2 hours';
    case '11 - 15':
      return 'up to 3 hours';
    case '16 - 25':
      return 'up to 4 hours';
    default:
      return null;
  }
});
</script>

<template>
  <div class="pack-sidecart__inner">
    <header class="pack-sidecart__header">
      <h2 class="pack-sidecart__header-title">Shoot Summary</h2>
      <strong class="pack-sidecart__shoot-title">
        {{ packProduct.name }}
      </strong>
      <ul class="pack-sidecart__container">
        <li class="pack-sidecart__detail">
          <SoonaIcon name="monitor" />
          <span class="pack-sidecart__detail--shoot-type">virtual shoot</span>
          <SoonaIcon name="circle-question" />
        </li>
        <li
          v-if="shootLength"
          class="pack-sidecart__detail pack-sidecart__detail--length"
        >
          <em>=length of shoot:</em>
          <strong>{{ shootLength }}</strong>
          <SoonaIcon name="circle-question" />
        </li>
      </ul>
    </header>
    <div class="pack-pricing">
      <h2 class="pack-pricing__title">Pricing Info</h2>
      <ul name="item-list" class="pack-pricing__itemized">
        <li
          v-for="product in products"
          :key="`${product.name}-${product.level ?? product.type}`"
          class="pack-pricing__item"
        >
          <img
            v-if="product.thumbnail_image"
            :src="product.thumbnail_image"
            class="pack-pricing__item-img"
            alt=""
          />
          <img
            v-else
            :src="product.thumbnail_url"
            class="pack-pricing__item-img"
            alt=""
          />
          <div class="pack-pricing__item-content">
            <div class="pack-pricing__item-title-price">
              <strong class="pack-pricing__item-title">
                {{ product.name }}
              </strong>
              <em class="pack-pricing__item-price">
                {{ toCurrency(Number(product.rate)) }}
              </em>
            </div>
            <div class="pack-pricing__item-details">
              <div class="pack-pricing__view-details">
                <span>view details</span>
                <CaretViewDetails />
              </div>
              <p v-if="product.level === 'pack_product'">
                1 virtual shoot you can join from your soona dashboard.
              </p>
              <p v-html="product.description" />
              <p v-if="product.level !== 'pack_product'">
                <i>
                  {{ product.pricing_copy }}
                </i>
              </p>
            </div>
          </div>
        </li>
        <p class="pack-pricing__amount-due">
          Amount due today:
          <strong class="u-subheader--heavy">
            {{ toCurrency(packTotal) }}
          </strong>
        </p>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables_fonts';
@use '@/variables';

.pack-sidecart {
  &__inner {
    flex-direction: column;
    background-color: variables.$white-default;
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: variables.$periwink-blue-10;
    padding: 1rem 1rem 0.875rem;
  }

  &__header-title {
    @include variables_fonts.u-small--heavy;

    color: variables.$black-default;
    text-transform: uppercase;
    letter-spacing: 0.09375rem;
    border-bottom: 0.0625rem solid variables.$gray-40;
    padding-bottom: 1rem;
    width: 100%;
  }

  &__shoot-title {
    color: variables.$black-default;
    margin-bottom: 0.875rem;
    padding-top: 0.875rem;
    width: 100%;
  }

  &__container {
    width: 100%;
  }

  &__detail {
    display: flex;
    align-items: flex-start;
    color: variables.$black-default;

    &:not(:last-child) {
      margin-bottom: 0.875rem;
    }

    > svg {
      display: block;
      margin-right: 0.5rem;
      flex: 0 0 1.5rem;

      &:last-of-type {
        color: variables.$gray-60;
      }
    }

    &--shoot-type {
      margin-right: 0.5rem;
    }

    &--length {
      justify-content: flex-end;

      > strong {
        padding: 0 0.5rem;
      }
    }
  }
}
.pack-pricing {
  margin: 1rem 0;
  padding: 1rem 1rem 0.875rem;

  &__title {
    @include variables_fonts.u-small--heavy;

    color: variables.$black-default;
    text-transform: uppercase;
    letter-spacing: 0.09375rem;
    margin-bottom: 1rem;
  }

  &__itemized {
    margin-top: 0.25rem;
  }

  &__item {
    display: flex;
    align-items: flex-start;
    padding: 1rem 0;
    border-top: 0.0625rem solid variables.$gray-30;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__item-img {
    display: block;
    flex: 0 0 3.875rem;
    max-width: 3.875rem;
    border-radius: 0.3125rem;
    margin-right: 0.75rem;
  }

  &__item-content {
    flex-grow: 1;
    padding: 0;
  }

  &__item-title-price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;
    gap: 0.5rem;
  }

  &__item-title {
    display: flex;
    color: variables.$black-default;

    > .tooltip {
      align-self: center;
      padding-left: 0;
      margin-left: 0.5rem;
    }
  }

  &__item-price {
    display: flex;
    align-items: center;
    color: variables.$black-default;
    font-style: normal;
    text-align: right;
    gap: 0.25rem;
  }

  &__view-details {
    @include variables_fonts.u-body--regular;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: variables.$gray-60;
    margin-bottom: 0.5rem;

    > svg {
      display: block;
      margin-left: 0.25rem;
    }
  }

  &__item-details {
    color: variables.$black-default;

    p + p {
      margin-top: 0.5rem;
    }
  }

  &__amount-due {
    @include variables_fonts.u-button--large-caps;
    align-items: flex-end;
    border-top: 0.0625rem solid #d3d6d9;
    color: variables.$black-default;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    strong {
      color: variables.$black-default;
    }
  }
}
</style>
