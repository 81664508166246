<script setup>
import { computed } from 'vue';

import { toCurrency } from '@/lib/currency';
import { useCapability } from '@/composables/useCapability';
import { getContentCategoryText } from '@/lib/content-category';

import OverlayOrganizeMenu from '@/components/account_gallery/OverlayOrganizeMenu.vue';
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';
import DigitalAssetStatusBadge from '@/components/infinite_asset_gallery/DigitalAssetStatusBadge.vue';

const props = defineProps({
  reservationDigitalAsset: {
    type: Object,
    required: true,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  isRetainerClient: {
    type: Boolean,
    default: false,
  },
  selectDisabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
  selectVisible: {
    type: Boolean,
    default: false,
  },
  isBeingViewed: {
    type: Boolean,
    default: false,
  },
  showCommonActions: {
    type: Boolean,
    default: true,
  },
});

defineEmits(['request-page', 'selection-click', 'asset-click']);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const isRetainerClient = computed(() => props.isRetainerClient);
const displaySceneNumber = computed(
  () => isSoonaStaff.value && props.reservationDigitalAsset.scene_number
);

const bonusContentText = computed(() => {
  const contentCategory = props.reservationDigitalAsset?.content_category;
  // default is standard, early return
  if (contentCategory === 'default') {
    return null;
  }
  // unknown categories will be null and no rendered
  const categoryText = getContentCategoryText(contentCategory);

  if (!categoryText) {
    return null;
  }

  const rate = props.reservationDigitalAsset?.add_on_product?.rate;
  if (rate) {
    if (isRetainerClient.value && contentCategory === 'behind_the_scenes') {
      return `${categoryText}`;
    }
    return `${categoryText} · ${toCurrency(rate, 'USD', 0)}`;
  }
  return categoryText;
});
const isColorCard = computed(
  () => isSoonaStaff.value && props.reservationDigitalAsset?.is_color_card
);
</script>

<template>
  <BaseInfiniteGalleryCard
    title-hidden
    class="live-digital-asset-card"
    :class="{ 'live-digital-asset-card--being-viewed': isBeingViewed }"
    :selected="selected"
    :flex-grow="flexGrow"
    :select-visible="selectVisible"
    :select-disabled="selectDisabled"
    :paged-asset="reservationDigitalAsset"
    :show-common-actions="showCommonActions"
    :digital-asset="reservationDigitalAsset.digital_asset"
    @asset-click="$emit('asset-click', $event)"
    @request-page="$emit('request-page', $event)"
    @selection-click="$emit('selection-click', $event)"
  >
    <template #asset-overlay>
      <span
        v-if="displaySceneNumber"
        class="live-digital-asset-card__scene-number u-small--heavy"
      >
        <span class="u-visually-hidden">scene number</span>
        {{ reservationDigitalAsset.scene_number }}
      </span>
    </template>

    <template
      v-if="reservationDigitalAsset?.digital_asset?.ownership === 'customer'"
      #action-buttons
    >
      <OverlayOrganizeMenu :asset="reservationDigitalAsset.digital_asset" />
    </template>

    <template #statuses>
      <DigitalAssetStatusBadge
        v-if="bonusContentText"
        class="live-digital-asset-card__bts-status"
      >
        {{ bonusContentText }}
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-if="isColorCard">
        color card
      </DigitalAssetStatusBadge>
    </template>
  </BaseInfiniteGalleryCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-digital-asset-card {
  cursor: pointer;

  @media (max-width: variables.$screen-md-max) {
    &:hover {
      outline: none;
      box-shadow: none;
    }

    :deep(.asset__link) {
      overflow: visible;

      img {
        margin: 0 auto;
        width: fit-content;
        object-fit: contain;
        border-radius: 0.625rem;
        box-shadow: variables.$elevation-0;
      }
    }
  }

  &--being-viewed,
  &--being-viewed:hover {
    outline-offset: 2px;
    outline: 2px solid variables.$black-default;
  }

  &__scene-number {
    left: 0;
    top: 2.5rem;
    gap: 0.625rem;
    position: absolute;
    padding: 0.1875rem 0.25rem;
    color: variables.$white-default;
    border-radius: 0 0.3125rem 0.3125rem 0;
    background-color: variables.$black-translucent-60;
  }

  &__bts-status {
    color: variables.$periwink-blue-80;
    background-color: variables.$periwink-blue-20;
  }
}
</style>
