<script setup>
import { computed, ref, watchEffect } from 'vue';
import { useFlag } from '@/composables/useFlag';
import { useActiveSubscription } from '@/composables/subscriptions/useActiveSubscription';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import { useBulkGetAssetAddOns } from '@/queries/bag/useBulkGetAssetAddOns';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { usePriorityError } from '@/composables/usePriorityError';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useBulkApplyAssetAddOns } from '@/queries/bag/useBulkApplyAssetAddOns';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';

const props = defineProps({
  accountId: {
    required: true,
    type: Number,
  },
  assetIds: {
    required: true,
    type: Array,
  },
  open: {
    required: true,
    type: Boolean,
  },
});

const emit = defineEmits(['close', 'addOnsSaved']);

const phoenixStudioRentalFeeFlag = useFlag('phoenix_studio_rental_fee');
const toasterCartBulkEditNotesFlag = useFlag('toaster_cart_bulk_edit_notes');

const accountId = computed(() => props.accountId);
const assetIds = computed(() => props.assetIds);
const open = computed(() => props.open);
const isSaving = ref(false);

const editingNotes = ref({});

const {
  data: addOnData,
  isLoading: bulkAssetsLoading,
  error: loadSharedGalleryError,
} = useBulkGetAssetAddOns(accountId, assetIds);

const { hasTierTwoSubscription, hasTierThreeSubscription } =
  useActiveSubscription(accountId);

const { addToast } = useSoonaToast();

const bulkApplyAddOns = useBulkApplyAssetAddOns(accountId);

const priorityError = usePriorityError(loadSharedGalleryError);

const isLoading = computed(() => {
  return bulkAssetsLoading.value;
});

const lineItems = computed(() => addOnData.value?.data.line_items || []);

const lineItemsCreditedByMembership = computed(() => {
  if (phoenixStudioRentalFeeFlag.value) {
    return (
      lineItems.value.some(li => li.credited_by_preferred) ||
      hasTierTwoSubscription.value ||
      hasTierThreeSubscription.value
    );
  } else {
    return lineItems.value.some(li => li.credited_by_preferred);
  }
});

const mediaAddOns = computed(
  () => lineItems.value[0]?.media_add_on_line_items || []
);

watchEffect(() => {
  if (mediaAddOns.value.length) {
    mediaAddOns.value.forEach(addOn => {
      editingNotes.value[addOn.product.name] = '';
    });
  }
});

const toCurrency = (value, currency = 'USD') => {
  return value?.toLocaleString('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  });
};

const photosWithAddOns = addOnProductName => {
  return lineItems.value?.filter(lineItem =>
    lineItem.media_add_on_line_items?.some(
      addOn => addOn.product.name === addOnProductName && addOn.selected
    )
  );
};

const add = ref({});
const remove = ref({});

const photoAddOnUsage = computed(() => {
  return mediaAddOns.value.reduce((addOnUsage, addOn) => {
    const addOnCount = photosWithAddOns(addOn.product.name).length;

    addOnUsage[addOn.product.name] = {
      all:
        addOnCount === assetIds.value?.length ||
        add.value?.[addOn.product.name],
      none: addOnCount === 0,
      some: addOnCount > 0 && addOnCount < assetIds.value?.length,
    };
    return addOnUsage;
  }, {});
});

const addProduct = addOnProductName => {
  delete remove.value[addOnProductName];

  for (const photo of addOnData.value.data.line_items) {
    const matchingAddOn = photo.media_add_on_line_items.find(
      addOn => addOn.product.name === addOnProductName
    );
    if (!matchingAddOn.selected) {
      addOnProductName in add.value || (add.value[addOnProductName] = []);
      add.value[addOnProductName].push({
        price_id: matchingAddOn.product.default_price?.id,
        product_id: matchingAddOn.product.id,
        reservation_id: photo.reservation_id,
        quantity: 1,
        digital_asset_id: photo.digital_asset_id,
      });
    }
  }
};

const removeProduct = addOnProductName => {
  delete add.value[addOnProductName];
  editingNotes.value[addOnProductName] = '';

  for (const photo of addOnData.value.data.line_items) {
    const matchingAddOn = photo.media_add_on_line_items.find(
      addOn => addOn.product.name === addOnProductName
    );

    if (matchingAddOn.selected) {
      addOnProductName in remove.value || (remove.value[addOnProductName] = []);
      remove.value[addOnProductName].push({
        line_item_id: matchingAddOn.id,
      });
    }
  }
};

const addNotesToAddOnData = async () => {
  for (const addOn of mediaAddOns.value) {
    const addOnId = addOn.product.id;
    const addOnName = addOn.product.name;
    const addOnPriceId = addOn.product.default_price?.id;

    // check that the note has a value
    if (editingNotes.value[addOnName]) {
      const note = `${addOnName}: ${editingNotes.value[addOnName]}`;

      // get the data for each photo
      for (const photo of addOnData.value.data.line_items) {
        const digitalAssetId = photo.digital_asset_id;
        const reservationId = photo.reservation_id;

        if (add.value[addOnName]) {
          // check if photo id exists in add-on array
          const addOnIndex = add.value[addOnName].findIndex(
            addOn => addOn.digital_asset_id === digitalAssetId
          );

          // if the photo id is present in the array, update it
          if (addOnIndex >= 0) {
            add.value[addOnName][addOnIndex].note = note;
          } else {
            // if the photo id is not present in the array, add it
            add.value[addOnName].push({
              digital_asset_id: digitalAssetId,
              note: note,
              price_id: addOnPriceId,
              product_id: addOnId,
              quantity: 1,
              reservation_id: reservationId,
            });
          }
        } else {
          delete remove.value[addOnName];

          // if add-on is not in the array, add it
          add.value[addOnName] = [
            {
              digital_asset_id: digitalAssetId,
              note: note,
              price_id: addOnPriceId,
              product_id: addOnId,
              quantity: 1,
              reservation_id: reservationId,
            },
          ];
        }
      }
    }
  }
};

const handleSave = async () => {
  isSaving.value = true;

  if (toasterCartBulkEditNotesFlag.value) await addNotesToAddOnData();

  bulkApplyAddOns.mutate(
    {
      addOnData: {
        add: add.value,
        remove: remove.value,
      },
    },
    {
      onSuccess: () => {
        isSaving.value = false;
        addToast('edits applied successfully!', {
          variation: 'success',
        });
        emit('addOnsSaved');
      },
      onError: () => {
        isSaving.value = false;
        addToast(`error applying edits`, {
          variation: 'error',
        });
      },
    }
  );
};

const handleCheckboxClick = (e, addOnProductName) => {
  const isChecked = e.target.checked;
  if (isChecked) {
    addProduct(addOnProductName);
  } else {
    removeProduct(addOnProductName);
  }
};

const headingCopy = computed(() => {
  const numberOfPhotos = assetIds.value?.length;
  return `apply premium edits to (${numberOfPhotos}) photo${
    numberOfPhotos > 1 ? 's' : ''
  }`;
});

const applyCopy = computed(() => {
  const numberOfPhotos = assetIds.value?.length;
  return `apply to (${numberOfPhotos}) photo${numberOfPhotos > 1 ? 's' : ''}`;
});

const noteLabel = computed(() => {
  if (assetIds.value?.length > 1) return 'editing notes to apply to each asset';

  return 'editing notes to apply to this asset';
});
</script>

<template>
  <SoonaError v-if="priorityError">
    {{ priorityError }}
  </SoonaError>
  <SoonaDialog v-if="open && !isLoading" @close="() => emit('close')">
    <Teleport v-if="isSaving" to="body">
      <SoonaLoading v-if="isSaving" :is-loading="true" loading-text="saving" />
    </Teleport>
    <template #heading>{{ headingCopy }}</template>
    <div
      v-for="addOn in mediaAddOns"
      :key="addOn.id"
      class="bulk-choose-edits-modal__input-container"
    >
      <label class="u-body--regular bulk-choose-edits-modal__label">
        <input
          class="bulk-choose-edits-modal__input"
          type="checkbox"
          :checked="photoAddOnUsage[addOn.product.name].all"
          :indeterminate="photoAddOnUsage[addOn.product.name].some"
          @change="e => handleCheckboxClick(e, addOn.product.name)"
        />
        <template v-if="lineItemsCreditedByMembership">
          {{ addOn.product.name }}
        </template>
        <template v-else>
          {{ addOn.product.name }} {{ toCurrency(addOn.product.rate, 'USD') }}
        </template>
      </label>
      <p class="u-label--regular bulk-choose-edits-modal__helper">
        {{ addOn.product.description }}
      </p>
      <SoonaTextfield
        v-if="
          toasterCartBulkEditNotesFlag &&
          photoAddOnUsage[addOn.product.name].all &&
          !remove[addOn.product.name]
        "
        v-model="editingNotes[addOn.product.name]"
        :label="noteLabel"
        placeholder="add editing notes"
      />
    </div>
    <template #footer="{ close }">
      <small class="u-small--regular bulk-choose-edits-modal__disclaimer">
        *some edit requests are out of soona’s scope. custom requests beyond
        standard edits and premium edits are subject to additional pricing.
      </small>
      <SoonaButton variation="tertiary" @click="close">cancel</SoonaButton>
      <SoonaButton size="medium" type="submit" @click="handleSave">
        {{ applyCopy }}
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
@use '@/variables';

.bulk-choose-edits-modal {
  &__pricing {
    margin: 1.5rem 0 1.25rem;
  }

  &__flag {
    margin: 0 auto;
  }

  &__title {
    word-break: break-word;
  }

  &__input-container:not(:last-child) {
    margin-bottom: 0.75rem;
  }

  &__input {
    cursor: pointer;
    accent-color: variables.$periwink-blue-60;
    margin-right: 0.3125rem;
  }

  &__helper {
    color: variables.$gray-60;
  }

  &__disclaimer {
    flex-grow: 1;
    flex-basis: 100%;
    margin: -0.75rem 0 0.75rem;
    color: variables.$gray-60;
  }
}

:deep(.soona-textfield) {
  padding-bottom: 0;
  padding-top: 0.5rem;
}
</style>
