<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useDigitalAssetCommonActions } from '@/composables/digital_assets/useDigitalAssetCommonActions';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const liveShootStore = useLiveShootStore();
const { assetAccountId, assetId, selectedRDA, isNotesVisible } =
  storeToRefs(liveShootStore);
const digitalAsset = computed(() => selectedRDA.value?.digital_asset);

const { isIndependentRawAsset, visibilityAll, assetLoading } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const {
  isOwnedBySoona,

  canBagAsset,
  isAddedToBag,
  toggleAddToBag,
  isAddToBagLoading,
  isAddToBagDisabled,

  isFavorited,
  toggleFavorite,
  canFavoriteAsset,
  isFavoriteLoading,
  isFavoriteDisabled,

  isStaffPicked,
  toggleStaffPick,
  isStaffPicksLoading,
  isStaffPicksDisabled,
  canManageCustomerCollections,
} = useDigitalAssetCommonActions(digitalAsset);
</script>

<template>
  <span
    v-if="!visibilityAll && !assetLoading"
    class="live-mobile-da-common--actions__hidden u-label--heavy"
  >
    <SoonaIcon name="broken-link" size="medium" />hidden
  </span>

  <div
    v-if="visibilityAll && !isIndependentRawAsset"
    class="live-mobile-da-common--actions__actions"
  >
    <slot />

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          size="large"
          variation="icon-gray-outline"
          :aria-describedby="ariaDescribedby"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
          @click="liveShootStore.setIsNotesVisible(!isNotesVisible)"
        >
          <SoonaIcon
            name="memo-pencil"
            :class="{ 'button-icon--active': isNotesVisible }"
          />
          <span class="u-visually-hidden">
            {{ isNotesVisible ? 'hide notes' : 'show notes' }}
          </span>
        </SoonaButton>
      </template>

      <template #tooltip-content> toggle notes </template>
    </SoonaTooltip>

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          v-if="canManageCustomerCollections && isOwnedBySoona"
          :ref="setRef"
          size="large"
          variation="icon-plain-gray"
          :loading="isStaffPicksLoading"
          :disabled="isStaffPicksDisabled"
          :aria-describedby="ariaDescribedby"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
          @click="toggleStaffPick"
        >
          <SoonaIcon
            :name="isStaffPicked ? 'trophy-solid' : 'trophy'"
            :class="{ 'button-icon--active': isStaffPicked }"
          />
          <span class="u-visually-hidden">expert pick</span>
        </SoonaButton>
      </template>

      <template #tooltip-content>
        {{ isStaffPicked ? 'remove from expert picks' : 'add to expert picks' }}
      </template>
    </SoonaTooltip>

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          v-if="canFavoriteAsset"
          :ref="setRef"
          size="large"
          variation="icon-gray-outline"
          :loading="isFavoriteLoading"
          :disabled="isFavoriteDisabled"
          :aria-describedby="ariaDescribedby"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
          @click="toggleFavorite"
        >
          <SoonaIcon
            :name="isFavorited ? 'heart-solid' : 'heart'"
            :class="{ 'button-icon--active': isFavorited }"
          />
          <span class="u-visually-hidden">favorite</span>
        </SoonaButton>
      </template>

      <template #tooltip-content>
        {{ isFavorited ? 'remove from favorites' : 'add to favorites' }}
      </template>
    </SoonaTooltip>

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          v-if="isOwnedBySoona && canBagAsset"
          :ref="setRef"
          size="large"
          variation="icon-primary"
          class="live-mobile-da-common--actions__add-to-bag"
          :loading="isAddToBagLoading"
          :disabled="isAddToBagDisabled"
          :aria-describedby="ariaDescribedby"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
          @click="toggleAddToBag"
        >
          <SoonaIcon
            :name="isAddedToBag ? 'soona-bag-2-solid-check' : 'soona-bag'"
          />
          <span class="u-visually-hidden">bag</span>
        </SoonaButton>
      </template>

      <template #tooltip-content>
        {{ isAddedToBag ? 'remove from bag' : 'add to bag' }}
      </template>
    </SoonaTooltip>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.button-icon--active {
  color: variables.$friendly-red-50;
}

.live-mobile-da-common--actions {
  &__actions {
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__hidden {
    align-self: flex-end;
    display: flex;
    padding: 0.125rem 0.25rem;
    justify-content: flex-end;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.3125rem;
    background-color: variables.$black-translucent-60;
    color: variables.$white-default;
  }
}
</style>
