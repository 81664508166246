<script setup>
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { ref } from 'vue';

defineProps({
  iconName: {
    type: String,
    required: true,
  },
  visuallyHiddenText: {
    type: String,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  pressed: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['click']);

function handleClick(e) {
  if (e.currentTarget.getAttribute('aria-disabled') === 'true') {
    return;
  }
  emit('click', e);
}
const buttonRef = ref(null);

defineExpose({
  contains: (...args) => {
    return buttonRef.value?.contains(...args);
  },
  focus: (...args) => {
    return buttonRef.value?.focus(...args);
  },
});
</script>

<template>
  <SoonaTooltip placement="top">
    <template
      #default="{
        setRef,
        mouseenter,
        focus,
        mouseleave,
        blur,
        ariaDescribedby,
      }"
    >
      <button
        :ref="
          el => {
            buttonRef = el;
            setRef(el);
          }
        "
        class="overlay-button"
        :class="{ 'overlay-button--loading': loading }"
        :aria-disabled="loading || disabled"
        :aria-pressed="pressed"
        :aria-describedby="ariaDescribedby"
        v-bind="$attrs"
        @mouseenter="mouseenter"
        @focus="focus"
        @mouseleave="mouseleave"
        @blur="blur"
        @click="handleClick"
      >
        <SoonaIcon :name="iconName" />
        <span class="u-visually-hidden">{{ visuallyHiddenText }}</span>
      </button>
    </template>
    <template #tooltip-content>
      <slot name="tooltip-content" />
    </template>
  </SoonaTooltip>
</template>

<style lang="scss" scoped>
@use '@/variables';

@keyframes overlay-button-toggle-pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.9);
  }
}

@keyframes overlay-button-toggle-background-pulse {
  from {
    background: variables.$black-translucent-60;
  }
  to {
    background-color: variables.$black-translucent-83;
  }
}

.overlay-button {
  pointer-events: auto;
  display: inline-flex;
  height: fit-content;
  align-items: center;
  cursor: pointer;
  border: none;
  border-radius: 10rem;
  color: variables.$white-default;
  transition: color 0.3s ease-out;
  outline-width: 0.125rem;
  background: variables.$black-translucent-35;
  outline-color: variables.$periwink-blue-60;
  padding: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    transition: transform 0.1s ease-in-out;
  }

  &[aria-pressed='true'],
  &[aria-expanded='true'] {
    &:not([aria-disabled='true']):active {
      background: variables.$black-translucent-60;
    }
  }

  &[aria-expanded='true'] {
    color: variables.$friendly-red-30;
    background: variables.$black-translucent-60;
  }

  &--loading {
    cursor: wait;
    color: variables.$friendly-red-30;

    & {
      animation: overlay-button-toggle-background-pulse 0.6s ease-in-out
        infinite alternate;
    }

    @media (prefers-reduced-motion: no-preference) {
      svg {
        animation: overlay-button-toggle-pulse 0.6s ease-in-out infinite
          alternate;
      }
    }
  }

  &:not(&--loading)[aria-disabled='true'] {
    pointer-events: none;
    cursor: not-allowed;
    background: none;
    stroke: variables.$white-default;
    stroke-width: 0.0625rem;
    color: variables.$black-translucent-83;
  }

  &:not([aria-disabled='true']):hover {
    background-color: variables.$black-translucent-83;
  }

  &:not([aria-disabled='true']):active {
    color: variables.$friendly-red-30;
    background: variables.$black-translucent-60;

    @media (prefers-reduced-motion: no-preference) {
      svg {
        transform: scale(1.1);
      }
    }
  }
}
</style>
