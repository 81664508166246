<script setup>
import { computed, ref } from 'vue';
import ContractOrderHistoryTable from '@/components/user/anytime/contracts/ContractOrderHistoryTable.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useRetainerTransactions } from '@/queries/retainer_transactions/useRetainerTransactions';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});

const accountId = computed(() => props.accountId);

const currentPage = ref(1);
const itemsPerPage = ref(10);

const { data } = useRetainerTransactions(accountId, {
  currentPage,
  itemsPerPage,
});

const retainerTransactions = computed(() => data.value?.data ?? []);
const retainerTransactionsPagination = computed(
  () => data.value?.pagination ?? {}
);

const isEmpty = computed(() => retainerTransactions.value?.length < 1);

// payments_todo: make this do something
const handleDownload = () => {
  console.log('download');
};
</script>

<template>
  <article class="contract-order-history">
    <div class="contract-order-history__header">
      <h2 class="u-subheader--heavy">Order history</h2>
      <SoonaButton
        v-if="!isEmpty && false"
        size="medium"
        variation="secondary-gray"
        @on-click="handleDownload"
      >
        Download all
        <SoonaIcon name="arrow-down-to-bracket" />
      </SoonaButton>
    </div>
    <ContractOrderHistoryTable
      v-if="!isEmpty"
      v-model:current-page="currentPage"
      v-model:items-per-page="itemsPerPage"
      :account-id="accountId"
      :transactions="retainerTransactions"
      :total-count="retainerTransactionsPagination.totalCount"
      :total-pages="retainerTransactionsPagination.totalPages"
    />
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.contract-order-history {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
}
</style>
