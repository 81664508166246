<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import ClientInfo from '@/components/user/anytime/retainers/transactions/crew/ClientInfo.vue';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
  disableSubmit: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const accountId = computed(() => props.accountId);

const handleBackClick = () =>
  router.push(`/account/${accountId.value}/contracts`);
</script>

<template>
  <div class="create-retainer-transaction">
    <SoonaBackButton button-text="Back" @click="handleBackClick" />
    <div class="create-retainer-transaction__header">
      <h1 class="u-headline--heavy">
        <slot name="header">Create transaction</slot>
      </h1>
      <p>
        <slot name="subheader" />
      </p>
    </div>
    <div class="create-retainer-transaction__form-container">
      <slot name="form" />
      <ClientInfo :account-id="accountId" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-retainer-transaction {
  container: crew-transaction-layout / inline-size;
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 1rem;
  }

  &__form-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.375rem;
    width: 100%;

    & > *:first-child {
      flex: 1 1 auto;
    }
  }
}

@container crew-transaction-layout (min-width: 48rem) {
  .create-retainer-transaction__form-container {
    flex-direction: row;
  }
}
</style>
