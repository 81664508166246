<script setup>
import { computed } from 'vue';

const props = defineProps({
  allSelected: {
    required: true,
    type: Boolean,
  },
  someSelected: {
    required: true,
    type: Boolean,
  },
});

defineEmits(['toggle-selection']);

const isIndeterminate = computed(() => {
  if (props.allSelected) return false;

  return props.someSelected;
});
</script>

<template>
  <label class="u-label--regular select-all">
    <input
      type="checkbox"
      :checked="allSelected"
      :indeterminate="isIndeterminate"
      @click="$emit('toggle-selection')"
    />
    select all photos
  </label>
</template>

<style scoped lang="scss">
@use '@/variables';

.select-all {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}
</style>
