import { defineStore } from 'pinia';
import { useMediaQuery } from '@vueuse/core';
import { ref, readonly, computed, unref } from 'vue';

import { LIVE_SHOOT_STORE_ID } from '@/stores/keys';
import { useReservation } from '@/composables/useReservation';

export const useLiveShootStore = defineStore(LIVE_SHOOT_STORE_ID, () => {
  const selectedRDA = ref(null);
  const selectedAssetVariation = ref(undefined);
  const assetId = computed(() => selectedRDA.value?.digital_asset.id);
  const assetAccountId = computed(
    () => selectedRDA.value?.digital_asset.account_id
  );

  const reservationId = ref(null);
  const { isLoading: isReservationLoading, nameTruncated } =
    useReservation(reservationId);

  const isNotesVisible = ref(false);
  const centerViewOpen = ref(false);
  const galleryHasMinimized = ref(false);

  const isDesktop = useMediaQuery('(min-width: 75rem)');
  const isTablet = useMediaQuery('(min-width: 40rem)');
  const isMobile = computed(() => !isTablet.value);

  const activeMobileView = ref('gallery');

  function selectRDA(rda) {
    selectedRDA.value = rda;
    isNotesVisible.value = false;
  }

  function $reset() {
    selectedRDA.value = null;
    reservationId.value = null;
    centerViewOpen.value = false;
    isNotesVisible.value = false;
    galleryHasMinimized.value = false;
    selectedAssetVariation.value = undefined;
  }

  return {
    $reset,

    isTablet,
    isMobile,
    isDesktop,

    activeMobileView: readonly(activeMobileView),
    setActiveMobileView: view => (activeMobileView.value = view),

    assetId: assetId,
    assetAccountId: assetAccountId,
    reservationId: readonly(reservationId),
    setReservationId: id => (reservationId.value = id),
    reservationNameTruncated: readonly(nameTruncated),
    isReservationLoading: readonly(isReservationLoading),

    isNotesVisible: readonly(isNotesVisible),
    setIsNotesVisible: visible => (isNotesVisible.value = visible),

    selectedRDA: readonly(selectedRDA),
    setSelectedRDA: rda => selectRDA(rda),
    selectedAssetVariation: readonly(selectedAssetVariation),
    setSelectedAssetVariation: variation =>
      (selectedAssetVariation.value = variation),

    centerViewOpen: readonly(centerViewOpen),
    setCenterViewOpen: open => (centerViewOpen.value = open),
    galleryHasMinimized: readonly(galleryHasMinimized),
    setGalleryHasMinimized: minimized =>
      (galleryHasMinimized.value = minimized),
  };
});

export const createLiveShootStore = ({ reservationId }) => {
  const store = useLiveShootStore();
  store.setReservationId(unref(reservationId));
  return store;
};
