<script setup>
import { computed, ref } from 'vue';
import { toCurrency } from '@/lib/currency';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useReservationAdditionalOrderStore } from '@/components/user/anytime/billing_and_orders/store/useReservationAdditionalOrderStore';

const props = defineProps({
  product: {
    type: Object,
    required: true,
    default: undefined,
  },
});

const emit = defineEmits(['select', 'de-select']);
const reservationAdditionalOrderStore = useReservationAdditionalOrderStore();
const selected = ref(false);
const customPrice = ref(undefined);
const product = computed(() => props.product);

const hasDynamicPrice = computed(() => {
  return product.value?.price_info?.default_price === undefined;
});

const updateSelection = () => {
  if (selected.value) {
    emit('select');
    if (!hasDynamicPrice.value) {
      reservationAdditionalOrderStore.setMiscellaneousProductPrices({
        id: product.value.id,
        price: product.value.price_info.default_price.unit_amount,
      });
    }
  } else {
    emit('de-select');
    customPrice.value = undefined;
    reservationAdditionalOrderStore.removeMiscellaneousProductPrice(
      product.value.id
    );
  }
};

const updateCustomPrice = () => {
  reservationAdditionalOrderStore.setMiscellaneousProductPrices({
    id: product.value.id,
    price: customPrice.value,
  });
};
</script>
<template>
  <div class="miscellaneous-service-card">
    <input
      :id="`miscellaneous-service-card-${product.id}`"
      v-model="selected"
      type="checkbox"
      class="miscellaneous-service-card__checkbox"
      name="test"
      @change="updateSelection"
    />
    <div class="miscellaneous-service-card__info">
      <h3 class="u-label--heavy">{{ product.name }}</h3>
      <p class="miscellaneous-service-card__subtitle">
        <template v-if="hasDynamicPrice">
          {{ product.description }}
        </template>
        <template v-else>
          {{ toCurrency(product.price_info.default_price.unit_amount) }}
        </template>
      </p>
      <SoonaTextfield
        v-if="hasDynamicPrice && selected"
        v-model="customPrice"
        class="miscellaneous-service-card__custom-textfield"
        label="charge amount"
        placeholder="Enter a custom price"
        type="number"
        :required="true"
        :rules="['required']"
        @blur="updateCustomPrice"
      >
        <template #icon-left>
          <SoonaIcon size="medium" name="dollar-sign" />
        </template>
      </SoonaTextfield>
    </div>
  </div>
</template>
<style scoped lang="scss">
@use '@/variables';
@use '@/variables_fonts';

.miscellaneous-service-card {
  border: 0.063rem solid variables.$gray-30;
  padding: 1rem;
  border-radius: 0.625rem;
  margin-bottom: 0.75rem;

  display: flex;
  align-items: baseline;
  gap: 1.5rem;

  &__info {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  &__subtitle {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-60;
  }

  &__custom-textfield {
    padding-top: 0.75rem;
    padding-bottom: 0;
  }
}
</style>
