<script setup>
import { computed, ref } from 'vue';
import { useContract } from '@/composables/contracts/useContract';
import { useUpdateContract } from '@/queries/contracts/useUpdateContract';
import dayjs from 'dayjs';
import ContractSummaryField from '@/components/user/anytime/contracts/ContractSummaryField.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import UploadDialog from '@/components/user/anytime/contracts/create/UploadDialog.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  contractId: {
    required: true,
    type: [Number, String],
  },
  isSoonaStaff: {
    default: false,
    type: Boolean,
  },
});
const accountId = computed(() => props.accountId);
const contractId = computed(() => props.contractId);

const {
  contract: activeContract,
  contractTotalDisplay,
  accountCoordinator,
  accountManager,
  enterpriseContractItem,
  hasAccountManager,
  discount,
  fastPassContractItem,
  gifUnitRateDisplay,
  photoUnitRateDisplay,
  ugcUnitRateDisplay,
  videoUnitRateDisplay,
} = useContract({ accountId, contractId });

const { mutate: updateContract, isPending } = useUpdateContract(
  accountId,
  contractId
);

const accountManagerOrCoordinator = computed(() => {
  return hasAccountManager.value
    ? accountManager.value
    : accountCoordinator.value;
});

const accountManagerCopy = computed(() => {
  if (props.isSoonaStaff) return 'Managed by';
  if (hasAccountManager.value) return 'Your account manager';

  return 'Your account coordinator';
});

const contractDuration = computed(() => {
  if (!activeContract.value) return '--';

  const formattedStartDate = dayjs(activeContract.value?.start_date).format(
    'MM/DD/YYYY'
  );

  const formattedEndDate = dayjs(activeContract.value?.end_date).format(
    'MM/DD/YYYY'
  );

  return `${formattedStartDate} - ${formattedEndDate}`;
});

const showContractsSection = computed(() => {
  return props.isSoonaStaff || activeContract.value?.uploadedFiles?.length;
});

const showUploadDialog = ref(false);

const allUploadedFiles = computed(() => {
  return activeContract.value?.contract_files ?? [];
});

const handleUpdateContractData = updatedContractData => {
  updateContract({
    contract_files: updatedContractData,
  });
};

const removeUploadedFile = fileId => {
  updateContract({
    remove_contract_file: fileId,
  });
};
</script>

<template>
  <article class="contract-summary">
    <div
      v-if="accountManagerOrCoordinator?.id"
      class="contract-summary__account-manager"
    >
      <SoonaAvatar
        :name="accountManagerOrCoordinator?.name"
        size="2.5rem"
        type="account"
        :url="accountManagerOrCoordinator?.avatar_url"
      />
      <div>
        <p class="u-badge--small">{{ accountManagerCopy }}</p>
        <p class="u-body--heavy">{{ accountManagerOrCoordinator?.name }}</p>
      </div>
    </div>
    <div class="contract-summary__summary-content">
      <p class="u-subheader--heavy">Contract ID: {{ activeContract?.id }}</p>
      <p class="u-label--heavy contract-summary__subtext">
        Duration: {{ contractDuration }}
      </p>
      <div class="contract-summary__summary">
        <div class="contract-summary__summary-row">
          <ContractSummaryField
            label="Total contract value"
            :value="contractTotalDisplay ?? '--'"
          />
          <ContractSummaryField
            label="Platform membership"
            :value="!!enterpriseContractItem ? 'Included' : 'Not included'"
          />
          <ContractSummaryField
            label="Fast Pass"
            :value="!!fastPassContractItem ? 'Included' : 'Not included'"
          />
          <ContractSummaryField
            label="Pro service discount rate"
            :value="`${discount?.percent_off ?? 0}%`"
          />
        </div>
        <div class="contract-summary__summary-row">
          <ContractSummaryField
            label="Photo unit rate"
            :value="!!photoUnitRateDisplay ? photoUnitRateDisplay : '--'"
          />
          <ContractSummaryField
            label="Video clip unit rate"
            :value="!!videoUnitRateDisplay ? videoUnitRateDisplay : '--'"
          />
          <ContractSummaryField
            label="GIF unit rate"
            :value="!!gifUnitRateDisplay ? gifUnitRateDisplay : '--'"
          />
          <ContractSummaryField
            label="UGC unit rate"
            :value="!!ugcUnitRateDisplay ? ugcUnitRateDisplay : '--'"
          />
        </div>
        <div v-if="showContractsSection">
          <p class="u-label--heavy contract-summary__files-label">Contract</p>
          <SoonaButton
            v-if="isSoonaStaff"
            class="contract-summary__add-file-button"
            variation="secondary-gray"
            size="medium"
            @on-click="() => (showUploadDialog = true)"
          >
            <SoonaIcon name="plus-large" />
            Add files
          </SoonaButton>
          <div
            v-if="allUploadedFiles?.length"
            class="contract-summary__completed-uploads"
          >
            <div
              v-for="file in allUploadedFiles"
              :key="file.id"
              class="contract-summary__uploaded"
            >
              <SoonaLoading
                v-if="isPending"
                is-loading
                loading-text="Processing"
              />
              <div v-else class="contract-summary__details">
                <SoonaIcon name="file" />
                <a
                  class="u-body--heavy contract-summary__filename"
                  :href="file.url"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ file.filename }}
                </a>
                <p class="u-body--regular contract-summary__size">
                  {{ Math.floor(+file.byte_size / 1000) }}KB
                </p>
                <SoonaTooltip v-if="isSoonaStaff">
                  <template
                    #default="{ setRef, mouseenter, focus, mouseleave, blur }"
                  >
                    <SoonaButton
                      :ref="el => setRef(el)"
                      variation="icon-plain-gray"
                      size="small"
                      @on-click="removeUploadedFile(file.id)"
                      @mouseenter="mouseenter"
                      @focus="focus"
                      @mouseleave="mouseleave"
                      @blur="blur"
                    >
                      <SoonaIcon name="xmark" />
                    </SoonaButton>
                  </template>
                  <template #tooltip-content>Remove this file</template>
                </SoonaTooltip>
              </div>
            </div>
          </div>
          <UploadDialog
            v-if="showUploadDialog"
            :uploaded-files="allUploadedFiles ?? []"
            @close-dialog="showUploadDialog = false"
            @update="handleUpdateContractData"
            @remove="removeUploadedFile"
          />
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@use '@/variables';

.contract-summary {
  border: 0.0625rem solid variables.$gray-30;
  border-radius: 0.625rem;
  container: contract-summary / inline-size;
  margin: 1.5rem 0;
  overflow: hidden;

  &__account-manager {
    align-items: center;
    background-color: variables.$periwink-blue-20;
    display: flex;
    gap: 0.75rem;
    padding: 1rem 1.5rem;
  }

  &__summary-content {
    padding: 1.5rem;
  }

  &__subtext {
    color: variables.$gray-60;
    padding-bottom: 2rem;
  }

  &__summary {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__summary-row {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__files-label {
    color: variables.$gray-60;
    padding-bottom: 0.75rem;
  }

  &__completed-uploads {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  &__uploaded {
    align-items: center;
    border: 0.0625rem solid variables.$gray-30;
    border-radius: 0.3125rem;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    max-width: 19.75rem;
    padding: 0.75rem;

    svg {
      flex-shrink: 0;
    }
  }

  &__details {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    width: 100%;
  }

  &__size {
    color: variables.$gray-60;
  }

  &__filename {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}

@container contract-summary (min-width: 43rem) {
  .contract-summary__summary-row {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
