<script setup>
import { defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import {
  ADJUST_ACTION,
  BACKGROUND_COLOR_ACTION,
  BLUR_BACKGROUND_ACTION,
} from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import { useMediaEditor } from '@/components/user/anytime/gallery/media-editor/useMediaEditor';
import ActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBar.vue';
import MediaEditorLoading from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaEditorLoading.vue';
import MediaEditorError from '@/components/user/anytime/gallery/media-editor/media-multiplier/MediaEditorError.vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import LeftActionBarButtons from '@/components/user/anytime/gallery/media-editor/action-bars/LeftActionBarButtons.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import ActionBarSecondaryButton from '@/components/user/anytime/gallery/media-editor/action-bars/ActionBarSecondaryButton.vue';
import RemoveBackground from '@/components/user/anytime/gallery/media-editor/media-multiplier/RemoveBackground.vue';
import ImageResizer from '@/components/user/anytime/gallery/media-editor/media-multiplier/resizer/ImageResizer.vue';
import AIExpand from '@/components/user/anytime/gallery/media-editor/media-multiplier/uncrop/AIExpand.vue';
import PremiumEditsDigitalAsset from '@/components/user/anytime/gallery/media-editor/PremiumEditsDigitalAsset.vue';
import backgroundColor from 'images/media-editor/background-color.png';
import blurBackground from 'images/media-editor/blur-background.png';

const AsyncShiftBackgroundColor = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/ShiftBackgroundColor.vue'
    ),
  loadingComponent: MediaEditorLoading,
  delay: 100,
  errorComponent: MediaEditorError,
});

const AsyncBlurBackground = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/BlurBackground.vue'
    ),
  loadingComponent: MediaEditorLoading,
  delay: 100,
  errorComponent: MediaEditorError,
});

const AsyncAddShadows = defineAsyncComponent({
  loader: async () =>
    await import(
      '@/components/user/anytime/gallery/media-editor/media-multiplier/AddShadows.vue'
    ),
  loadingComponent: MediaEditorLoading,
  delay: 100,
  errorComponent: MediaEditorError,
});

const mediaEditorStore = useMediaEditorStore();
const { iframeUrl, assetId, assetAccountId, canAdjustBackgroundOnOwnAccount } =
  storeToRefs(mediaEditorStore);

const { pendingEditorTasks, maskUrl, isPhoto, assetLoading } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const {
  leftFlyoutHeading,
  leftFlyoutSubheading,
  isAdjustOpen,
  isAIExpandActive,
  isBlurBackgroundActive,
  isImageResizerActive,
  isLeftFlyoutOpen,
  isPremiumEditsActive,
  isRemoveBackgroundActive,
  isShiftBackgroundColorActive,
  isShadowsActive,
  showBackButton,
} = useMediaEditor();

const { linkClicked } = useBaseEvents();

const route = useRoute();

const shareFeedback = () => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: 'share feedback',
    linkHref: null,
  });
  mediaEditorStore.setShowFeedbackDialog(true);
};

const handleLinkClicked = actionText => {
  linkClicked({
    context: route.meta.context,
    subContext: 'media editor menu',
    linkLabel: actionText,
    linkHref: null,
  });
};
const handleButtonClick = (actionText, actionSymbol) => {
  handleLinkClicked(actionText);
  mediaEditorStore.setActiveAction(actionSymbol);
};

const handleBackClick = () => {
  // when we add more subnavs have `MediaEditorProvider` in place, we'll want each component
  // to provide their back action symbol when the components register themselves with the provider.
  mediaEditorStore.setActiveAction(ADJUST_ACTION);
};

watch(assetId, () => {
  if (!assetId.value || assetLoading.value) return;
  if (isAdjustOpen.value && !isPhoto.value) {
    mediaEditorStore.forceSetActiveAction(null);
  }
});
</script>

<template>
  <ActionBar
    class="left-action-bar"
    :is-flyout-open="isLeftFlyoutOpen && !iframeUrl"
    position="left"
  >
    <LeftActionBarButtons />
    <template v-if="leftFlyoutHeading" #flyout-heading>
      <template v-if="showBackButton">
        <SoonaButton
          class="action-bar-left__back"
          variation="icon-gray-outline"
          size="medium"
          @on-click="handleBackClick"
        >
          <SoonaIcon name="arrow-left" size="medium" />
        </SoonaButton>
      </template>
      {{ leftFlyoutHeading }}
    </template>
    <template v-if="leftFlyoutSubheading" #flyout-subheading>
      {{ leftFlyoutSubheading }}
    </template>
    <template #flyout-content>
      <template v-if="isAdjustOpen">
        <ActionBarSecondaryButton
          column="left"
          :thumb-image="backgroundColor"
          title="background color"
          description="adjust the background color of your image"
          :enabled="!!maskUrl && !pendingEditorTasks"
          @on-click="
            () => handleButtonClick('background color', BACKGROUND_COLOR_ACTION)
          "
        />
        <hr />
        <ActionBarSecondaryButton
          :thumb-image="blurBackground"
          title="blur background"
          description="add blur to your background and make your product pop!"
          :enabled="!!maskUrl && !pendingEditorTasks"
          @on-click="
            () => handleButtonClick('blur background', BLUR_BACKGROUND_ACTION)
          "
        />
      </template>
      <PremiumEditsDigitalAsset v-else-if="isPremiumEditsActive" />
      <AsyncShiftBackgroundColor v-else-if="isShiftBackgroundColorActive" />
      <AsyncBlurBackground v-else-if="isBlurBackgroundActive" />
      <RemoveBackground v-else-if="isRemoveBackgroundActive" />
      <ImageResizer v-else-if="isImageResizerActive" />
      <AsyncAddShadows v-else-if="isShadowsActive" ref="addShadowsComponent" />
      <AIExpand v-else-if="isAIExpandActive" ref="aiExpandComponent" />
    </template>
    <div v-if="canAdjustBackgroundOnOwnAccount" class="me-feedback-action">
      <SoonaIcon
        class="me-feedback-action__icon"
        name="heart-duotone"
        size="small"
      />
      <p class="u-label--small me-feedback-action__text">
        help us improve our media editor!
      </p>
      <button
        type="button"
        class="u-button-reset u-label--small me-feedback-action__button"
        @click="shareFeedback"
      >
        share feedback
      </button>
    </div>
  </ActionBar>
</template>
<style lang="scss" scoped>
@use 'src/variables';

.action-bar-left {
  &__back {
    margin-right: 0.75rem;
    margin-top: 0.25rem;
  }

  &__beta {
    margin-top: 0.25rem;
    padding: 0.0625rem 0.25rem;
    background: variables.$periwink-blue-20;
    color: variables.$periwink-blue-80;
    border-radius: 3.125rem;
  }
}

.me-feedback-action {
  margin-top: auto;
  padding: 0.5rem;

  &__icon {
    color: variables.$periwink-blue-40;
  }

  &__text {
    color: variables.$gray-60;
  }

  &__button {
    text-decoration: underline;

    &:hover,
    &:focus-visible {
      text-decoration: none;
    }
  }
}
</style>
