<script setup>
import { computed, watch } from 'vue';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBulkHideDigitalAssets } from '@/queries/digital_assets/useBulkHideDigitalAssets';
import SoonaDropdownMenu from '@/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from '@/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';

const props = defineProps({
  accountId: {
    type: Number,
    required: true,
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
  isChatOpen: {
    type: Boolean,
    default: false,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
  reservationId: {
    type: [String, Number],
    default: null,
  },
});

const emit = defineEmits(['close']);

const accountId = computed(() => props.accountId);

const selectedAssets = computed(() => props.selectedAssets);
const numberOfAssetsSelected = computed(() => selectedAssets.value.length);

// hide files module
const {
  mutate: hideDigitalAssets,
  isPending,
  error: hideDigitalAssetsError,
  reset: resetHideDigitalAssets,
} = useBulkHideDigitalAssets(accountId);

const bulkHideFiles = () => {
  const ids = selectedAssets.value.map(ecda => ecda.digital_asset.id);
  hideDigitalAssets(ids, {
    onSuccess: () => {
      emit('close');
    },
  });
};

watch(numberOfAssetsSelected, newSelectedCount => {
  if (newSelectedCount === 0) {
    resetHideDigitalAssets();
  }
});

const hasError = computed(() => {
  return [hideDigitalAssetsError.value].some(err => !!err);
});

const invalidAssetsSelected = computed(() => {
  // hiding re-edits will break re-edit requests
  return selectedAssets.value.some(ecda => ecda.edit_status !== 'accepted');
});
</script>

<template>
  <SoonaActionBar
    class="digital-asset-edits-multi-select-action-bar"
    :display="numberOfAssetsSelected > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #close-button>
      <SoonaTooltip placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="setRef"
            :disabled="isPending"
            variation="icon-transparent"
            :aria-describedby="ariaDescribedby"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @on-click="$emit('close')"
          >
            <SoonaIcon name="xmark" />
            <span class="u-visually-hidden">cancel</span>
          </SoonaButton>
        </template>

        <template #tooltip-content>cancel</template>
      </SoonaTooltip>
    </template>

    <template #content>
      <SoonaError
        v-if="hasError"
        no-margin
        class="digital-asset-multi-select-action-bar__error"
      >
        something went wrong. please try again.
      </SoonaError>
      <p class="u-label--heavy">
        {{ numberOfAssetsSelected }}
        asset{{ numberOfAssetsSelected > 1 ? 's' : '' }}
        selected
      </p>
    </template>
    <template #actions>
      <SoonaDropdownMenu
        size="large"
        variation="icon-transparent"
        :display-caret="false"
      >
        <template #trigger-content>
          <SoonaIcon name="dots-vertical" />
          <span class="u-visually-hidden">organize</span>
        </template>
        <template #default="{ keydown, mouseover, clickCapture }">
          <SoonaDropdownMenuItem>
            <button
              role="menuitem"
              class="organize-menu__item"
              :disabled="invalidAssetsSelected"
              @click="bulkHideFiles"
              @keydown="keydown"
              @mouseover="mouseover"
              @click.capture="clickCapture"
            >
              <SoonaIcon name="eye-slash" />
              hide
            </button>
          </SoonaDropdownMenuItem>
        </template>
      </SoonaDropdownMenu>
    </template>
  </SoonaActionBar>
</template>

<style lang="scss" scoped>
.digital-asset-edits-multi-select-action-bar {
  &__error {
    margin-bottom: 1rem;
  }
}
</style>
