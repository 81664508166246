<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRouter } from 'vue-router';

import { useMe } from '@/composables/user/useMe';
import { useBagCount } from '@/queries/bag/useBag';
import { useLiveShootStore } from './useLiveShootStore';
import { useGetAvailableDownPaymentCreditInfo } from '@/queries/reservations/useGetAvailableDownPaymentCreditInfo';

import SoonaFlag from '@/components/ui_library/SoonaFlag.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

import { PeriwinkBlue20, PeriwinkBlue80 } from 'src/variables.module.scss';

const router = useRouter();

const liveShootStore = useLiveShootStore();
const { reservationId, reservationNameTruncated, isMobile } =
  storeToRefs(liveShootStore);

// bag
const { currentAccountId } = useMe();
const { data: bagCount } = useBagCount(currentAccountId, {
  enabled: computed(() => !!currentAccountId.value),
});
const itemsInBag = computed(() => bagCount.value ?? null);
////

// credits
const { data: creditInfo } =
  useGetAvailableDownPaymentCreditInfo(reservationId);

const hasDownPaymentCredits = computed(() => {
  return (
    creditInfo.value?.photo_count > 0 ||
    creditInfo.value?.video_count > 0 ||
    creditInfo.value?.gif_count > 0 ||
    creditInfo.value?.bts_count > 0
  );
});

const pluralOrSingle = (count, singular, plural) => {
  return count === 1 ? singular : plural;
};

const bookingCreditsText = computed(() => {
  let credit_texts = [];

  for (const [key, value] of Object.entries(creditInfo.value)) {
    if (value > 0) {
      const creditType = key.replace('_count', '');
      credit_texts.push(
        `${value} ${pluralOrSingle(value, creditType, `${creditType}s`)}`
      );
    }
  }

  return credit_texts.join(' | ');
});
////
</script>

<template>
  <header class="live-shoot-header">
    <!-- todo: improve the routing logic here, for if they use the link directly with no history -->
    <SoonaButton
      variation="icon-plain-gray"
      size="medium"
      @click="() => router.go(-1)"
    >
      <SoonaIcon name="arrow-left" />
      <span class="u-visually-hidden">back</span>
    </SoonaButton>

    <div class="live-shoot-header__title">
      <h2
        :class="{ 'u-subheader--heavy': !isMobile, 'u-body--heavy': isMobile }"
      >
        {{ reservationNameTruncated }}
        <SoonaFlag
          v-if="!isMobile"
          title="live"
          type="pill"
          padding-size="0rem 0.25rem"
          :text-color="PeriwinkBlue80"
          :background-color="PeriwinkBlue20"
        />
      </h2>

      <div v-if="hasDownPaymentCredits" class="live-shoot-header__credits">
        <SoonaIcon v-if="!isMobile" name="badge-dollar" size="small" />
        <p class="u-small--regular">
          {{ isMobile ? 'credits:' : 'booking credits:' }}
          <span>{{ bookingCreditsText }}</span>
        </p>
      </div>
    </div>

    <SoonaButton element="a" size="medium" href="/user/anytime#/bag/">
      <SoonaIcon name="soona-bag" />
      checkout
      <span v-if="itemsInBag && itemsInBag > 0">
        ({{ itemsInBag }})
        <span class="u-visually-hidden">&nbsp;items in bag</span>
      </span>
    </SoonaButton>
  </header>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-shoot-header {
  z-index: 1;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  box-shadow: variables.$elevation-0;
  background: variables.$white-default;

  @media (min-width: 40rem) {
    padding: 0rem;
    box-shadow: none;
    background: none;
  }

  &__title {
    gap: 0;
    flex: 1;
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h2 {
      gap: 0.5rem;
      display: flex;
      align-items: center;
    }

    @media (min-width: 40rem) {
      gap: 0.5rem;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__credits {
    gap: 0.25rem;
    display: flex;
    align-items: center;
    color: variables.$gray-70;
  }
}
</style>
