<script setup>
import { computed, toRefs } from 'vue';

import { useMe } from '@/composables/user/useMe';
import { useCapability } from '@/composables/useCapability';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useUpdateBagCollection } from '@/queries/bag_collection/useUpdateBagCollection';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import useActionBarMediaQuery from '@/components/user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['close']);
const { selectedAssets, accountId } = toRefs(props);

const { currentUserId } = useMe();
const { actionBarIsMobile } = useActionBarMediaQuery();
const { hasCapability: hasBagCapability } = useCapability({
  capability: 'add_to_bag',
  subjectType: 'account',
  subjectId: accountId,
});

const { data: bagCollection } = useBagCollection(accountId);
const bagCollectionId = computed(() => bagCollection.value?.id);

const {
  mutate: updateBagCollectionAssets,
  isPending: isLoadingUpdateBagCollectionAssets,
} = useUpdateBagCollection(accountId, bagCollectionId);

const baggableAssetsSelected = computed(() => {
  return selectedAssets.value?.some(
    da => da.ownership === 'soona' && !da?.bag_collection_digital_asset?.id
  );
});

const addToBag = () => {
  const body = selectedAssets.value.reduce((acc, asset) => {
    if (
      asset.ownership !== 'customer' &&
      !asset.bag_collection_digital_asset?.id
    ) {
      return [
        ...acc,
        {
          added_by_user_id: currentUserId.value,
          digital_asset_id: asset.id,
        },
      ];
    }

    return acc;
  }, []);

  updateBagCollectionAssets(
    {
      bag_collection_digital_assets_attributes: body,
    },
    {
      onSuccess: () => {
        emit('close');
      },
    }
  );
};
</script>

<template>
  <SoonaButton
    :loading="isLoadingUpdateBagCollectionAssets"
    :variation="actionBarIsMobile ? 'icon-primary' : 'primary'"
    :disabled="
      !hasBagCapability ||
      isLoadingUpdateBagCollectionAssets ||
      !baggableAssetsSelected
    "
    @on-click="addToBag"
  >
    <SoonaIcon name="soona-bag" />
    <span :class="{ 'u-visually-hidden': actionBarIsMobile }">
      add to bag
    </span>
  </SoonaButton>
</template>
