<script setup>
import { storeToRefs } from 'pinia';

import { toCurrency } from '@/lib/currency';
import { useLiveShootStore } from './useLiveShootStore';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const liveShootStore = useLiveShootStore();
const { assetId, assetAccountId } = storeToRefs(liveShootStore);

const {
  asset,
  isBTS,
  mediaType,
  rdaSuccess,
  addOnProduct,
  ownedByCustomer,
  isIndependentRawAsset,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);
</script>

<template>
  <p
    v-if="
      rdaSuccess && addOnProduct && !ownedByCustomer && !isIndependentRawAsset
    "
    class="live-asset-price u-badge--small"
  >
    <strong>
      {{ toCurrency(+addOnProduct?.rate, 'USD', 0) }}
    </strong>
    or 1
    <template v-if="isBTS">BTS</template>
    <template v-else-if="asset">{{ mediaType }}</template>
    credit
  </p>
</template>

<style lang="scss" scoped>
.live-asset-price {
  gap: 0.25rem;
  display: flex;
  align-items: center;

  :deep(.tooltip__button) {
    svg {
      height: 0.75rem;
      width: 0.75rem;
    }
  }
}
</style>
