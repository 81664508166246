<script setup>
import { computed, watch, ref, provide, toRefs, onUnmounted } from 'vue';
import {
  onBeforeRouteLeave,
  onBeforeRouteUpdate,
  useRoute,
  useRouter,
} from 'vue-router';
import {
  onKeyStroke,
  useMediaQuery,
  useTimeoutFn,
  useTitle,
} from '@vueuse/core';
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from 'src/composables/useCapability';
import { useDialogSoonaSelect } from '@/composables/useDialogSoonaSelect';
import BottomActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/BottomActionBar.vue';
import LeftActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/LeftActionBar.vue';
import MediaAsset from 'src/components/user/anytime/gallery/media-editor/MediaAsset.vue';
import MediaChat from 'src/components/user/anytime/gallery/media-editor/MediaChat.vue';
import TopActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/TopActionBar.vue';
import RightActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/RightActionBar.vue';
import SoonaAlert from '@/components/ui_library/SoonaAlert.vue';
import UnsavedChangesWarning from '@/components/user/anytime/gallery/media-editor/media-multiplier/UnsavedChangesWarning.vue';
import { TELEPORT_TO } from '@/composables/useTeleportTo';
import ShareFeedbackDialog from '@/components/user/anytime/gallery/media-editor/ShareFeedbackDialog.vue';
import RotateDigitalAsset from '@/components/user/anytime/gallery/media-editor/RotateDigitalAsset.vue';
import { ROUTE_MAP } from '@/components/user/anytime/gallery/media-editor/mediaEditorActionKeys';
import SubscriptionsDialog from 'src/components/modal-payment-flows/subscriptions/SubscriptionsDialog.vue';
import { useDialogContext } from '@/composables/useDialog';
import uniqueId from 'lodash/uniqueId';
import StaffActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/StaffActionBar.vue';
import TempGifActionBar from '@/components/user/anytime/gallery/media-editor/action-bars/staff-actions-bar/TempGifActionBar.vue';
import AIEditorIframe from '@/components/user/anytime/gallery/media-editor/media-multiplier/ai/AIEditorIframe.vue';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useDigitalAssetAnnotateProvider } from '@/components/notes/digital-asset-notes/useDigitalAssetAnnotate';

const props = defineProps({
  asset: {
    type: Object,
    default: undefined,
  },
  isAssetLoading: {
    type: Boolean,
    default: false,
  },
  reservationId: {
    type: [String, Number],
    default: undefined,
  },
});

const emits = defineEmits(['close', 'approve-image']);

const popoverRef = ref();
const id = uniqueId('media-editor-');

const { checkAllowEscapeClose } = useDialogContext({ id });

provide(TELEPORT_TO, popoverRef);

const route = useRoute();
const router = useRouter();
const mediaEditorStore = useMediaEditorStore();
const {
  activeAction,
  canvasData,
  showWarning,
  showPaywallDialog,
  iframeUrl,
  isIframeOpen,
  showForeground,
  showFeedbackDialog,
  selectorData,
} = storeToRefs(mediaEditorStore);

const urlTool = computed(() => route.query.tool);

const { hasCapability: isFtSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { asset, isAssetLoading } = toRefs(props);

const assetId = computed(() => asset.value?.id);
const assetAccountId = computed(() => asset.value?.account_id);

const {
  rdaId,
  isPhoto,
  isGif,
  isIndependentRawAsset,
  ownedBySoona,
  mediaUrl,
  truncatedTitle,
  imageVariations,
} = useMediaEditorDigitalAsset(assetAccountId, assetId);

const { start: beginClose, isPending: isClosing } = useTimeoutFn(
  e => {
    emits('close', e);
  },
  150,
  { immediate: false }
);

function closeEditor(e) {
  mediaEditorStore.forceSetActiveAction(null);
  if (!isClosing.value && !e?.delivered_asset_id) {
    beginClose(e);
  } else {
    emits('close', e);
  }
}
mediaEditorStore.setCloseAction(closeEditor);

const selectedVariation = ref(undefined);
const selectVariation = variation => {
  selectedVariation.value = variation;
};

const url = computed(() => {
  return (
    imageVariations.value?.find(v => v.value === selectedVariation.value)
      ?.value ?? mediaUrl.value
  );
});

const matchMediaIsWide = useMediaQuery('(min-width: 60rem)');

useFocusTrap(popoverRef, { immediate: true });

const tabTitle = computed(
  () => `${truncatedTitle.value ?? 'AI studio'} | soona`
);
useTitle(tabTitle);

const showPreviewOnlyAlert = computed(() => {
  return (
    ownedBySoona.value &&
    (Object.keys(selectorData.value).length > 0 ||
      canvasData.value ||
      showForeground.value)
  );
});

const isChatOpen = ref(false);

const hideChat = computed(() => {
  return (
    (!!activeAction.value && !matchMediaIsWide.value) || isIframeOpen.value
  );
});

// lifecycle hooks

onKeyStroke('Escape', () => {
  if (checkAllowEscapeClose(popoverRef)) {
    closeEditor();
  }
});

const { pageViewed } = useBaseEvents();

watch(
  assetId,
  () => {
    mediaEditorStore.setAssetId(assetId.value);
    mediaEditorStore.setAssetAccountId(assetAccountId.value);
    pageViewed();
  },
  { immediate: true }
);

useDialogSoonaSelect(popoverRef);

onBeforeRouteLeave(async to => {
  return await mediaEditorStore.handleRouteChange(to);
});

onBeforeRouteUpdate(async to => {
  return await mediaEditorStore.handleRouteChange(to);
});

onUnmounted(() => {
  mediaEditorStore.$reset();
});

watch(
  urlTool,
  () => {
    if (urlTool.value) {
      mediaEditorStore.forceSetActiveAction(ROUTE_MAP[urlTool.value]);
      const queryParams = { ...route.query };
      delete queryParams.tool;
      router.replace({ query: queryParams });
    }
  },
  { immediate: true }
);

useDigitalAssetAnnotateProvider(assetId);
</script>

<template>
  <Teleport to="body">
    <div
      ref="popoverRef"
      class="media-editor"
      :class="{
        'media-editor--closing': isClosing,
      }"
      role="dialog"
      aria-modal="true"
    >
      <TopActionBar v-if="matchMediaIsWide || !iframeUrl">
        {{ truncatedTitle }}
      </TopActionBar>
      <div class="media-editor__editing-panel">
        <LeftActionBar
          v-if="matchMediaIsWide && !isIndependentRawAsset"
          class="media-editor__left-action-bar"
        />
        <div
          class="media-editor__middle-column"
          :class="{
            'media-editor__middle-column--no-overflow': iframeUrl,
            'media-editor__middle-column--chat-open': isChatOpen && !hideChat,
          }"
        >
          <StaffActionBar
            v-if="isFtSoonaStaff && asset && rdaId && !isIframeOpen"
            :selected-variation="url"
            :variations="imageVariations"
            @select-variation="selectVariation"
          />
          <TempGifActionBar v-else-if="isGif && ownedBySoona" />
          <SoonaAlert v-if="showPreviewOnlyAlert" class="media-editor__alert">
            <span class="media-editor__alert-content">
              <b>edits are preview-only</b> on unpurchased photos
            </span>
          </SoonaAlert>
          <slot name="error" />
          <div
            class="media-editor__img-wrapper"
            :class="{ 'media-editor__img-wrapper--dense': iframeUrl }"
          >
            <MediaAsset
              v-if="asset && !isIndependentRawAsset && !iframeUrl"
              :src-url="url"
              :popover-ref="popoverRef"
              :is-file-loading="isAssetLoading"
            />
            <AIEditorIframe
              v-else-if="asset && isIframeOpen"
              @approve-image="$emit('approve-image', $event)"
            />
            <RotateDigitalAsset
              v-if="isPhoto && matchMediaIsWide && !iframeUrl"
              class="media-editor__img-rotate"
            />
          </div>
          <MediaChat
            v-if="!!reservationId"
            v-model:is-chat-open="isChatOpen"
            :reservation-id="reservationId"
            :hide-chat="hideChat"
          />
        </div>
        <RightActionBar v-if="matchMediaIsWide && !isIframeOpen" />
      </div>
      <BottomActionBar v-if="!matchMediaIsWide && !isIndependentRawAsset" />
      <slot v-if="!isIframeOpen" name="carousel" />
    </div>
    <ShareFeedbackDialog v-if="showFeedbackDialog" />
    <UnsavedChangesWarning v-if="showWarning" />
    <SubscriptionsDialog
      v-if="showPaywallDialog"
      class="media-editor__paywall-dialog"
      selected-tier-slug="tier-one"
      @close="() => mediaEditorStore.setShowPaywallDialog(false)"
    />
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.media-editor {
  background-color: variables.$gray-10;
  bottom: 0;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  animation:
    0.25s ease-out both scale-up,
    0.15s ease-out both k-fade-in;

  @media (prefers-reduced-motion: reduce) {
    animation: 0.15s ease-out both k-fade-in;
  }

  &--closing {
    animation:
      0.15s ease-out both scale-down,
      0.15s ease-out both k-fade-out;

    @media (prefers-reduced-motion: reduce) {
      animation: 0.15s ease-out both k-fade-out;
    }
  }

  &__editing-panel {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    position: relative;

    @media (min-width: variables.$screen-md-min) {
      flex-direction: row;
    }
  }

  &__middle-column {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
    transition: padding-right 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    border-top: 0.0625rem solid variables.$gray-30;

    &--no-overflow {
      overflow-y: hidden;
    }
  }

  &__alert {
    margin: 1rem 0 0;
    max-width: 90rem;
  }

  &__alert-content {
    @include variables_fonts.u-label--regular;
  }

  &__img-wrapper {
    align-items: center;
    display: grid;
    flex-grow: 1;
    grid-template-columns: 1fr auto;
    gap: 1rem;
    justify-items: center;
    min-height: 0;
    padding: 1rem 0 1rem 1rem;
    width: 100%;

    &--dense {
      gap: 0rem;
      padding: 0;
    }
  }

  &__img-rotate {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }

  :deep(.subscriptions-dialog) {
    z-index: 16;
  }

  :deep(.soona-dialog) {
    z-index: 16;
  }

  @media (min-width: 45.0625rem) {
    &__middle-column--chat-open {
      padding-right: 18.75rem;
    }
  }

  @media (min-width: variables.$screen-md-min) {
    &__middle-column {
      border-bottom: 0.0625rem solid variables.$gray-30;

      &--chat-open {
        // matches clamp values in MediaChat.vue
        padding-right: clamp(14.25rem, 23.2vw, 23.4375rem);
      }
    }

    &__alert-content {
      @include variables_fonts.u-body--regular;
    }
  }
}

@keyframes scale-up {
  from {
    transform: scale(0.95);
  }
}

@keyframes scale-down {
  to {
    transform: scale(0.95);
  }
}
</style>
