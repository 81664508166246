<script setup>
import { computed } from 'vue';

const props = defineProps({
  options: {
    type: Array,
    required: true,
    validator: options => {
      return (
        options.every(
          option =>
            Object.hasOwn(option, 'value') && Object.hasOwn(option, 'label')
        ) || options.every(option => typeof option === 'string')
      );
    },
  },
  variation: {
    type: String,
    default: 'friendly-red',
    validator: variation => {
      return ['friendly-red', 'periwink-blue', 'black'].includes(variation);
    },
  },
  size: {
    type: String,
    default: 'large',
    validator: size => {
      return ['small', 'medium', 'large'].includes(size);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const modelValue = defineModel({
  type: String,
  required: true,
});

// support an array of strings, or an array of objects
const options = computed(() => {
  if (props.options.every(option => typeof option === 'string')) {
    return props.options.map(option => ({ value: option, label: option }));
  }
  return props.options;
});
</script>

<template>
  <div class="soona-filter-single">
    <button
      v-for="option in options"
      :key="option.value"
      class="soona-filter-single__button"
      :class="{
        'u-small--heavy': size === 'small',
        'u-label--heavy': size === 'medium',
        'u-body--heavy': size === 'large',
      }"
      :aria-pressed="modelValue === option.value"
      :aria-disabled="disabled"
      :data-variation="variation"
      :data-size="size"
      data-cypress="soona-filter-single"
      @click="
        () => {
          if (!disabled) {
            modelValue = option.value;
          }
        }
      "
    >
      <slot
        name="option"
        v-bind="option"
        :selected="modelValue === option.value"
      >
        {{ option.label }}
      </slot>
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.soona-filter-single {
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem 0.75rem;
  overflow-x: auto;

  &[data-visually-hidden='true'] {
    visibility: hidden;
    position: absolute;
  }

  &__button {
    display: inline-flex;
    padding: var(--padding);
    justify-content: center;
    align-items: center;
    gap: 0.375rem;
    flex-shrink: 0;
    border-radius: 1.625rem;
    border: 0.0625rem solid variables.$gray-30;
    background: variables.$white-default;
    color: variables.$black-default;

    & :slotted(svg) {
      color: variables.$gray-60;
      height: var(--svg-size);
      width: var(--svg-size);
    }

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-70;
      outline-offset: -0.125rem;
    }

    &:hover {
      background: var(--hover-background);

      & :slotted(svg) {
        color: var(--hover-icon-color);
      }
    }

    &:active,
    &[aria-pressed='true'] {
      color: var(--active-color);
      background: var(--active-background);
      border-color: var(--active-border);

      & :slotted(svg) {
        color: var(--active-icon-color);
      }
    }

    &[aria-disabled='true'] {
      background: variables.$gray-20;
      border-color: variables.$gray-20;
      color: variables.$gray-50;
      cursor: not-allowed;

      & :slotted(svg) {
        color: variables.$gray-50;
      }
    }

    &[data-size='small'] {
      --padding: 0.1875rem 0.75rem;
      --svg-size: 1rem;
    }
    &[data-size='medium'] {
      --padding: 0.375rem 1rem;
      --svg-size: 1rem;
    }
    &[data-size='large'] {
      --padding: 0.4375rem 1rem;
      --svg-size: 1.25rem;
    }

    &[data-variation='friendly-red'] {
      --hover-background: #{variables.$friendly-red-20};
      --hover-icon-color: #{variables.$friendly-red-60};
      --active-background: #{variables.$friendly-red-20};
      --active-icon-color: #{variables.$friendly-red-50};
      --active-border: #{variables.$friendly-red-40};
    }

    &[data-variation='periwink-blue'] {
      --hover-background: #{variables.$periwink-blue-20};
      --hover-icon-color: #{variables.$periwink-blue-60};
      --active-background: #{variables.$periwink-blue-20};
      --active-icon-color: #{variables.$periwink-blue-50};
      --active-border: #{variables.$periwink-blue-40};
    }

    &[data-variation='black'] {
      --hover-background: #{variables.$gray-20};
      --hover-icon-color: #{variables.$black-default};
      --active-background: #{variables.$black-translucent-83};
      --active-icon-color: #{variables.$white-default};
      --active-border: #{variables.$black-translucent-83};
      --active-color: #{variables.$white-default};
    }
  }
}
</style>
