<script setup>
import useActionBarMediaQuery from '../user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

defineProps({
  display: {
    type: Boolean,
    required: true,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const { actionBarIsMobile } = useActionBarMediaQuery();
</script>

<template>
  <Transition>
    <div v-if="display" class="action-bar">
      <div class="action-bar__content">
        <slot v-if="!actionBarIsMobile" name="close-button" />
        <slot name="content" />
      </div>
      <div class="action-bar__actions">
        <slot v-if="actionBarIsMobile" name="close-button" />
        <div class="action-bar__actions-content">
          <slot name="actions" />
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
@use '@/variables';

.action-bar {
  position: fixed;
  bottom: 0;
  left: calc((v-bind(pageBoundingRectLeft) * 1px) - 0.5rem);
  width: calc((v-bind(pageBoundingRectWidth) * 1px) + 1rem);
  z-index: 5;
  background-color: variables.$black-translucent-83;
  backdrop-filter: blur(1rem);
  color: variables.$white-default;
  padding: 1rem 1rem 2rem;
  border-radius: 0.625rem 0.625rem 0 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: variables.$elevation-3;

  &__content {
    gap: 1rem;
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    gap: 1rem;
  }

  &__actions-content {
    gap: 1rem;
    display: flex;
  }

  // desktop
  // screen-sm-min = 768px
  @media (min-width: variables.$screen-sm-min) {
    padding: 1.5rem;
    border-radius: 0.625rem;
    bottom: -1.5rem;
    transform: translateY(-50%);
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    &__actions {
      justify-content: flex-start;
      align-self: flex-end;
      gap: 1rem;
    }
  }
}

@media (prefers-reduced-motion: no-preference) {
  .v-enter-active,
  .v-leave-active {
    transition: transform 0.2s ease-out;
  }

  .v-enter-from,
  .v-leave-to {
    transform: translateY(100%);
  }
}
</style>
