<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import UploadContract from './UploadContract.vue';

defineProps({
  uploadedFiles: {
    required: true,
    type: Array,
  },
});

const emit = defineEmits(['close-dialog', 'update', 'remove']);
</script>

<template>
  <SoonaDialog
    class="upload-dialog"
    max-width="33rem"
    @close="emit('close-dialog')"
  >
    <UploadContract
      :uploaded-files="uploadedFiles"
      @update="e => emit('update', e.uploadedFiles)"
      @remove="e => emit('remove', e.fileToRemove)"
    />
    <template #footer="{ close }">
      <SoonaButton @on-click="close">Done</SoonaButton>
    </template>
  </SoonaDialog>
</template>
