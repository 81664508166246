<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed, ref } from 'vue';
import { useAccount } from '@/queries/account/useAccount';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import BillingInfoForm from './BillingInfoForm.vue';
import { toCityStateZip } from '@/lib/address-formatter';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});
const accountId = computed(() => props.accountId);
const isEditing = ref(false);
const { data: account } = useAccount(accountId);
const billingAddress = computed(() => account.value?.billing_address);

const handleEdit = () => {
  isEditing.value = true;
};

const close = () => {
  isEditing.value = false;
};
</script>

<template>
  <div class="billing-info">
    <SoonaButton
      variation="secondary-gray"
      size="medium"
      class="billing-info__edit-btn"
      @on-click="handleEdit"
    >
      Edit <SoonaIcon name="pen-square" />
    </SoonaButton>
    <SoonaAvatar
      v-if="account"
      size="6rem"
      type="account"
      :name="account?.name"
      :src="account?.avatar"
      :title="account?.name"
    />
    <div class="billing-info__text-container">
      <p class="billing-info__label">Name & billing address</p>
      <p class="u-subheader--heavy">{{ account?.name }}</p>
      <p class="u-body--regular">
        {{ billingAddress?.address_1 }}
      </p>
      <p v-if="billingAddress?.address_2" class="u-body--regular">
        {{ billingAddress?.address_2 }}
      </p>
      <p class="u-body--regular">
        {{ toCityStateZip(billingAddress) }}
      </p>
    </div>
    <div class="billing-info__text-container">
      <p class="billing-info__label">Billing email</p>
      <p class="u-subheader--heavy">{{ account?.billing_email }}</p>
    </div>
    <SoonaDialog v-if="isEditing" @close="close">
      <template #heading>Billing info</template>
      <BillingInfoForm :account-id="accountId" @cancel="close" @save="close" />
    </SoonaDialog>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.billing-info {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__label {
    @include variables_fonts.u-label--regular;
    color: variables.$gray-60;
    margin-bottom: 0.25rem;
  }

  &__location-container {
    width: 100%;
  }

  &__select-location {
    margin-top: 0.5rem;
    background: variables.$white-default;
  }

  &__edit-btn {
    position: absolute;
    top: 16px;
    right: 16px;
  }
}
</style>
