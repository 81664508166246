<script setup>
import { computed, ref } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { useBulkRemoveFromBag } from '@/queries/bag/useBulkRemoveFromBag';
import SoonaActionBar from '@/components/ui_library/SoonaActionBar.vue';
import BulkChooseEdits from '@/components/checkout/BulkChooseEdits.vue';
import SelectAllAssets from '@/components/checkout/SelectAllAssets.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaTooltip from '../ui_library/SoonaTooltip.vue';
import useActionBarMediaQuery from '../user/anytime/reservation/gallery/digital_asset_multiselect/useActionBarMediaQuery';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  allSelected: {
    default: false,
    type: Boolean,
  },
  selectedAssets: {
    type: Array,
    required: true,
  },
  showSelectAll: {
    default: false,
    type: Boolean,
  },
  someSelected: {
    default: false,
    type: Boolean,
  },
  pageBoundingRectLeft: {
    type: Number,
    required: true,
  },
  pageBoundingRectWidth: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits([
  'cancelMultiSelect',
  'addOnsSaved',
  'toggle-selection',
]);

const { actionBarIsMobile } = useActionBarMediaQuery();

const accountId = computed(() => props.accountId);

const { mutate: bulkRemoveFromBag, isPending } =
  useBulkRemoveFromBag(accountId);

const isUpdating = computed(() => isPending.value);

const assetIds = computed(() => props.selectedAssets);

const numberOfProductsSelected = computed(() => props.selectedAssets.length);

const selectedAssetsText = computed(() =>
  numberOfProductsSelected.value === 1
    ? '1 selected'
    : `${numberOfProductsSelected.value} selected`
);

const isModalOpen = ref(false);

const closeModal = () => {
  isModalOpen.value = false;
};

const openModal = () => {
  isModalOpen.value = true;
};

const handleAddOnsSaved = () => {
  isModalOpen.value = false;
  emit('addOnsSaved');
};

const isLoading = ref(false);

const handleBulkRemove = () => {
  isLoading.value = true;
  bulkRemoveFromBag(
    { assetType: 'digital_asset', assetIds: assetIds.value },
    {
      onSuccess: () => {
        isLoading.value = false;
      },
    }
  );
};
</script>
<template>
  <Teleport v-if="isLoading" to="body">
    <SoonaLoading v-if="isLoading" :is-loading="true" loading-text="removing" />
  </Teleport>
  <SoonaActionBar
    :display="selectedAssets.length > 0"
    :page-bounding-rect-left="pageBoundingRectLeft"
    :page-bounding-rect-width="pageBoundingRectWidth"
  >
    <template #close-button>
      <SoonaTooltip placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="setRef"
            variation="icon-transparent"
            :aria-describedby="ariaDescribedby"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @on-click="$emit('cancelMultiSelect')"
          >
            <SoonaIcon name="xmark" />
            <span class="u-visually-hidden">cancel</span>
          </SoonaButton>
        </template>

        <template #tooltip-content>cancel</template>
      </SoonaTooltip>
    </template>

    <template #content>
      <SelectAllAssets
        v-if="showSelectAll"
        :all-selected="allSelected"
        :some-selected="someSelected"
        @toggle-selection="emit('toggle-selection')"
      />
      <p class="u-label--heavy">{{ selectedAssetsText }}</p>
    </template>

    <template #actions>
      <SoonaTooltip placement="top">
        <template
          #default="{
            setRef,
            mouseenter,
            focus,
            mouseleave,
            blur,
            ariaDescribedby,
          }"
        >
          <SoonaButton
            :ref="setRef"
            :disabled="isUpdating"
            variation="icon-transparent"
            :aria-describedby="ariaDescribedby"
            @blur="blur"
            @focus="focus"
            @mouseenter="mouseenter"
            @mouseleave="mouseleave"
            @click.prevent="handleBulkRemove"
          >
            <SoonaIcon name="trash" />
            <span class="u-visually-hidden">remove from bag</span>
          </SoonaButton>
        </template>

        <template #tooltip-content>remove from bag</template>
      </SoonaTooltip>

      <SoonaButton
        :variation="actionBarIsMobile ? 'icon-primary' : 'primary'"
        :disabled="isUpdating"
        @click="openModal"
      >
        <SoonaIcon name="pen-swirl" size="small" />
        <span :class="{ 'u-visually-hidden': actionBarIsMobile }">
          premium edits
        </span>
      </SoonaButton>
    </template>
  </SoonaActionBar>
  <BulkChooseEdits
    v-if="isModalOpen && selectedAssets.length > 0"
    :asset-ids="selectedAssets"
    :account-id="accountId"
    :open="isModalOpen"
    @close="closeModal"
    @add-ons-saved="handleAddOnsSaved()"
  ></BulkChooseEdits>
</template>

<style lang="scss" scoped>
@use '@/variables';

.action-bar {
  @media (max-width: variables.$screen-xs-max) {
    bottom: 3.75rem;

    @media (max-height: variables.$screen-xxs-min) {
      gap: 0.75rem;
      padding-top: 0.75rem;
      padding-bottom: 1.625rem;
    }
  }
}
</style>
