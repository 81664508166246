<script setup>
import { useRouter } from 'vue-router';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const router = useRouter();
</script>

<template>
  <nav class="live-action-bar">
    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          :aria-describedby="ariaDescribedby"
          variation="icon-transparent"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        >
          <SoonaIcon name="video" />
        </SoonaButton>
      </template>
      <template #tooltip-content>turn on camera</template>
    </SoonaTooltip>

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          :aria-describedby="ariaDescribedby"
          variation="icon-transparent"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        >
          <SoonaIcon name="microphone-alt-1" />
        </SoonaButton>
      </template>
      <template #tooltip-content>turn on microphone</template>
    </SoonaTooltip>

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          :aria-describedby="ariaDescribedby"
          variation="icon-transparent"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        >
          <SoonaIcon name="expand" />
        </SoonaButton>
      </template>
      <template #tooltip-content>fullscreen video call</template>
    </SoonaTooltip>

    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          :aria-describedby="ariaDescribedby"
          variation="icon-transparent"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
        >
          <SoonaIcon name="clapperboard" />
        </SoonaButton>
      </template>
      <template #tooltip-content>view shot list</template>
    </SoonaTooltip>

    <!-- todo: update this routing along with LiveShootHeader -->
    <SoonaTooltip placement="top">
      <template
        #default="{
          blur,
          focus,
          setRef,
          mouseenter,
          mouseleave,
          ariaDescribedby,
        }"
      >
        <SoonaButton
          :ref="setRef"
          :aria-describedby="ariaDescribedby"
          variation="icon-primary"
          @blur="blur"
          @focus="focus"
          @mouseenter="mouseenter"
          @mouseleave="mouseleave"
          @click="router.go(-1)"
        >
          <SoonaIcon name="arrow-right-from-bracket" />
          <span class="u-visually-hidden">exit</span>
        </SoonaButton>
      </template>
      <template #tooltip-content>exit live shoot</template>
    </SoonaTooltip>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-action-bar {
  gap: 1rem;
  right: 50%;
  bottom: 1rem;
  display: flex;
  padding: 0.5rem;
  position: absolute;
  border-radius: 2rem;
  transform: translateX(50%);
  backdrop-filter: blur(8px);
  box-shadow: variables.$elevation-1;
  view-transition-name: live-action-bar;
  background: variables.$black-translucent-83;
}
</style>
