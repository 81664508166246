import { http } from '@/config/vue-axios';

export async function publishDigitalAssetsToContentful(
  accountId,
  assetIds,
  resolutions,
  customFileName,
  integrationId
) {
  const response = await http.post('/integrations/contentful/publish', {
    account_id: accountId,
    asset_ids: assetIds,
    resolutions: resolutions,
    custom_file_name: customFileName,
    integration_id: integrationId,
  });

  return response.data;
}
