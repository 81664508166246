import { toValue } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { http } from '@/config/vue-axios';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {number | Ref<number> | string | Ref<string>} [accountId]
 * @param {Object} [params]
 * @param {number | Ref<number> | string | Ref<string>} [params.currentPage]
 * @param {number | Ref<number> | string | Ref<string>} [params.itemsPerPage]
 * @param {UseQueryOptions} [queryOptions]
 *
 * @returns {UseInfiniteQueryReturnType<any, unknown>}
 */
export function useRetainerTransactions(
  accountId,
  { currentPage, itemsPerPage } = {},
  queryOptions
) {
  return useQuery({
    queryKey: queryKeys.accountRetainerTransactions(accountId, {
      currentPage,
      itemsPerPage,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (toValue(currentPage)) params['page'] = toValue(currentPage);
      if (toValue(itemsPerPage)) params['items'] = toValue(itemsPerPage);

      const response = await http.get(
        `/accounts/${toValue(accountId)}/retainer_transactions`,
        {
          params,
          signal,
        }
      );
      return {
        pagination: mapPaginationHeaders(response.headers),
        data: response.data,
      };
    },
    ...queryOptions,
  });
}
