<script setup>
import BaseInfiniteGalleryCard from '@/components/infinite_asset_gallery/BaseInfiniteGalleryCard.vue';
import DigitalAssetStatusBadge from '@/components/infinite_asset_gallery/DigitalAssetStatusBadge.vue';
import OverlayOrganizeMenu from '@/components/account_gallery/OverlayOrganizeMenu.vue';
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import { toCurrency } from '@/lib/currency';
import { getContentCategoryText } from '@/lib/content-category';

const props = defineProps({
  reservationDigitalAsset: {
    type: Object,
    required: true,
  },
  alwaysShowTitle: {
    type: Boolean,
    required: false,
  },
  flexGrow: {
    type: Number,
    default: 0,
  },
  isRetainerClient: {
    type: Boolean,
    default: false,
  },
  to: {
    type: [String, Object],
    default: null,
  },
  selectDisabled: {
    type: Boolean,
    default: false,
  },
  selected: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['request-page', 'selection-click']);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const isRetainerClient = computed(() => props.isRetainerClient);
const displaySceneNumber = computed(
  () => isSoonaStaff.value && props.reservationDigitalAsset.scene_number
);

const bonusContentText = computed(() => {
  const contentCategory = props.reservationDigitalAsset?.content_category;
  // default is standard, early return
  if (contentCategory === 'default') {
    return null;
  }
  // unknown categories will be null and no rendered
  const categoryText = getContentCategoryText(contentCategory);

  if (!categoryText) {
    return null;
  }

  const rate = props.reservationDigitalAsset?.add_on_product?.rate;
  if (rate) {
    if (isRetainerClient.value && contentCategory === 'behind_the_scenes') {
      return `${categoryText}`;
    }
    return `${categoryText} · ${toCurrency(rate, 'USD', 0)}`;
  }
  return categoryText;
});
const isColorCard = computed(
  () => isSoonaStaff.value && props.reservationDigitalAsset?.is_color_card
);
</script>

<template>
  <BaseInfiniteGalleryCard
    :selected="selected"
    select-visible
    :select-disabled="selectDisabled"
    :always-show-title="alwaysShowTitle"
    :digital-asset="reservationDigitalAsset.digital_asset"
    :paged-asset="reservationDigitalAsset"
    :flex-grow="flexGrow"
    :to="to"
    @request-page="$emit('request-page', $event)"
    @selection-click="$emit('selection-click', $event)"
  >
    <template #asset-overlay>
      <span
        v-if="displaySceneNumber"
        class="reservation-digital-asset-card__scene-number u-small--heavy"
      >
        <span class="u-visually-hidden">scene number</span>
        {{ reservationDigitalAsset.scene_number }}
      </span>
    </template>

    <template
      v-if="reservationDigitalAsset?.digital_asset?.ownership === 'customer'"
      #action-buttons
    >
      <OverlayOrganizeMenu :asset="reservationDigitalAsset.digital_asset" />
    </template>

    <template #statuses>
      <DigitalAssetStatusBadge
        v-if="bonusContentText"
        class="reservation-digital-asset-card__bts-status"
      >
        {{ bonusContentText }}
      </DigitalAssetStatusBadge>
      <DigitalAssetStatusBadge v-if="isColorCard">
        color card
      </DigitalAssetStatusBadge>
    </template>
  </BaseInfiniteGalleryCard>
</template>

<style lang="scss" scoped>
@use '@/variables';

.reservation-digital-asset-card {
  &__scene-number {
    position: absolute;
    top: 2.5rem;
    left: 0;
    padding: 0.1875rem 0.25rem;
    gap: 0.625rem;
    border-radius: 0 0.3125rem 0.3125rem 0;
    color: variables.$white-default;
    background-color: variables.$black-translucent-60;
  }

  &__bts-status {
    background-color: variables.$periwink-blue-20;
    color: variables.$periwink-blue-80;
  }
}
</style>
