import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { queryKeys } from '@/queries/query-keys';
import { useMe } from '@/composables/user/useMe';
import { nonAPIHttp } from '@/config/vue-axios';

export function useUpdateCurrentUser() {
  const queryClient = useQueryClient();
  const { invalidateMe, currentUserId } = useMe();

  return useMutation({
    mutationFn: async body => {
      const response = await nonAPIHttp.put(`/users`, {
        user: {
          name: body.name,
          email: body.email,
          job_title: body.jobTitle,
          username: body.email,
          phone: body.phone,
          password: body.password,
          password_confirmation: body.passwordConfirmation,
          current_password: body.currentPassword,
          accept_text_messages: body.acceptTextMessages,
        },
      });

      return response.data;
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.user(currentUserId),
        }),
        invalidateMe(),
      ]);
    },
  });
}
