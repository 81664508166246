<script setup>
import { computed, ref, useId, watchEffect } from 'vue';
import * as Sentry from '@sentry/vue';
import { generateHTML } from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaUploadV2 from '@/components/uploader/SoonaUploadV2.vue';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaEditor from 'src/components/ui_library/soona_editor/SoonaEditor.vue';

const props = defineProps({
  requirementType: {
    type: String,
    required: true,
  },
  initialAccountRequirement: {
    type: Object,
    default: () => ({}),
  },
  priorityErrors: {
    type: Array,
    default: null,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['submit', 'cancel']);

const { addToast } = useSoonaToast();
const uploadErrorToast = () => {
  addToast(`Your file couldn't be loaded`, {
    variation: 'error',
  });
};

const id = useId();
const textareaId = computed(() => `${id}-textarea`);
const content = ref('');
const files = ref([]);

const addUpload = blob => {
  files.value.push({
    blob_id: blob.id,
    filename: blob.filename,
    content_type: blob.content_type,
    _destroy: 0,
  });
};

const contentHtml = ref('');
const renderError = ref(null);

watchEffect(() => {
  if (!content.value) {
    contentHtml.value = '';
    renderError.value = null;
    return;
  }

  try {
    contentHtml.value = generateHTML(content.value, [
      StarterKit,
      Link,
      Underline,
    ]);
    renderError.value = null;
  } catch (error) {
    console.error('could not render a prosemirror doc', error);
    contentHtml.value = '';
    renderError.value = error;

    Sentry.captureException(new Error('could not render a prosemirror doc'), {
      extra: {
        error,
        initialAccountRequirement: props.initialAccountRequirement,
      },
    });
  }
});

watchEffect(() => {
  content.value = props.initialAccountRequirement.content;
  files.value =
    props.initialAccountRequirement.files?.map(f => ({ ...f })) ?? [];
});
</script>

<template>
  <SoonaForm class="requirement-form" @submit="$emit('submit', $event)">
    <div class="requirement-form__actions">
      <SoonaButton
        type="button"
        variation="tertiary"
        size="medium"
        @on-click="$emit('cancel')"
      >
        cancel
      </SoonaButton>
      <SoonaButton
        type="submit"
        variation="primary"
        size="medium"
        :loading="loading"
      >
        save
      </SoonaButton>
    </div>

    <SoonaError
      v-if="priorityErrors"
      :priority-errors="priorityErrors"
      no-margin
    />

    <SoonaError v-if="renderError">
      error rendering rich text<br />{{ renderError.message }}
    </SoonaError>

    <SoonaEditor
      v-model="content"
      class="requirement-form__content"
      min-height="fit-content"
      :toolbar-actions="{
        bold: true,
        headings: true,
        italic: true,
        underline: true,
        link: true,
        lists: true,
        strike: true,
      }"
    >
      <template #label>
        <label :for="textareaId" class="u-visually-hidden">content</label>
      </template>
    </SoonaEditor>
    <input
      :id="textareaId"
      :value="JSON.stringify(content)"
      name="account_requirement[content]"
      type="hidden"
    />
    <input
      :value="contentHtml"
      name="account_requirement[content_html]"
      type="hidden"
    />
    <input
      :name="`account_requirement[requirement_type]`"
      type="hidden"
      :value="requirementType"
      :disabled="initialAccountRequirement.requirement_type"
    />
    <fieldset v-if="files.length > 0" class="requirement-form__files">
      <legend class="u-subheader--heavy requirement-form__files__legend">
        attachments
      </legend>
      <div
        v-for="(file, index) in files"
        :key="`${file.id}-${file.blob_id}`"
        class="requirement-form__file"
        :data-remove="file._destroy"
        role="group"
        :aria-labelledby="`remove-file-blob-${file.id}-${file.blob_id}-filename`"
      >
        <SoonaIcon
          :name="
            file.content_type?.includes('image/') ? 'image-square' : 'file'
          "
        />
        <span
          :id="`remove-file-blob-${file.id}-${file.blob_id}-filename`"
          class="u-body--heavy requirement-form__file__filename"
        >
          {{ file.filename }}
        </span>
        <span
          v-if="file.file_size_display"
          class="u-body--regular"
          aria-label="file size"
        >
          {{ file.file_size_display }}
        </span>
        <span class="requirement-form__file__actions">
          <span v-if="file._destroy" class="u-label--regular">removed</span>
          <SoonaTooltip placement="top">
            <template
              #default="{ setRef, mouseenter, focus, mouseleave, blur }"
            >
              <input
                :id="`remove-file-blob-${file.id}-${file.blob_id}`"
                v-model="file._destroy"
                :name="`account_requirement[files_attachments_attributes][${index}][_destroy]`"
                type="checkbox"
                value="1"
                class="u-visually-hidden"
              />
              <label
                :ref="setRef"
                :for="`remove-file-blob-${file.id}-${file.blob_id}`"
                class="u-small--regular requirement-form__file__remove"
                @blur="blur"
                @focus="focus"
                @mouseenter="mouseenter"
                @mouseleave="mouseleave"
              >
                <SoonaIcon :name="file._destroy ? 'flip-backward' : 'xmark'" />
                <span class="u-visually-hidden">
                  remove {{ file.filename }}
                </span>
              </label>
            </template>
            <template #tooltip-content>{{
              file._destroy ? 'restore' : 'remove'
            }}</template>
          </SoonaTooltip>
        </span>
        <input
          :name="`account_requirement[files_attachments_attributes][${index}][id]`"
          type="hidden"
          :value="file.id"
          :disabled="!file.id"
        />
        <input
          :name="`account_requirement[files_attachments_attributes][${index}][blob_id]`"
          type="hidden"
          :value="file.blob_id"
          :disabled="!file.blob_id"
        />
      </div>
    </fieldset>

    <SoonaUploadV2
      :file-size-limit-mb="50"
      is-multiple
      @upload-complete="addUpload"
      @upload-error="uploadErrorToast"
    >
      <template #default="{ onUploadClick }">
        <SoonaButton
          variation="tertiary"
          class="requirement-form__attach-files"
          @on-click="onUploadClick"
        >
          <SoonaIcon name="paperclip" />
          attach files
        </SoonaButton>
      </template>
    </SoonaUploadV2>
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';

.requirement-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__content {
    font-size: 1rem;
    width: 100%;
    border-radius: 0.625rem;
  }

  :deep(a) {
    color: variables.$periwink-blue-70;
    text-decoration: underline;
    overflow-wrap: anywhere;

    &:hover {
      color: variables.$black-default;
      transition: color 0.1s ease-out;
    }

    &:active {
      color: variables.$periwink-blue-80;
    }
  }

  &__files {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    &__legend {
      margin-bottom: 1rem;
    }
  }

  &__file {
    display: flex;
    flex-flow: row wrap;
    padding: 0.75rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.3125rem;
    border: 0.0625rem solid variables.$gray-30;
    color: variables.$gray-60;

    &[data-remove='true'] {
      background-color: variables.$gray-20;

      .requirement-form__file__filename {
        color: variables.$gray-70;
      }
    }

    svg {
      flex: 0 0 1.5rem;
      height: 1.5rem;
      width: 1.5rem;
    }

    &__filename {
      color: variables.$black-default;
      overflow-wrap: anywhere;
    }

    &__actions {
      flex: 1 1 auto;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;
      gap: 0.5rem;
    }

    &__remove {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: transparent;
      color: variables.$gray-60;
      flex-shrink: 0;
      border-radius: 10rem;
      padding: 0.1875rem;
      /* multiplied to equal 1 */
      font-size: 0.75rem;
      line-height: 1.3333;
      user-select: none;
      transition: background-color 0.1s ease-out;

      svg {
        height: 1rem;
        width: 1rem;
      }

      input:focus-visible + & {
        outline: solid 0.125rem variables.$periwink-blue-60;
      }

      &:hover {
        background-color: variables.$black-translucent-04;
      }

      &:active {
        background-color: variables.$black-translucent-08;
      }
    }
  }

  &__attach-files {
    align-self: flex-start;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    align-items: center;
    flex-flow: row wrap;
  }
}
</style>
