import { useMutation, useQueryClient } from '@tanstack/vue-query';
import { http } from '@/config/vue-axios';
import { unref } from 'vue';
import * as Sentry from '@sentry/vue';
import { queryKeys } from '@/queries/query-keys';

export function useCreateReservationAdditionalChargeOrder(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async body => {
      const params = {
        account_id: body.accountId,
        charge_immediately: body?.chargeImmediately,
        coupon_code: body?.couponCode,
        memo: body?.memo,
        payment_method_id: body.paymentMethodId,
        payment_method_type: body.paymentMethodType,
        prices: body?.prices,
        products: body?.products,
        reservation_id: unref(reservationId),
        save_payment_method: body.savePaymentMethod,
        sales_tax: body.salesTax,
        token: body?.paymentToken,
      };

      if (body.compedOrder) {
        params.comped_order = body.compedOrder;
        params.credit_reason = body.creditReason;
      }

      const response = await http.post('/orders.json', params);

      return response.data;
    },
    onSuccess: async (data, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: queryKeys.accountOrders(variables.accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.orders(variables.accountId),
        }),
        queryClient.invalidateQueries({
          queryKey: queryKeys.reservationOrders(variables.reservationId),
        }),
      ]);
    },
    onError: error => {
      Sentry.captureException('failed to create additional charge order', {
        extra: error,
      });
      throw new Error(error.response.data.message);
    },
  });
}
