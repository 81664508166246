<script setup>
import { computed, inject } from 'vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import {
  MEDIA_POPOVER_BACK_KEY,
  MEDIA_POPOVER_BACK_TEXT,
} from '@/components/user/anytime/gallery/media-editor-routing-keys';
import { useRoute } from 'vue-router';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';

const props = defineProps({
  isWide: {
    type: Boolean,
    default: false,
  },
});

const route = useRoute();
const { linkClicked } = useBaseEvents();

const isWide = computed(() => props.isWide);
const backUrl = inject(MEDIA_POPOVER_BACK_KEY);
const backText = inject(MEDIA_POPOVER_BACK_TEXT);

const trackBackClick = () => {
  if (backUrl.value) {
    linkClicked({
      context: route.meta.context,
      subContext: 'media editor header',
      linklabel: 'back',
      linkHref: backUrl.value,
    });
  }
};
</script>

<template>
  <div>
    <div class="media-header">
      <div class="media-header__details">
        <div class="media-header__details-top">
          <h3 v-if="$slots['default']" class="media-header__title"><slot /></h3>
          <slot name="details-top" />
        </div>

        <div class="media-header__details-bottom">
          <p
            v-if="$slots['price'] || $slots['price-addendum']"
            class="media-header__cost u-small--regular"
          >
            <strong v-if="$slots['price']" class="media-header__price">
              <slot name="price" />
            </strong>
            <slot name="price-addendum" />
          </p>

          <slot name="details-bottom" />
        </div>
      </div>

      <div class="media-header__actions">
        <slot name="actions" />
      </div>

      <div v-if="$slots['warning']" class="media-header__warning">
        <slot name="warning" />
      </div>

      <SoonaButton
        class="media-header__close"
        :size="isWide ? 'large' : 'medium'"
        variation="icon-gray-outline"
        element="router-link"
        :to="backUrl"
        :title="backText"
        @click="trackBackClick"
      >
        <SoonaIcon name="xmark" />
        <span class="u-visually-hidden">Close</span>
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use 'src/variables';

.media-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  background-color: variables.$white-default;
  padding: 1rem;

  &__details {
    flex: 1 1 calc(100% - 3.25rem);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__details-top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.5rem;
    padding-right: 3rem;
  }

  &__title {
    word-break: break-word;
    display: flex;
    align-items: center;
    min-height: 1.5rem; // prevents layout jank when quickly navigating images
  }

  &__details-bottom {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  :deep(.media-header__close) {
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  &__actions {
    flex: 0 0 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  &__cost {
    gap: 0.25rem;
    display: flex;
    flex: 0 0 100%;
    align-items: center;
  }

  &__price {
    color: variables.$black-default;
  }

  &__warning {
    flex-basis: 100%;
  }

  @media (min-width: variables.$screen-md-min) {
    gap: 1.25rem;
    position: relative;
    padding: 0.75rem 6.75rem 0.75rem 2rem;

    &__details {
      flex: 1 1 auto;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      align-self: center;
      min-height: 2.75rem; // prevents layout jank when quickly navigating images
    }

    &__details-top {
      padding-right: 0;
    }

    &__details-bottom {
      flex: 0 1 auto;
      gap: 0.75rem;
    }

    :deep(.media-header__close) {
      top: 0.75rem;
      right: 2rem;
      height: auto;
    }

    &__actions {
      gap: 0.5rem;
      flex: 0 1 auto;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
    }

    &__cost {
      flex: 0 1 auto;
    }
  }
}
</style>
