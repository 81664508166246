<script setup>
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';

import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaRadioButtonItem from '@/components/ui_library/soona_radio_buttons/SoonaRadioButtonItem.vue';
import SoonaRadioButtonGroup from '@/components/ui_library/soona_radio_buttons/SoonaRadioButtonGroup.vue';

const liveShootStore = useLiveShootStore();
const { activeMobileView } = storeToRefs(liveShootStore);
</script>

<template>
  <nav class="live-mobile-nav">
    <SoonaRadioButtonGroup
      name="nav-switch"
      label="active view"
      :model-value="activeMobileView"
      @update:model-value="liveShootStore.setActiveMobileView"
    >
      <SoonaRadioButtonItem value="gallery" class="live-mobile-nav__nav-item">
        <SoonaIcon name="grid" size="medium" />
        <span class="u-small--regular"> Gallery </span>
      </SoonaRadioButtonItem>
      <SoonaRadioButtonItem value="chat" class="live-mobile-nav__nav-item">
        <SoonaIcon name="message-circle-lines-alt" size="medium" />
        <span class="u-small--regular"> Chat </span>
      </SoonaRadioButtonItem>
    </SoonaRadioButtonGroup>
  </nav>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-mobile-nav {
  gap: 1rem;
  z-index: 1;
  display: flex;
  padding: 0.75rem;
  align-items: center;
  justify-content: center;
  box-shadow: variables.$elevation-0;
  background: variables.$white-default;
  view-transition-name: live-mobile-nav;

  &__nav-item {
    &:deep(.radio-button__label) {
      min-width: 5.5rem;
    }
  }
}
</style>
