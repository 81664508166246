<script setup>
import { computed, ref, toRefs } from 'vue';
import { useRouter } from 'vue-router';
import { useAccount } from '@/composables/useAccount';
import CreateContractCard from './CreateContractCard.vue';
import CreateContractInfo from './CreateContractInfo.vue';
import BillingInfo from '../BillingInfo.vue';
import CreateDiscountsRates from './CreateDiscountsRates.vue';
import CreateSubscriptionInfo from './CreateSubscriptionInfo.vue';
import CreateContractOverdraft from '@/components/user/anytime/contracts/create/CreateContractOverdraft.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import UploadContract from './UploadContract.vue';
import { isEmptyValue } from '@/lib/global/is-empty-value';
import { useSchedulingPreferences } from '@/composables/useSchedulingPreferences';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
  contractData: {
    required: true,
    type: [Object],
    default: () => ({}),
  },
});
const emit = defineEmits(['review']);
const router = useRouter();

const accountId = computed(() => props.accountId);

const { contractData } = toRefs(props);
const formData = ref(contractData.value);

const {
  billingAddress,
  preferredTimezone,
  isLoading: loadingAccount,
} = useAccount(accountId);

const { preferredAccountTimeZoneName, accountPreferenceLoading } =
  useSchedulingPreferences(accountId, null);

const accountTimeZone = computed(
  () => preferredTimezone.value || preferredAccountTimeZoneName.value
);

const goToPreviousPage = () => {
  router.back();
};

const handleUpdateContractData = updatedContractData => {
  const currentContractData = formData.value;
  formData.value = { ...currentContractData, ...updatedContractData };
};

const handleReview = () => {
  emit('review', formData.value);
};

const disableSubmit = computed(() => {
  if (!billingAddress.value) return true;
  if (formData.value.duration <= 0) return true;
  if (formData.value.retainerAmount <= 0) return true;
  if (!formData.value.startDate) return true;
  if (!formData.value.endDate) return true;
  if (formData.value.totalAmount <= 0) return true;
  if (isEmptyValue(formData.value.photoUnit)) return true;
  if (isEmptyValue(formData.value.videoUnit)) return true;
  if (isEmptyValue(formData.value.gifUnit)) return true;
  if (isEmptyValue(formData.value.ugcUnit)) return true;
  return !formData.value.overdraftType;
});

const isLoading = computed(
  () => loadingAccount.value || accountPreferenceLoading.value
);
</script>

<template>
  <div class="create-contract-form">
    <SoonaLoading v-if="isLoading" is-loading is-contained />
    <!-- Billing Info -->
    <CreateContractCard title="Billing Info">
      <BillingInfo :account-id="accountId" />
    </CreateContractCard>
    <CreateContractCard title="Contract Info">
      <!-- Contract Info -->
      <CreateContractInfo
        :preferred-timezone="accountTimeZone"
        :initial-data="contractData"
        @update="handleUpdateContractData"
      />
      <hr />
      <!-- Discounts & unit rates -->
      <CreateDiscountsRates
        :initial-data="contractData"
        @update="handleUpdateContractData"
      />
      <CreateContractOverdraft
        :overdraft-type="contractData?.overdraftType"
        @update="handleUpdateContractData"
      />
      <!-- Subscription Info -->
      <CreateSubscriptionInfo
        :fast-pass-price="contractData?.fastPassPrice"
        @update="handleUpdateContractData"
      />
      <UploadContract
        :uploaded-files="formData?.uploadedFiles"
        @update="handleUpdateContractData"
      />
    </CreateContractCard>
    <div class="create-contract-form__footer">
      <SoonaButton
        class="create-contract-form__cancel-btn"
        variation="tertiary"
        size="medium"
        @on-click="goToPreviousPage"
      >
        Cancel
      </SoonaButton>
      <SoonaButton
        class="create-contract-form__review-btn"
        variation="solid-black"
        size="medium"
        :disabled="disableSubmit"
        @on-click="handleReview"
      >
        Review contract details
        <SoonaIcon name="arrow-right" />
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.create-contract-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  hr {
    background-color: variables.$gray-30;
    margin: 0;
  }

  &__back-btn {
    max-width: fit-content;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }
}
</style>
