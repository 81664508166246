<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { refDebounced, useElementBounding } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { useAccount } from '@/composables/useAccount';
import { useBaseEvents } from '@/composables/segment/useBaseEvents';
import { useCapability } from '@/composables/useCapability';
import { useFlag } from '@/composables/useFlag';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import DigitalAssetCard from '@/components/account_gallery/DigitalAssetCard.vue';
import InfiniteGallery from '@/components/infinite_asset_gallery/InfiniteGallery.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import { useBulkSelection } from '@/composables/digital_assets/useBulkSelection';
import GallerySearch from './GallerySearch.vue';
import SoonaButton from '../ui_library/SoonaButton.vue';
import SoonaIcon from '../ui_library/soona_icon/SoonaIcon.vue';
import AccountGalleryFilterToolbar from './AccountGalleryFilterToolbar.vue';
import { useMe } from '@/composables/user/useMe';
import MultiSelectGalleryActionBar from './MultiSelectGalleryActionBar/MultiSelectGalleryActionBar.vue';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  canRemoveFromAlbum: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['favouritesClicked']);

const { pageViewed } = useBaseEvents();
const route = useRoute();
const router = useRouter();
const accountId = computed(() => +props.accountId);
const { account, isDigitalSubscriber, hasEmbeddingsEnabled } =
  useAccount(accountId);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'soona_staff',
});

const galleryEmbeddedSearchFlag = useFlag('pegasus_gallery_embedded_search');
const globalEmbeddedSearchFlag = useFlag('pegasus_global_embedded_search');

const wrapper = ref(null);

const { currentAccountId } = useMe();
const isMyAccount = computed(() => currentAccountId.value === accountId.value);

const headingCopy = computed(() => {
  if (isMyAccount.value) return 'assets';
  return `${account.value?.name}’s assets`;
});

const canRemoveFromAlbum = computed(() => {
  return props.canRemoveFromAlbum;
});

const itemsPerPage = ref(25);
const searchText = ref('');
const searchDebounced = refDebounced(searchText, 250);
const similarityText = ref('');
const similaritySearch = ref('');
const selectedSort = ref(route.query?.order_by ?? 'date created');
const sortDirection = ref(route.query?.direction ?? 'desc');
const startPage = ref(1);
const favoritesActivated = ref(
  +route.query?.collection_id === account.value?.favorites_collection_id
    ? true
    : false
);

const albumCollectionId = computed({
  get() {
    return route.query?.collection_id;
  },
  set(value) {
    router.replace({
      query: { ...route.query, collection_id: value },
    });
  },
});

const { left, width } = useElementBounding(wrapper);

const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapper,
  heightRem: 14,
  gapRem: 1,
});

const selectedFilters = computed({
  get() {
    return {
      origin: route.query?.origin,
      ownership: route.query?.ownership,
      media_type: route.query?.media_type,
      collection_id: route.query?.collection_id,
      direction: sortDirection.value,
      order_by: selectedSort.value,
    };
  },
  set(value) {
    router.replace({
      query: Object.fromEntries(
        Object.entries(value).filter(
          ([, val]) => val !== undefined && val !== null
        )
      ),
    });
  },
});

const queryFilters = computed(() => {
  return {
    ...selectedFilters.value,
    visibility: 'all',
  };
});

const embeddedSearchEnabled = computed(() => {
  return (
    hasEmbeddingsEnabled.value &&
    galleryEmbeddedSearchFlag.value &&
    (isSoonaStaff.value ||
      isDigitalSubscriber.value ||
      globalEmbeddedSearchFlag.value)
  );
});

const updateFilters = filters => {
  selectedFilters.value = {
    ...selectedFilters.value,
    ...filters,
  };
};

const toggleFavoritesFilter = () => {
  emit(
    'favouritesClicked',
    favoritesActivated.value ? null : account.value?.favorites_collection_id,
    'FavoritesCollectionDigitalAsset'
  );
};

const { assetRows, fetchPage, pagination, error, rawData } =
  useInfiniteDigitalAssets(accountId, {
    gutter,
    rowWidth,
    itemsPerPage,
    height: rowHeight,
    sortBy: selectedSort,
    filters: queryFilters,
    searchQuery: searchDebounced,
    sortDirection: sortDirection,
    similarityText: similaritySearch,
  });

const priorityErrors = usePriorityErrors(error);

const { onSelectionClick, resetSelection, isAssetSelected, selectedAssets } =
  useBulkSelection(rawData, da => da);

watch(
  () => route.query,
  newQuery => {
    favoritesActivated.value =
      +newQuery.collection_id === account.value?.favorites_collection_id;
  }
);

watchEffect(() => {
  if (similarityText.value === '') {
    similaritySearch.value = '';
  }
});

onMounted(() => {
  pageViewed();
});
</script>

<template>
  <div class="all-assets">
    <div>
      <h1 class="u-title--heavy">{{ headingCopy }}</h1>
      <GallerySearch
        v-if="embeddedSearchEnabled"
        v-model="similarityText"
        class="all-assets__search"
        @submit.prevent="similaritySearch = similarityText"
      />
    </div>

    <div class="all-assets__toolbar">
      <AccountGalleryFilterToolbar
        v-model:selected-sort="selectedSort"
        v-model:sort-direction="sortDirection"
        :disable-sort="!!similarityText"
        :selected-filters="selectedFilters"
        @filter-change="updateFilters"
        @update:sort-direction="() => updateFilters()"
        @update:selected-sort="() => updateFilters()"
      />

      <div
        v-if="!route.query?.collection_id || favoritesActivated"
        class="all-assets__favourite-wrapper"
      >
        <SoonaButton
          variation="secondary-periwink"
          size="medium"
          @click="toggleFavoritesFilter"
        >
          <SoonaIcon
            :class="{ 'friendly-red': favoritesActivated }"
            :name="favoritesActivated ? 'heart-solid' : 'heart'"
          />favorites
        </SoonaButton>
      </div>
    </div>

    <p class="all-assets__items u-small--regular">
      {{ pagination.totalCount.toLocaleString() }} items
    </p>

    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <section v-else ref="wrapper">
      <InfiniteGallery
        v-slot="{ data }"
        :rows="assetRows"
        :start-page="startPage"
        :height="rowHeight"
        :gap="gutter"
        :offset-top="offsetTop"
      >
        <DigitalAssetCard
          v-for="asset in data.assets"
          :key="asset.id"
          :asset="asset"
          :flex-grow="data.width / rowWidth > 0.6 ? 1 : 0"
          :selected="isAssetSelected(asset)"
          :to="{
            name: 'account-gallery-media-view',
            params: {
              accountId: asset.account_id,
              digitalAssetId: asset.id,
            },
            query: selectedFilters,
          }"
          @request-page="pageNumber => fetchPage(pageNumber)"
          @selection-click="onSelectionClick(asset, $event)"
        />
      </InfiniteGallery>
      <MultiSelectGalleryActionBar
        :account-id="accountId"
        :page-bounding-rect-left="left"
        :page-bounding-rect-width="width"
        :selected-assets="selectedAssets"
        :album-collection-id="albumCollectionId"
        :show-remove-from-album="canRemoveFromAlbum"
        @close="resetSelection"
        @remove-selected-asset="onSelectionClick"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.all-assets {
  gap: 1rem;
  display: flex;
  flex-direction: column;

  &__search {
    margin-top: 0.5rem;
  }

  &__toolbar {
    gap: 0.5rem;
    display: flex;
    justify-content: space-between;
  }

  &__favourite-wrapper {
    gap: 0.5rem;
    display: flex;
    align-items: center;
  }

  &__items {
    color: variables.$gray-60;
  }

  .friendly-red {
    color: variables.$friendly-red-50;
  }
}
</style>
