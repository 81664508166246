<script setup>
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import CreateContractForm from './CreateContractForm.vue';
import ReviewContract from './ReviewContract.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [String, Number],
  },
});
const router = useRouter();
const accountId = computed(() => props.accountId);
const pageType = ref('create'); // 'create' | 'review'
const contractData = ref({
  discount: null,
  duration: 0,
  endDate: null,
  enterprisePrice: null,
  fastPassPrice: null,
  gifUnit: null,
  overdraftType: 'none',
  photoUnit: null,
  retainerAmount: 0,
  startDate: null,
  totalAmount: 0,
  ugcUnit: null,
  uploadedFiles: [],
  utcEndDate: null,
  utcStardDate: null,
  videoUnit: null,
});

const handleBack = () => {
  if (pageType.value === 'review') {
    pageType.value = 'create';
  } else {
    router.back();
  }
};

const review = contract => {
  contractData.value = contract;
  pageType.value = 'review';
};
</script>

<template>
  <div class="create-contract">
    <SoonaBackButton
      class="create-contract__back-button"
      button-text="Back"
      @click="handleBack"
    />
    <div v-if="pageType === 'review'" class="create-contract__title-section">
      <h2 class="u-headline--heavy">Review contract</h2>
    </div>
    <CreateContractForm
      v-if="pageType === 'create'"
      :account-id="accountId"
      :contract-data="contractData"
      @review="review"
    />
    <ReviewContract
      v-else
      :account-id="accountId"
      :contract-data="contractData"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.create-contract {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }
}
</style>
