<script setup>
import SoonaButton from '../ui_library/SoonaButton.vue';
import SoonaIcon from '../ui_library/soona_icon/SoonaIcon.vue';

defineProps({
  assets: {
    type: Array,
    required: true,
  },
});

const selectedAssetIndex = defineModel('selectedAssetIndex');
</script>

<template>
  <div class="batch-single-asset-viewer">
    <div class="batch-single-asset-viewer__asset">
      <slot />
    </div>

    <div class="batch-single-asset-viewer__buttons">
      <SoonaButton
        variation="icon-gray-outline"
        :disabled="selectedAssetIndex === 0"
        @on-click="selectedAssetIndex -= 1"
      >
        <SoonaIcon name="arrow-left" />
        <span class="u-visually-hidden">Previous</span>
      </SoonaButton>
      {{ selectedAssetIndex + 1 }} of {{ assets.length }}
      <SoonaButton
        variation="icon-gray-outline"
        :disabled="selectedAssetIndex === assets.length - 1"
        @on-click="selectedAssetIndex += 1"
      >
        <SoonaIcon name="arrow-right" />
        <span class="u-visually-hidden">Next</span>
      </SoonaButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.batch-single-asset-viewer {
  gap: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__asset {
    width: 100%;
    min-height: 0;
    max-height: 100%;
    aspect-ratio: 1 / 1;
  }

  &__buttons {
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
