<script setup>
import { ref, computed, toRefs } from 'vue';

import { useCapabilities } from '@/composables/useCapabilities';

import SoonaIcon from 'src/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaFilterSingle from '@/components/ui_library/SoonaFilterSingle.vue';

const props = defineProps({
  facets: {
    type: Object,
    default: null,
  },
  isShrunk: {
    type: Boolean,
    default: false,
  },
});

const selectedFilter = defineModel({
  type: String,
  default: '',
});

const { facets } = toRefs(props);

const capabilitiesRef = ref([
  { capability: 'ft_soona_staff' },
  { capability: 'raw_no_thumbnail' },
]);

const capabilities = useCapabilities(capabilitiesRef);

const filterOptions = computed(() => {
  if (!facets.value) return [];

  const options = [
    {
      label: 'all',
      value: 'all',
      count: facets.value.all_count,
    },
    {
      label: 'favorites',
      value: 'favorites',
      count: facets.value.favorites_count,
      iconName: 'heart',
      selectedIconName: 'heart-solid',
    },
    {
      label: 'expert picks',
      value: 'expert_picks',
      count: facets.value.expert_picks_count,
      iconName: 'trophy',
      selectedIconName: 'trophy-solid',
    },
    {
      label: 'in bag',
      value: 'in_bag',
      count: facets.value.in_bag_count,
      iconName: 'soona-bag',
      selectedIconName: 'soona-bag-solid',
    },
    {
      label: 'notes',
      value: 'notes',
      count: facets.value.notes_count,
      iconName: 'message-circle-lines-alt',
      selectedIconName: 'message-circle-lines-alt',
    },
  ];

  if (capabilities.value?.ft_soona_staff?.hasCapability) {
    options.push({
      label: 'hidden',
      value: 'hidden',
      count: facets.value.hidden_count,
      iconName: 'eye-slash',
      selectedIconName: 'eye-slash',
    });
    options.push({
      label: 'archived',
      value: 'archived',
      count: facets.value.archived_count,
      iconName: 'box-archive',
      selectedIconName: 'box-archive',
    });
  }

  if (facets.value.edits_count > 0) {
    options[0].label = 'unpurchased';
    options.unshift({
      label: 'edits',
      value: 'edits',
      count: facets.value.edits_count,
    });
  }

  if (capabilities.value?.raw_no_thumbnail?.hasCapability) {
    options.push({
      label: 'raws no preview',
      value: 'raws_no_preview',
      count: undefined,
      iconName: 'file',
      selectedIconName: 'file-search-alt',
    });
  }

  return options;
});
</script>

<template>
  <div class="gallery-filters" :class="{ 'gallery-filters--shrunk': isShrunk }">
    <SoonaFilterSingle
      v-model="selectedFilter"
      :options="filterOptions"
      class="gallery-filters__filter"
      variation="black"
      size="small"
    >
      <template
        #option="{ count, iconName, label, selected, selectedIconName }"
      >
        <SoonaIcon
          v-if="iconName"
          :name="selected && selectedIconName ? selectedIconName : iconName"
        />
        <span
          class="u-label--heavy"
          :class="{ 'u-visually-hidden': isShrunk && label !== 'all' }"
        >
          {{ label }}
        </span>
        <span v-if="count && !isShrunk" class="u-body--regular">
          ({{ count }})
        </span>
      </template>
    </SoonaFilterSingle>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.gallery-filters {
  display: flex;
  align-items: center;
  padding-left: 0.75rem;

  &--shrunk {
    padding-left: 0.5rem;

    & .gallery-filters__filter {
      gap: 0.25rem;

      :deep(.soona-filter-single__button) {
        padding: 0.1875rem 0.5rem;
      }
    }
  }
}
</style>
