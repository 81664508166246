import { computed, readonly } from 'vue';

import { useCapability } from '@/composables/useCapability';
import { useBagCollection } from '@/queries/bag_collection/useBagCollection';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useStaffPicksCollection } from '@/queries/staff_picks_collection/useStaffPicksCollection';
import { useCreateBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useCreateBagCollectionDigitalAsset';
import { useDeleteBagCollectionDigitalAsset } from '@/queries/bag_collection_digital_assets/useDeleteBagCollectionDigitalAsset';
import { useCreateFavoritesCollectionDigitalAsset } from '@/queries/favorites-collection-digital-assets/useCreateFavoritesCollectionDigitalAsset';
import { useDeleteFavoritesCollectionDigitalAsset } from '@/queries/favorites-collection-digital-assets/useDeleteFavoritesCollectionDigitalAssets';
import { useCreateStaffPickCollectionDigitalAsset } from '@/queries/staff_picks_collection_digital_assets/useCreateStaffPicksCollectionDigitalAsset';
import { useDeleteStaffPickCollectionDigitalAsset } from '@/queries/staff_picks_collection_digital_assets/useDeleteStaffPicksCollectionDigitalAsset';

/**
 * @description A generic composable to handle
 * the most common actions for digital assets,
 *
 * supports: favorite, add-to-bag, staff picks
 *
 * @param {Ref<Object>} digitalAsset - the digital asset in question
 * @returns {{
 *   isOwnedBySoona: import('vue').DeepReadonly<boolean>,
 *   toggleAddToBag: toggleAddToBagFn,
 *   canBagAsset: import('vue').DeepReadonly<boolean>,
 *   isAddedToBag: import('vue').DeepReadonly<boolean>,
 *   isAddToBagLoading: import('vue').DeepReadonly<boolean>,
 *   isAddToBagDisabled: import('vue').DeepReadonly<boolean>,
 *   toggleFavorite: toggleFavoriteFn,
 *   isFavorited: import('vue').DeepReadonly<boolean>,
 *   canFavoriteAsset: import('vue').DeepReadonly<boolean>,
 *   isFavoriteLoading: import('vue').DeepReadonly<boolean>,
 *   isFavoriteDisabled: import('vue').DeepReadonly<boolean>,
 *   toggleStaffPick: toggleStaffPickFn,
 *   isStaffPicked: import('vue').DeepReadonly<boolean>,
 *   isStaffPicksLoading: import('vue').DeepReadonly<boolean>,
 *   isStaffPicksDisabled: import('vue').DeepReadonly<boolean>,
 *   canManageCustomerCollections: import('vue').DeepReadonly<boolean>
 * }}
 */
export function useDigitalAssetCommonActions(digitalAsset) {
  const digitalAssetId = computed(() => digitalAsset.value.id);
  const accountId = computed(() => digitalAsset.value.account_id);

  const { hasCapability: canFavoriteAsset } = useCapability({
    capability: 'favorite_gallery',
    subjectType: 'account',
    subjectId: accountId,
  });

  const { hasCapability: canBagAsset } = useCapability({
    capability: 'add_to_bag',
    subjectType: 'account',
    subjectId: accountId,
  });

  const { hasCapability: canManageCustomerCollections } = useCapability({
    capability: 'manage_customer_collections',
  });

  //== bag
  const { data: bagCollection } = useBagCollection(accountId, {
    // frequently fetched in galleries, keep it fresh for a minute to reduce calls
    staleTime: 1000 * 60,
  });
  const bagCollectionId = computed(() => bagCollection.value?.id);
  const bagCDAId = computed(
    () => digitalAsset.value.bag_collection_digital_asset?.id
  );

  const { mutate: mutateAddToBag, isPending: isAddingToBag } =
    useCreateBagCollectionDigitalAsset(bagCollectionId);

  const { mutate: mutateRemoveFromBag, isPending: isRemovingFromBag } =
    useDeleteBagCollectionDigitalAsset(bagCollectionId, bagCDAId);

  const isAddToBagLoading = computed(
    () => isAddingToBag.value || isRemovingFromBag.value
  );
  const isAddToBagDisabled = computed(() => {
    return !canBagAsset.value || isAddToBagLoading.value;
  });
  const isAddedToBag = computed(() => !!bagCDAId.value);

  /**
   * @callback toggleAddToBagFn
   * @returns {void}
   */
  function toggleAddToBag() {
    if (!bagCollectionId.value || !canBagAsset.value) return;

    if (isAddedToBag.value) {
      mutateRemoveFromBag();
    } else {
      mutateAddToBag({
        digital_asset_id: digitalAssetId.value,
      });
    }
  }
  //== end bag

  // favorites
  const { data: favoriteCollection } = useFavoritesCollection(accountId, {
    // frequently fetched in galleries, keep it fresh for a minute to reduce calls
    staleTime: 1000 * 60,
  });
  const favoriteCollectionId = computed(() => favoriteCollection.value?.id);
  const favoriteCDAId = computed(
    () => digitalAsset.value.favorites_collection_digital_asset?.id
  );

  const { mutate: mutateAddToFavorite, isPending: isAddingToFavorites } =
    useCreateFavoritesCollectionDigitalAsset(favoriteCollectionId);

  const {
    mutate: mutateRemoveFromFavorite,
    isPending: isRemovingFromFavorites,
  } = useDeleteFavoritesCollectionDigitalAsset(
    favoriteCollectionId,
    favoriteCDAId
  );

  const isFavoriteLoading = computed(
    () => isAddingToFavorites.value || isRemovingFromFavorites.value
  );
  const isFavoriteDisabled = computed(() => {
    return !canFavoriteAsset.value || isFavoriteLoading.value;
  });
  const isFavorited = computed(() => !!favoriteCDAId.value);

  /**
   * @callback toggleFavoriteFn
   * @returns {void}
   */
  function toggleFavorite() {
    if (!favoriteCollectionId.value || !canFavoriteAsset.value) return;

    if (isFavorited.value) {
      mutateRemoveFromFavorite({
        digital_asset_id: digitalAssetId.value,
      });
    } else {
      mutateAddToFavorite({
        digital_asset_id: digitalAssetId.value,
      });
    }
  }
  //== end favorites

  // staff picks
  const { data: staffPicksCollection } = useStaffPicksCollection(accountId, {
    // frequently fetched in galleries, keep it fresh for a minute to reduce calls
    staleTime: 1000 * 60,
  });
  const staffPicksCollectionId = computed(() => staffPicksCollection.value?.id);
  const staffPickCDAId = computed(
    () => digitalAsset.value.staff_picks_collection_digital_asset?.id
  );
  const { mutate: mutateAddtoStaffPicks, isPending: isAddingToStaffPicks } =
    useCreateStaffPickCollectionDigitalAsset(staffPicksCollectionId);
  const {
    mutate: mutateRemoveFromStaffPicks,
    isPending: isRemovingFromStaffPicks,
  } = useDeleteStaffPickCollectionDigitalAsset(
    staffPicksCollectionId,
    staffPickCDAId
  );

  const isStaffPicksLoading = computed(
    () => isAddingToStaffPicks.value || isRemovingFromStaffPicks.value
  );
  const isStaffPicksDisabled = computed(() => {
    return !canManageCustomerCollections.value || isStaffPicksLoading.value;
  });
  const isStaffPicked = computed(() => !!staffPickCDAId.value);

  /**
   * @callback toggleStaffPickFn
   * @returns {void}
   */
  function toggleStaffPick() {
    if (!staffPicksCollectionId.value || !canManageCustomerCollections.value) {
      return;
    }

    if (isStaffPicked.value) {
      mutateRemoveFromStaffPicks({
        digital_asset_id: digitalAssetId.value,
      });
    } else {
      mutateAddtoStaffPicks({
        digital_asset_id: digitalAssetId.value,
      });
    }
  }
  //== end staff picks

  const isOwnedBySoona = computed(
    () => digitalAsset.value?.ownership === 'soona'
  );

  return {
    isOwnedBySoona: readonly(isOwnedBySoona),

    toggleAddToBag,
    canBagAsset: readonly(canBagAsset),
    isAddedToBag: readonly(isAddedToBag),
    isAddToBagLoading: readonly(isAddToBagLoading),
    isAddToBagDisabled: readonly(isAddToBagDisabled),

    toggleFavorite,
    isFavorited: readonly(isFavorited),
    canFavoriteAsset: readonly(canFavoriteAsset),
    isFavoriteLoading: readonly(isFavoriteLoading),
    isFavoriteDisabled: readonly(isFavoriteDisabled),

    toggleStaffPick,
    isStaffPicked: readonly(isStaffPicked),
    isStaffPicksLoading: readonly(isStaffPicksLoading),
    isStaffPicksDisabled: readonly(isStaffPicksDisabled),
    canManageCustomerCollections: readonly(canManageCustomerCollections),
  };
}
