import { toValue } from 'vue';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { queryKeys } from 'src/queries/query-keys';
import { http } from '@/config/vue-axios';

export function useConfirmRetainer(reservationId) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const response = await http.post(
        `/reservations/${toValue(reservationId)}/confirm`,
        { confirm: { sales_tax: { calculate_sales_tax: true } } }
      );

      return response.data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.reservations(reservationId),
      });
    },
  });
}
