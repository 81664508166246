<script setup>
import { computed, ref } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaDialog from '@/components/ui_library/SoonaDialog.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';
import { useUpdateAccount } from 'src/queries/account/useUpdateAccount';
import { useUpdateUser } from 'src/queries/users/useUpdateUser';
import { useAccount } from '@/queries/account/useAccount';
import { useGetUser } from 'src/queries/users/useGetUser';
import { useMe } from '@/composables/user/useMe';

const { currentAccountId, currentUserId } = useMe();
const { mutate: updateAccount } = useUpdateAccount(currentAccountId);
const { data: account } = useAccount(currentAccountId);
const isDesktop = useMediaQuery('(min-width: 48rem)');

const { mutate: updateUser } = useUpdateUser(currentUserId);
const { data: user } = useGetUser(currentUserId);

const jobTitle = ref(user.value?.job_title);
const website = ref(account.value?.website);

const validInputs = computed(() => {
  return jobTitle.value && website.value;
});

async function submitOnboardingInfo(e) {
  e.preventDefault();
  const accountInfo = {
    lets_get_the_basics: {
      data: {
        website: website.value,
      },
    },
  };

  updateAccount(accountInfo);
  updateUser({ job_title: jobTitle.value });
}
</script>

<template>
  <SoonaDialog role="alertdialog">
    <template #hero-image>
      <picture>
        <source
          type="image/webp"
          srcset="@images/onboarding-welcome-hero-banner.webp"
        />
        <img src="@images/onboarding-welcome-hero-banner.png" alt="" />
      </picture>
    </template>

    <template #heading>
      <header>
        <h2 class="u-title--heavy">Welcome to soona</h2>
        <p class="u-body--regular">
          Your all-in-one platform to make, manage, and optimize photo + video
          content for all your ecommerce channels.
        </p>
      </header>
    </template>

    <SoonaForm id="account-info-form" @submit="e => submitOnboardingInfo(e)">
      <SoonaTextfield
        v-model="website"
        label="company website"
        name="website"
        :required="true"
        autocomplete="off"
        placeholder="e.g. www.soona.co"
        :rules="['url']"
        :show-counter="false"
        class="custom-textfield-padding"
        cypress-name="input-website"
      />

      <SoonaTextfield
        v-model="jobTitle"
        label="job title"
        name="jobTitle"
        :required="true"
        autocomplete="on"
        placeholder="e.g. Marketing Manager"
        :show-counter="false"
        class="custom-textfield-padding"
        cypress-name="input-job-title"
      />
    </SoonaForm>

    <template #footer>
      <SoonaButton
        type="submit"
        variation="solid-black"
        form="account-info-form"
        data-cypress="button-website"
        :disabled="!validInputs"
        :size="isDesktop ? 'medium' : 'large'"
      >
        continue
      </SoonaButton>
    </template>
  </SoonaDialog>
</template>

<style lang="scss" scoped>
.custom-textfield-padding {
  padding-bottom: 1rem;
}
</style>
