<script setup>
import { toRefs } from 'vue';
import { storeToRefs } from 'pinia';

import { createLiveShootStore } from './useLiveShootStore';
import { useStartViewTransition } from '@/composables/useStartViewTransition';

import LiveGallery from './LiveGallery.vue';
import LiveActionBar from './LiveActionBar.vue';
import LiveShootHeader from './LiveShootHeader.vue';
import LiveFocusedAsset from './LiveFocusedAsset.vue';
import LiveMobileNav from './mobile/LiveMobileNav.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import { useAccount } from '@/composables/useAccount';

const props = defineProps({
  reservationId: {
    type: [Number, String],
    required: true,
  },
  filter: {
    default: null,
    type: [String, null],
  },
});

const { reservationId } = toRefs(props);

const liveShootStore = createLiveShootStore({ reservationId });
const {
  isMobile,
  isDesktop,
  selectedRDA,
  assetAccountId,
  centerViewOpen,
  activeMobileView,
  galleryHasMinimized,
  isReservationLoading,
} = storeToRefs(liveShootStore);

const { startViewTransition } = useStartViewTransition();
const toggleCenterView = ({ open, initialAsset } = {}) => {
  if (initialAsset && !selectedRDA.value) {
    liveShootStore.setSelectedRDA(initialAsset);
  }

  if (centerViewOpen.value === open) {
    return;
  }

  document.getElementById('live-gallery').style.overflow = 'visible';

  startViewTransition(() => {
    liveShootStore.setCenterViewOpen(
      open !== undefined ? open : !centerViewOpen.value
    );
  }).finished.finally(() => {
    liveShootStore.setGalleryHasMinimized(
      open !== undefined ? open : !galleryHasMinimized.value
    );
    document.getElementById('live-gallery').style.overflow = 'auto';
  });
};

const { isActiveRetainerClient } = useAccount(assetAccountId);
</script>

<template>
  <teleport to="body">
    <section
      class="live"
      role="dialog"
      aria-modal="true"
      :class="{ 'live--center-open': centerViewOpen && isDesktop }"
    >
      <SoonaLoading
        v-if="isReservationLoading"
        is-loading
        loading-text="Setting the stage..."
      />
      <LiveShootHeader class="live__header" :reservation-id="reservationId" />

      <section
        v-if="!isMobile || activeMobileView === 'gallery'"
        ref="galleryWrapper"
        class="live__gallery-wrapper"
        :class="{
          'live__gallery-wrapper--shrink': centerViewOpen && isDesktop,
        }"
      >
        <LiveGallery
          id="live-gallery"
          :key="`gallery-minimized-${centerViewOpen}`"
          :filter="filter"
          :is-retainer-client="isActiveRetainerClient"
          @toggle-center-view="toggleCenterView"
        />

        <LiveActionBar v-if="!centerViewOpen && !isMobile" />
      </section>

      <section v-if="centerViewOpen && isDesktop" class="live__center-view">
        <LiveFocusedAsset v-if="selectedRDA" @close="toggleCenterView" />
      </section>

      <aside v-if="!isMobile" class="live__video-feed">
        <h3 class="u-body--heavy">video feed</h3>
      </aside>

      <aside v-if="!isMobile || activeMobileView === 'chat'" class="live__chat">
        <h3 class="u-body--heavy">live chat</h3>
      </aside>

      <LiveMobileNav v-if="isMobile" />
    </section>
  </teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live {
  inset: 0;
  position: fixed;
  background: variables.$gray-10;

  display: flex;
  flex-direction: column;

  @media (min-width: 40rem) {
    gap: 1rem;
    padding: 1rem 1rem 0 1rem;

    display: grid;
    grid-template-rows: auto 1fr 3fr;
    grid-template-columns: 2.5fr 2fr;
    grid-template-areas:
      'header header'
      'left top-right'
      'left bottom-right';
  }

  @media (min-width: variables.$screen-lg-min) {
    grid-template-columns: 15rem 2fr minmax(auto, 21.25rem);

    grid-template-areas:
      'header header header'
      'left left top-right'
      'left left bottom-right';

    &--center-open {
      grid-template-areas:
        'header header header'
        'left middle top-right'
        'left middle bottom-right';
    }
  }

  &__header {
    grid-area: header;
  }

  &__gallery-wrapper {
    flex: 1;
    display: flex;
    overflow: auto;
    grid-area: left;
    max-height: 100%;
    position: relative;
    flex-direction: column;
    background: variables.$white-default;

    @media (min-width: 40rem) {
      margin-bottom: 1rem;
      border-radius: 0.625rem;
      box-shadow: variables.$elevation-0;
    }

    &--shrink {
      margin-bottom: 0;
      border-radius: 0.625rem 0.625rem 0 0;
    }
  }

  &__center-view {
    overflow: auto;
    grid-area: middle;
  }

  &__video-feed {
    grid-area: top-right;
    border-radius: 0.625rem;
    box-shadow: variables.$elevation-0;
    background: variables.$white-default;

    h3 {
      padding: 1rem;
      box-shadow: variables.$elevation-0;
    }
  }

  &__chat {
    flex: 1;
    grid-area: bottom-right;
    background: variables.$white-default;

    @media (min-width: 40rem) {
      margin-bottom: 1rem;
      border-radius: 0.625rem;
      box-shadow: variables.$elevation-0;
    }

    h3 {
      padding: 1rem;
      box-shadow: variables.$elevation-0;
    }
  }
}
</style>
