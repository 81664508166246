export const useStartViewTransition = () => {
  const startViewTransition = fn => {
    // Fallback for browsers that don't support view transition API:
    if (!document.startViewTransition) {
      fn();

      return {
        finished: new Promise(resolve => {
          resolve();
        }),
      };
    }

    return document.startViewTransition(() => fn());
  };
  return { startViewTransition };
};
