<script setup>
import { computed } from 'vue';
import { useCapability } from '@/composables/useCapability';
import { useMe } from '@/composables/user/useMe';
import emptyImage from 'images/empty-states/empty-contracts.png';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaNoResults from '@/components/ui_library/SoonaNoResults.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: String,
  },
  accountName: {
    required: true,
    type: String,
  },
});

const { hasCapability: canCreateContract } = useCapability({
  capability: 'create_contracts',
});
const { currentAccountId } = useMe();

const bodyCopy = computed(() => {
  if (canCreateContract.value) return 'Add a contract to get started';

  return 'Contact sales@soona.co to learn more';
});

const headerCopy = computed(() => {
  if (currentAccountId.value === +props.accountId) {
    return 'Your enterprise contracts will show here';
  }

  return `${props.accountName}’s contracts will show here`;
});
</script>

<template>
  <!-- payments_todo: use different image for client-facing version -->
  <SoonaNoResults :image-source="emptyImage">
    <template #header>{{ headerCopy }}</template>
    <template #body>{{ bodyCopy }}</template>
    <template v-if="canCreateContract" #button>
      <SoonaButton
        v-if="canCreateContract"
        element="router-link"
        size="medium"
        :to="`/account/${accountId}/contract/new`"
        variation="solid-black"
      >
        <SoonaIcon name="plus-large" /> New contract
      </SoonaButton>
    </template>
  </SoonaNoResults>
</template>
