<script setup>
import { computed, ref, watchEffect, watch } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useCapability } from 'src/composables/useCapability';
import { useContracts } from '@/composables/contracts/useContracts';
import { useContract } from '@/composables/contracts/useContract';
import { useDebounce } from '@vueuse/core';
import { useCreateRetainerTransaction } from '@/queries/retainer_transactions/useCreateRetainerTransaction';
import { usePriorityErrors } from '@/composables/usePriorityErrors';
import { useRetainer } from '@/queries/retainers/useRetainer';
import { useRouter } from 'vue-router';
import { useSalesTax } from '@/composables/sales_tax/useSalesTax';
import SalesTaxTooltip from '@/components/sales_tax/SalesTaxTooltip.vue';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaError from '@/components/ui_library/SoonaError.vue';
import SoonaForm from '@/components/ui_library/SoonaForm.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import SoonaSelect from '@/components/ui_library/SoonaSelect.vue';
import SoonaTextfield from '@/components/ui_library/SoonaTextfield.vue';

const props = defineProps({
  accountId: {
    type: [Number, String],
    required: true,
  },
});

const creditsToDeduct = ref(0);
const isTyping = ref(false);

const accountId = computed(() => props.accountId);

const router = useRouter();

const { hasCapability: canOverdraftFromRetainer } = useCapability({
  capability: 'overdraft_retainer',
  subjectType: 'account',
  subjectId: accountId,
});

const {
  mutate,
  isPending,
  error: errorCreatingTransaction,
} = useCreateRetainerTransaction(accountId);

const {
  activeContract,
  contracts,
  isLoading: isLoadingContracts,
  error: errorLoadingContracts,
} = useContracts({ accountId, statuses: ['active'] });

const selectedContractId = ref(activeContract.value?.id || null);
const { ugcUnitRate } = useContract({
  accountId,
  contractId: selectedContractId,
});

const contractOptions = computed(() => {
  return contracts.value?.map(contract => ({
    label: `contract ${contract.id}`,
    value: contract.id,
  }));
});

const selectedContract = computed(() => {
  return contracts.value?.find(
    contract => contract.id === selectedContractId.value
  );
});

const subtotal = computed(
  () => ugcUnitRate.value?.rate * creditsToDeduct.value
);

const debouncedSubtotal = useDebounce(subtotal, 1000);

watchEffect(() => {
  if (activeContract.value) {
    selectedContractId.value = activeContract.value.id;
  }
});

watch(creditsToDeduct, () => {
  isTyping.value = true;
});

watch(debouncedSubtotal, () => {
  isTyping.value = false;
});

const { data: retainer } = useRetainer({ accountId });

const {
  totalWithSurchargeDisplay,
  totalWithSurchargeInDollars,
  taxAmountExclusiveDisplay,
  isLoading: isCalculatingSalesTax,
} = useSalesTax({
  accountId,
  preSalesTaxSubtotal: computed(() => debouncedSubtotal.value),
});

const grandTotal = computed(() => {
  if (totalWithSurchargeDisplay.value === '$NaN') return toCurrency(0);

  return totalWithSurchargeDisplay.value;
});

const overdraftLimit = computed(() => activeContract.value?.overdraft_limit);

const isOverdraftingOverLimit = computed(() => {
  return (
    retainer.value?.current_amount - totalWithSurchargeInDollars.value <
    -overdraftLimit.value
  );
});

const disableSubmit = computed(() => {
  if (!selectedContract.value) return true;
  if (creditsToDeduct.value <= 0) return true;
  if (isCalculatingSalesTax.value) return true;
  if (isTyping.value) return true;
  if (isOverdraftingOverLimit.value) return true;

  return false;
});

const remainingFunds = computed(() => {
  const remaining =
    (retainer.value?.current_amount || 0) - totalWithSurchargeInDollars.value;

  return remaining;
});

const isOverdraft = computed(
  () =>
    (retainer.value?.current_amount || 0) - totalWithSurchargeInDollars.value <
    0
);

const disclaimerText = computed(() => {
  if (isOverdraft.value) {
    if (isOverdraftingOverLimit.value) {
      return ['This customer has reached their overdraft limit.'];
    } else if (canOverdraftFromRetainer.value) {
      return [
        'This order will cause an overdraft. Overdrafts should be added to the next customers invoice.',
      ];
    } else {
      return [
        'You have reached your overdraft limit. Contact your account manager to complete order.',
      ];
    }
  } else {
    return [];
  }
});

const deductFunds = () => {
  const deductedFunds = totalWithSurchargeInDollars.value;

  mutate(
    {
      body: {
        amount: deductedFunds,
        contract_id: selectedContract.value.id,
        transaction_type: 'withdrawal',
        reason_code: 'ugc',
      },
    },
    {
      onSuccess: () => {
        router.push(`/account/${accountId.value}/contracts`);
      },
    }
  );
};

const priorityErrors = usePriorityErrors(
  errorLoadingContracts,
  errorCreatingTransaction
);

const isLoading = computed(
  () => isLoadingContracts.value || isCalculatingSalesTax.value
);
</script>

<template>
  <SoonaForm class="deduct-funds-form" @submit="deductFunds">
    <SoonaError v-if="priorityErrors" :priority-errors="priorityErrors" />
    <SoonaLoading
      :is-loading="isPending"
      loading-text="Deducting from balance"
    />
    <SoonaLoading :is-loading="isLoading" />
    <SoonaSelect
      v-model="selectedContractId"
      class="deduct-funds-form__select-contract"
      placeholder="select contract"
      :options="contractOptions"
      :loading="isLoadingContracts"
      :disabled="isLoadingContracts"
    >
      <template #label>Contract</template>
    </SoonaSelect>
    <div class="deduct-funds-form__info">
      <h3 class="u-label--heavy">Account balance</h3>
      <p class="u-headline--heavy deduct-funds-form__total-balance">
        {{ toCurrency(retainer?.current_amount ?? 0) }}
      </p>
      <SoonaTextfield
        v-model="creditsToDeduct"
        label="UGC credits spent"
        name="credits"
        placeholder="10"
        required
        type="number"
      >
        <template #subtext>
          unit rate:
          {{ toCurrency(ugcUnitRate?.rate || 0) }}
        </template>
      </SoonaTextfield>
      <div v-if="isOverdraft" class="deduct-funds-form__disclaimer">
        <SoonaAlert v-for="text in disclaimerText" :key="text">
          {{ text }}
        </SoonaAlert>
      </div>
      <p
        class="u-body--regular deduct-funds-form__line-item deduct-funds-form__line-item--first"
      >
        <span>UGC credits</span>
        <span>{{ creditsToDeduct }}</span>
      </p>
      <p class="u-body--regular deduct-funds-form__line-item">
        <span>Subtotal</span>
        <span>{{ toCurrency(subtotal || 0) }}</span>
      </p>
      <p class="u-body--regular deduct-funds-form__line-item">
        <span class="tooltip"> Estimated sales tax <SalesTaxTooltip /></span>
        <span>{{ taxAmountExclusiveDisplay }}</span>
      </p>
      <p
        class="u-body--regular deduct-funds-form__line-item deduct-funds-form__line-item--last"
      >
        <span>Total</span>
        <span>{{ grandTotal }}</span>
      </p>
      <p class="deduct-funds-form__remaining-balance">
        <span class="u-button--large-caps">Remaining balance</span>
        <span class="u-subheader--heavy">{{
          toCurrency(remainingFunds || 0)
        }}</span>
      </p>
    </div>
    <SoonaButton
      class="deduct-funds-form__submit"
      :disabled="disableSubmit"
      type="submit"
      variation="solid-black"
    >
      Confirm charge
    </SoonaButton>
  </SoonaForm>
</template>

<style lang="scss" scoped>
@use '@/variables';

.deduct-funds-form {
  &__select-contract {
    margin-bottom: 1rem;
  }

  &__info {
    background-color: variables.$periwink-blue-10;
    border: 0.125rem solid variables.$gray-30;
    border-radius: 0.625rem;
    padding: 1.5rem;
    margin-bottom: 1rem;
  }

  h3 {
    color: variables.$gray-60;
  }

  &__total-balance {
    padding-bottom: 1rem;
  }

  .soona-textfield {
    padding-bottom: 0;
  }

  &__line-item {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    &--first {
      margin-top: 1rem;
    }

    &--last {
      padding-bottom: 1rem;
    }
  }

  .tooltip {
    display: flex;
    gap: 0.25rem;
  }

  &__remaining-balance {
    align-items: center;
    border-top: 0.0625rem solid variables.$gray-30;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    padding-top: 1rem;
  }

  &__submit {
    margin-left: auto;
    display: block;
  }
}
</style>
