<script setup>
import { computed } from 'vue';
import { toCurrency } from '@/lib/currency';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import PaginatorFull from '@/components/directory/PaginatorFull.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  transactions: {
    required: true,
    type: Object,
  },
  currentPage: {
    type: Number,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: true,
  },
  totalCount: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

defineEmits(['update:currentPage', 'update:itemsPerPage']);

const router = useRouter();

const transactions = computed(() => props.transactions?.retainer_transactions);

const accountId = computed(() => props.accountId);

const totalPages = computed(() => props.totalPages);
const itemsPerPage = computed(() => Number(props.itemsPerPage) || 10);

const handleRowAction = (details, e = null) => {
  if (!details) return;
  if (details?.order_type === 'shipping') return;
  if (!e || e?.key === 'Enter') {
    router.push({
      name: 'order-view',
      params: { accountId: accountId.value, orderId: details.id },
    });
  }
};

const handleOrderType = transactionable => {
  switch (transactionable?.details?.order_type) {
    case 'down_payment':
      return 'down payment';
    case 'shipping':
      return 'shipping';
    case 'additional_charge':
      return 'charge';
    case 'add_on':
      return 'charge';
  }
};

const handleTransactionType = transaction => {
  switch (transaction?.transaction_type) {
    case 'recharge':
      return { type: 'funds added', color: 'funds-added' };
    case 'withdrawal':
      if (transaction.reason_code === 'ugc') {
        return { type: 'charge', color: 'other' };
      } else {
        return {
          type: handleOrderType(transaction.transactionable),
          color: 'other',
        };
      }
    default:
      return { type: 'other' };
  }
};

const handleServices = transaction => {
  let transactionable = transaction?.transactionable;
  let items = transactionable?.details?.items;
  if (transaction.reason_code === 'ugc') return 'UGC';
  if (!items || items.length === 0) return;

  let formattedItems = items
    .filter(
      item => item.toLowerCase() !== 'photo' && item.toLowerCase() !== 'video'
    )
    .map(item => item[0].toUpperCase() + item.slice(1));

  return formattedItems.length > 3
    ? `${formattedItems[0]}, ${formattedItems[1]}...`
    : formattedItems.join(', ');
};
</script>

<template>
  <div class="contract-order-history-table">
    <table>
      <thead>
        <tr>
          <th>
            <span class="contract-order-history-table__sort">Date</span>
          </th>
          <th>
            <span class="contract-order-history-table__sort">Charged by</span>
          </th>
          <th>
            <span class="contract-order-history-table__sort">Type</span>
          </th>
          <th>
            <span class="contract-order-history-table__sort">Services</span>
          </th>
          <th>
            <span class="contract-order-history-table__sort">Booking ID</span>
          </th>
          <th>
            <span class="contract-order-history-table__sort">Amount</span>
          </th>
          <th>
            <span class="contract-order-history-table__sort">Balance</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="transaction in transactions"
          :key="transaction.id"
          class="u-small--regular contract-order-history-table__row"
          :class="`contract-order-history-table__row--${handleTransactionType(transaction).color}`"
          tabindex="0"
          @click="() => handleRowAction(transaction?.transactionable?.details)"
          @keydown="
            e => handleRowAction(transaction?.transactionable?.details, e)
          "
        >
          <td>{{ dayjs(transaction.date).format('MMM D, YYYY') }}</td>
          <td>
            {{ transaction?.transactionable?.details?.created_by_user_name }}
          </td>
          <td>
            <span
              class="u-badge--small contract-order-history-table__tag"
              :class="`contract-order-history-table__tag--${handleTransactionType(transaction).color}`"
            >
              {{ handleTransactionType(transaction).type }}
            </span>
          </td>
          <td>
            {{ handleServices(transaction) ?? '--' }}
          </td>
          <td>
            <p
              v-if="transaction?.transactionable?.details?.reservation_id"
              class="u-label--heavy"
            >
              #{{ transaction?.transactionable?.details.reservation_id }}
            </p>
            <p v-else>--</p>
          </td>
          <td
            class="u-label--heavy"
            :class="`contract-order-history-table__amount-${handleTransactionType(transaction).color}`"
          >
            {{
              (handleTransactionType(transaction).type === 'funds added'
                ? '+'
                : '-') + toCurrency(transaction.amount)
            }}
          </td>
          <td>
            {{ toCurrency(transaction.retainer_balance_after_transaction) }}
          </td>
        </tr>
      </tbody>
    </table>
    <PaginatorFull
      class="contract-order-history-table__paginator"
      :page="currentPage"
      :records-per-page="itemsPerPage"
      :total-pages="totalPages"
      :total-count="totalCount"
      :page-count="transactions.length"
      @update:page="$emit('update:currentPage', $event)"
      @update:records-per-page="$emit('update:itemsPerPage', $event)"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.contract-order-history-table {
  overflow-x: auto;

  &__paginator {
    margin-top: 1rem;
  }

  table {
    white-space: nowrap;
    width: 100%;
  }

  tr {
    border-bottom: 0.0625rem solid variables.$gray-30;
    padding: 0.75rem 0.5rem;
  }

  tbody {
    tr:last-child {
      border-bottom: none;
    }
  }

  th {
    @include variables_fonts.u-small--heavy;

    color: variables.$gray-60;
    cursor: pointer;
    padding: 0.5rem;
    transition: background-color 0.2s;

    &:hover {
      background-color: variables.$gray-05;
    }

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
      outline-offset: -0.125rem;
    }
  }

  td {
    padding: 1rem 0.5rem;
  }

  &__row {
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: variables.$periwink-blue-10;
    }

    &:focus-visible {
      outline: 0.125rem solid variables.$periwink-blue-60;
      outline-offset: -0.125rem;
    }
  }

  &__tag {
    border-radius: 0.1875rem;
    padding: 0.25rem 0.375rem;

    &--other {
      background-color: variables.$tangerine-20;
      color: variables.$tangerine-80;
    }

    &--photo,
    &--video {
      background-color: variables.$bubbletape-pink-20;
    }

    &--ugc {
      background-color: variables.$green-apple-20;
    }

    &--funds-added {
      background: variables.$avo-toast-30;
      color: var(--Black-Default, #000);
    }
  }

  &__amount-funds-added {
    color: variables.$avo-toast-60;
  }

  &__sort {
    align-items: center;
    display: flex;
    gap: 0.25rem;

    &--desc {
      svg {
        transform: rotate(180deg);
      }
    }

    &--inactive {
      svg {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}
</style>
