<script setup>
import { ref, computed } from 'vue';
import { useMediaQuery } from '@vueuse/core';
import { useAccount } from 'src/composables/useAccount';
import { useFlag } from '@/composables/useFlag';
import { useDraggableDrawer } from '@/composables/useDraggableDrawer';
import { toCurrency } from '@/lib/currency';
import PreferredAd from 'src/components/checkout/PreferredAd.vue';
import ReshopHalloweenBanner from 'src/components/ReshopHalloweenBanner.vue';
import { useShopMore } from '@/queries/shop_more/useShopMore';

const props = defineProps({
  accountId: {
    type: [String, Number],
    default: null,
    required: false,
  },
  canViewPaymentInfo: {
    type: Boolean,
    default: true,
    required: false,
  },
  isCompedOrder: {
    required: false,
    type: Boolean,
  },
  total: {
    type: Number,
    required: true,
  },
  showPreferredAd: {
    type: Boolean,
    default: false,
  },
  cartTotals: Object,
  preferredCredits: Number,
});

const summaryPaymentInner = ref();
const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');

const preferredAdFlag = useFlag('apollo_bag_checkout_preferred_ad');
const paymentsDisablePreferredFlag = useFlag('payments_disable_preferred');
const reshopSaleFlag = useFlag('phoenix_reshop_sale');
const paymentsRetainerMvpFlag = useFlag('payments_retainer_mvp');

const accountId = computed(() => props.accountId);
const { isActiveRetainerClient } = useAccount(accountId);
const { data: facetsData } = useShopMore(
  accountId,
  {
    facets: true,
    searchQuery: '',
    itemsPerPage: 1,
    currentPage: 1,
  },
  {
    enabled: computed(() => !!accountId.value),
  }
);
const isPreferred = computed(() => props.preferredCredits > 0);
const showPreferredReshopSale = computed(() => {
  if (!props.accountId || !isPreferred.value) {
    return false;
  } else {
    return (
      reshopSaleFlag.value && facetsData.value?.pagination?.onSaleCount > 0
    );
  }
});

const { drawerMode, drawerStyle, drawerOpenClose, drawerOpenRatio } =
  useDraggableDrawer(74, summaryPaymentInner);

const isDialog = computed(
  () => !matchMediaIsWide.value && drawerMode.value === 'open'
);

const handleHeaderClick = () => {
  drawerOpenClose(drawerMode.value === 'open' ? 'close' : 'open');
};
</script>

<template>
  <Teleport to="body" :disabled="matchMediaIsWide">
    <div
      v-if="drawerMode === 'open' || drawerMode === 'transitioning'"
      class="summary-payment__bg"
      :style="{ opacity: drawerOpenRatio / 100 }"
      @touchend="drawerOpenClose('close')"
    />
    <div
      v-if="total || $slots['default']"
      v-bind="$attrs"
      class="summary-payment"
      :class="`summary-payment--${drawerMode}`"
      :style="drawerStyle"
      :role="isDialog ? 'dialog' : undefined"
      :aria-modal="isDialog ? true : undefined"
      :aria-labelledby="isDialog ? 'summary-title' : undefined"
    >
      <div ref="summaryPaymentInner" class="summary-payment__inner">
        <ReshopHalloweenBanner
          v-if="matchMediaIsWide && showPreferredReshopSale"
          banner-variant="preferred"
        />
        <div>
          <div
            :class="
              preferredAdFlag &&
              showPreferredAd &&
              !paymentsDisablePreferredFlag
                ? 'summary-payment__summary preferred-ad'
                : 'summary-payment__summary'
            "
          >
            <component
              :is="matchMediaIsWide ? 'div' : 'button'"
              class="summary-payment__summary-header"
              :class="{ 'u-button-reset': !matchMediaIsWide }"
              @click="!matchMediaIsWide ? handleHeaderClick() : undefined"
              ><h2 id="summary-title" class="summary-payment__summary-title">
                summary<template v-if="!matchMediaIsWide"> + payment</template>
              </h2>
              <template v-if="!matchMediaIsWide && isCompedOrder">
                total: <strong>$0</strong>
              </template>
              <template v-else-if="!matchMediaIsWide">
                total: <strong>{{ toCurrency(total) }}</strong>
              </template>
            </component>
            <table class="summary-payment__itemized">
              <thead class="u-visually-hidden">
                <tr>
                  <th scope="col">summary line item</th>
                  <th scope="col">dollar amount</th>
                </tr>
              </thead>
              <slot name="default"></slot>
              <tfoot class="summary-payment__itemized-footer">
                <tr data-cypress="summary-payment-total">
                  <th scope="row">total</th>
                  <td
                    v-if="isCompedOrder"
                    data-cypress="summary-payment-total-amount"
                  >
                    $0
                  </td>
                  <td v-else data-cypress="summary-payment-total-amount">
                    {{ toCurrency(total) }}
                  </td>
                </tr>
              </tfoot>
            </table>
            <slot name="opt_in_banner"></slot>
            <slot name="discount_banner"></slot>
          </div>
          <PreferredAd
            v-if="
              preferredAdFlag &&
              showPreferredAd &&
              !paymentsDisablePreferredFlag
            "
            :preferred-credits="preferredCredits"
            :cart-totals="cartTotals"
          />
        </div>
        <slot name="disclaimer-below-summary" />
        <div v-if="$slots['billing']" class="summary-payment__billing">
          <slot name="billing" />
        </div>
        <slot name="disclaimer-above-payment" />
        <div v-if="canViewPaymentInfo" class="summary-payment__payment">
          <h3
            v-if="!isActiveRetainerClient && !paymentsRetainerMvpFlag"
            class="summary-payment__payment-title"
          >
            Payment<template v-if="!matchMediaIsWide"> info</template>
          </h3>
          <slot name="payment" />
        </div>
        <slot name="terms" />
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.summary-payment {
  position: fixed;
  right: 0;
  left: 0;
  top: 100%;
  background-color: variables.$periwink-blue-10;
  border: 0.0625rem solid variables.$gray-30;
  border-bottom: 0;
  border-radius: 1rem 1rem 0 0;
  filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.27));
  cursor: grab;
  touch-action: none;

  &--open,
  &--transitioning {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    background:
      linear-gradient(variables.$periwink-blue-10 30%, rgba(239, 243, 255, 0))
        center top,
      linear-gradient(rgba(239, 243, 255, 0), variables.$periwink-blue-10 70%)
        center bottom,
      linear-gradient(rgba(0, 0, 0, 0.24), rgba(239, 243, 255, 0)) center top,
      linear-gradient(rgba(239, 243, 255, 0), rgba(0, 0, 0, 0.24)) center bottom;
    background-color: variables.$periwink-blue-10;
    background-repeat: no-repeat;
    background-size:
      100% 2.5rem,
      100% 2.5rem,
      100% 0.75rem,
      100% 0.75rem;
    background-attachment: local, local, scroll, scroll;
  }

  &__inner {
    padding: 1.25rem 1rem 1rem;
  }

  &__summary-header {
    @include variables_fonts.u-label--small;
    order: 1;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
    gap: 0.25rem;
    padding: 1.25rem 1rem;
    margin: -1.25rem -1rem 0;
    width: calc(100% + 2rem);
    position: relative;
    border-radius: 1rem 1rem 0 0;
    outline-offset: -0.125rem;

    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.125rem;
      height: 0.25rem;
      width: 2rem;
      background-color: variables.$gray-40;
      transition:
        background-color 0.1s ease-out,
        box-shadow 0.1s ease-out;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 1rem;
      right: 1rem;
      border-bottom: 0.0625rem solid variables.$gray-30;
    }

    &:hover,
    &:focus-visible {
      &::before {
        background-color: variables.$gray-50;
        box-shadow: inset 0.0625rem 0.0625rem 0.125rem variables.$gray-60;
      }
    }

    strong {
      @include variables_fonts.u-label--heavy;
    }
  }

  &__summary-title {
    @include variables_fonts.u-title--heavy;

    margin-right: auto;
  }

  &__itemized {
    order: 2;
    border-collapse: collapse;
    padding-top: 0.5rem;
    margin-bottom: 1rem;
    color: variables.$black-default;
    width: 100%;

    :slotted(tr > *:last-child) {
      text-align: right;
      padding-left: 0.25rem;
    }

    tr > *:last-child {
      text-align: right;
      padding-left: 0.25rem;
    }

    .summary-payment--closed & {
      visibility: hidden;
    }
  }

  :slotted(.summary-payment__itemized-body) {
    th,
    td {
      @include variables_fonts.u-label--regular;

      color: variables.$black-default;
      padding: 0.25rem 0;
      text-transform: lowercase;
    }

    tr:first-child {
      th,
      td {
        padding-top: 0.5rem;
      }
    }

    tr:last-child {
      th,
      td {
        padding-bottom: 0.5rem;
      }
    }
  }

  &__itemized-subtotal > th:first-child {
    font-weight: 800;
  }

  &__itemized-footer {
    border-top: 0.0625rem solid variables.$gray-30;

    th,
    td {
      @include variables_fonts.u-button--large-caps;

      line-height: 1.42857143;
      color: variables.$black-default;
      padding-top: 0.5rem;
      vertical-align: bottom;
    }

    td {
      @include variables_fonts.u-body--heavy;
    }
  }

  &__billing {
    border-top: 0.125rem solid variables.$gray-30;
    margin: 1rem -1rem;
    padding: 1rem 1rem 0;
  }

  &__payment {
    margin: 0 -1rem;
    padding: 1rem 1rem 0;
    border-top: 0.125rem solid variables.$gray-30;
    .summary-payment--closed & {
      visibility: hidden;
    }

    :deep(.soona-button--primary) {
      width: 100%;
    }

    @media (min-width: variables.$screen-sm-min) {
      :deep(.soona-button--primary) {
        width: inherit;
      }
    }

    :slotted(.summary-section__decline_order) {
      text-align: center;
      margin-top: 0.5rem;
    }
  }

  &__payment-title {
    @include variables_fonts.u-body--heavy;

    margin-bottom: 1rem;
  }

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  @media (min-width: variables.$screen-sm-min) {
    position: static;
    right: auto;
    left: auto;
    top: auto;
    transform: none;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    filter: none;
    cursor: auto;
    touch-action: auto;

    &__inner {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 0;
    }

    &__summary,
    &__payment {
      background-color: variables.$periwink-blue-10;
      border: 0.0625rem solid variables.$gray-30;
      border-radius: 0.625rem;
      padding: 1.25rem 1.5rem;
      display: flex;
      flex-direction: column;
    }

    &__summary.preferred-ad {
      border-bottom: 0;
      border-radius: 0.625rem 0.625rem 0 0;
    }

    &__summary-header {
      display: block;
      padding: 0;
      margin: 0 0 1.25rem;
      width: auto;
      position: static;

      &::after {
        display: none;
      }
    }

    &__summary-title {
      @include variables_fonts.u-headline--heavy;

      margin-right: 0;
    }

    &__itemized {
      border-top: 0;
      margin-bottom: 0;
    }

    :slotted(.summary-payment__itemized-body) {
      th,
      td {
        @include variables_fonts.u-body--regular;
      }

      tr:first-child {
        th,
        td {
          padding-top: 0;
        }
      }

      tr:last-child {
        th,
        td {
          padding-bottom: 0;
        }
      }
    }

    :slotted(.summary-payment__itemized-subtotal) {
      th,
      td {
        position: relative;
        padding-top: 0.75rem;

        &::before {
          content: '';
          position: absolute;
          top: 0.25rem;
          display: block;
          width: 100%;
          border-top: 0.0625rem solid variables.$gray-30;
        }
      }

      th::before {
        width: calc(100% + 0.25rem);
      }

      > th:first-child {
        font-weight: 400;
      }
    }

    &__itemized-footer {
      border-top: 0;

      th,
      td {
        @include variables_fonts.u-subheader--all-caps-black;
      }

      td {
        @include variables_fonts.u-headline--heavy;
      }
    }

    &__billing {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &__payment {
      margin: 0;
      border-top-width: 0.0625rem;
    }

    &__payment-title {
      @include variables_fonts.u-headline--heavy;
    }

    &__summary-header::before,
    &__bg {
      display: none;
    }
  }
}
</style>
