<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';

import { useLiveShootStore } from '../useLiveShootStore';
import { useSoonaToast } from '@/components/ui_library/soona_toast/useSoonaToast';
import { useUpdateDigitalAsset } from '@/queries/digital_assets/useUpdateDigitalAsset';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const { addToast } = useSoonaToast();
const liveShootStore = useLiveShootStore();
const { assetId, assetAccountId } = storeToRefs(liveShootStore);

const { isOriginal, isPhoto, rotation, notRotated } =
  useMediaEditorDigitalAsset(assetAccountId, assetId);

const { mutate: updateDigitalAsset, isPending: isUpdatingDigitalAsset } =
  useUpdateDigitalAsset(assetAccountId);

const isLoading = computed(() => !!isUpdatingDigitalAsset.value);

const rotationMessage = computed(() =>
  notRotated.value ? 'original rotation/0º' : `${rotation.value}º from original`
);

const rotateLeft = () => {
  const existingRotation = rotation.value ?? 0;
  const toRotate = existingRotation === 0 ? 270 : existingRotation - 90;
  updateDigitalAsset(
    {
      assetId: assetId,
      body: {
        rotation: toRotate,
      },
    },
    {
      onError: () => {
        addToast(`error rotating asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};

const rotateRight = () => {
  const existingRotation = rotation.value ?? 0;
  const toRotate = existingRotation === 270 ? 0 : existingRotation + 90;
  updateDigitalAsset(
    {
      assetId: assetId,
      body: {
        rotation: toRotate,
      },
    },
    {
      onError: () => {
        addToast(`error rotating asset`, {
          variation: 'error',
          noDismissButton: true,
        });
      },
    }
  );
};
</script>
<template>
  <SoonaButton
    v-if="isOriginal && isPhoto"
    class="rotate-left"
    variation="icon-gray-outline"
    :loading="isLoading"
    :disabled="isLoading"
    :title="`rotate left 90º (currently ${rotationMessage})`"
    @click="rotateLeft"
  >
    <SoonaIcon name="rotate-left" />
  </SoonaButton>
  <SoonaButton
    v-if="isOriginal && isPhoto"
    class="rotate-right"
    variation="icon-gray-outline"
    :loading="isLoading"
    :disabled="isLoading"
    :title="`rotate right 90º (currently ${rotationMessage})`"
    @click="rotateRight"
  >
    <SoonaIcon name="rotate-right" />
  </SoonaButton>
</template>
