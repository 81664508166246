<script setup>
import { storeToRefs } from 'pinia';
import { useScroll } from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, watch, nextTick } from 'vue';

import { useLiveShootStore } from './useLiveShootStore';
import { useInfiniteDigitalAssets } from '@/composables/useInfiniteDigitalAssets';
import { useReservationDigitalAssets } from '@/queries/reservation_digital_assets/useReservationDigitalAssets';
import { useInfiniteReservationDigitalAssets } from '@/queries/reservation_digital_assets/useInfiniteReservationDigitalAssets';

import LiveGalleryEmpty from './LiveGalleryEmpty.vue';
import SoonaLoading from '@/components/SoonaLoading.vue';
import LiveGalleryFilters from './LiveGalleryFilters.vue';
import { filterLookup } from '../ReservationGalleryFilters';
import LiveDigitalAssetCard from './LiveDigitalAssetCard.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import LiveNewAssetsIndicator from './LiveNewAssetsIndicator.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import LiveMobileExpandedAsset from './mobile/LiveMobileExpandedAsset.vue';
import SmallInfiniteGallery from '@/components/infinite_asset_gallery/SmallInfiniteGallery.vue';
import { useInfiniteGalleryWrapper } from '@/components/infinite_asset_gallery/useInfiniteGalleryWrapper';

const props = defineProps({
  filter: {
    type: [String, null],
    default: null,
  },
  isRetainerClient: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['toggle-center-view']);

const liveShootStore = useLiveShootStore();
const {
  isMobile,
  isDesktop,
  selectedRDA,
  reservationId,
  centerViewOpen,
  galleryHasMinimized,
} = storeToRefs(liveShootStore);

const route = useRoute();
const router = useRouter();

const wrapperRef = ref(null);
const galleryRef = ref(null);
const itemsPerPage = ref(30);
const expandedRow = ref(null);
const scrollPosition = ref(0);
const { y } = useScroll(galleryRef);

const filter = computed(() => props.filter ?? 'all');
const isRetainerClient = computed(() => props.isRetainerClient);
const galleryFilters = computed(() => filterLookup[filter.value]);
const isSingleRow = computed(() => !isDesktop.value);
const currentRowHeight = computed(() => (isSingleRow.value ? 15.625 : 11));

const {
  data: facetsData,
  isSuccess: facetsIsSuccess,
  isLoading: isFacetsLoading,
} = useReservationDigitalAssets(reservationId, {
  itemsPerPage: 1,
  facets: filterLookup,
});

const { gutter, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
  wrapperEl: wrapperRef,
  heightRem: currentRowHeight,
  gapRem: 1,
});

const {
  assetRows,
  fetchPage,
  isLoading,
  // error: infiniteAssetsError,
} = useInfiniteDigitalAssets(
  reservationId,
  {
    gutter,
    rowWidth,
    itemsPerPage,
    height: rowHeight,
    singleRow: isSingleRow,
    query: useInfiniteReservationDigitalAssets,
    filters: galleryFilters,
  },
  { enabled: facetsIsSuccess }
);

watch(assetRows, () => {
  if (selectedRDA.value) {
    const updatedAsset = assetRows.value
      ?.flat()
      .flatMap(assetRow => assetRow.assets)
      .find(asset => asset.id === selectedRDA.value.id);

    if (updatedAsset) {
      liveShootStore.setSelectedRDA(updatedAsset);
    }
  }
});

const toggleCenterView = () => {
  emit('toggle-center-view', {
    initialAsset: assetRows.value?.[0]?.assets?.[0],
  });
};

const setFilter = filter => {
  const updatedRoute = {
    name: route.name,
    params: route.params,
  };

  if (filter) {
    updatedRoute.query = { filter: filter.replace(/\s/g, '_') };
  }

  router.replace(updatedRoute);
};

const findAssetIndex = rda => {
  if (!rda) return 0;

  return assetRows.value?.flat().findIndex(assetRow => {
    return assetRow.assets?.some(a => a.id === rda.id);
  });
};

const selectAsset = (rda, rowIndex) => {
  liveShootStore.setSelectedRDA(rda);

  if (isDesktop.value) {
    scrollPosition.value = findAssetIndex(rda) - 1;
    emit('toggle-center-view', { open: true });
  } else {
    expandedRow.value = rowIndex;
  }
};

watch(galleryHasMinimized, () => {
  nextTick(() => {
    scrollPosition.value = findAssetIndex(selectedRDA?.value) - 1;
  });
});
</script>

<template>
  <div ref="wrapperRef" class="live-gallery">
    <SoonaLoading
      v-if="isLoading || isFacetsLoading"
      is-loading
      is-contained
      loading-text="Preparing photos..."
    />

    <header class="live-gallery__header">
      <div v-if="!isMobile" class="live-gallery__header__title">
        <h3 class="u-body--heavy">assets</h3>

        <SoonaButton
          v-if="centerViewOpen"
          size="medium"
          variation="icon-plain-gray"
          @click="toggleCenterView()"
          @pointerdown="toggleCenterView()"
        >
          <SoonaIcon name="expand-alt" />
          <span class="u-visually-hidden"> expand gallery </span>
        </SoonaButton>
      </div>

      <LiveGalleryFilters
        :model-value="filter"
        :facets="facetsData?.facets"
        :reservation-id="reservationId"
        :is-shrunk="centerViewOpen && isDesktop"
        @update:model-value="$event => setFilter($event)"
      />

      <LiveNewAssetsIndicator
        v-if="y > 500"
        @click="
          galleryRef.rowVirtualizer.scrollToIndex(0, { behavior: 'smooth' })
        "
      />
    </header>

    <SmallInfiniteGallery
      v-if="isLoading || isFacetsLoading || assetRows.length"
      v-slot="{ data, isExpanded, index }"
      ref="galleryRef"
      :key="`gallery-virtual-list-${rowHeight}`"
      v-model:expanded-row="expandedRow"
      :gap="gutter"
      :rows="assetRows"
      :height="rowHeight"
      :initial-scroll-position="scrollPosition"
      class="live-gallery__virtual-gallery"
      scroll-behavior="smooth"
      expands
    >
      <div
        v-for="rda in data.assets"
        :key="rda.id"
        class="live-gallery__card-wrapper"
        :class="{
          'live-gallery__card-wrapper--minimized': centerViewOpen,
        }"
        :style="{
          flexGrow: isDesktop ? (data.width / rowWidth > 0.4 ? 1 : 0) : 1,
        }"
      >
        <LiveDigitalAssetCard
          :reservation-digital-asset="rda"
          :is-retainer-client="isRetainerClient"
          :select-visible="!centerViewOpen || !isDesktop"
          :show-common-actions="selectedRDA?.id !== rda.id || isDesktop"
          :is-being-viewed="
            selectedRDA?.id === rda.id && centerViewOpen && isDesktop
          "
          :style="{
            height: `${rowHeight}px`,
            viewTransitionName: isDesktop ? `asset-${rda.id}` : 'none',
          }"
          @request-page="fetchPage($event)"
          @asset-click="selectAsset(rda, index)"
        />
        <LiveMobileExpandedAsset v-if="isExpanded && !isDesktop" />
      </div>
    </SmallInfiniteGallery>
    <LiveGalleryEmpty v-else />
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';

.live-gallery {
  flex: 1;
  width: 100%;
  display: flex;
  overflow: auto;
  position: relative;
  flex-direction: column;

  &__header {
    padding: 1rem 0rem;
    position: relative;

    @media (min-width: 40rem) {
      padding: 0rem;
      margin-bottom: 0.75rem;
    }

    &__title {
      display: flex;
      min-height: 2.5rem;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem 0.25rem 0.25rem 0.75rem;
    }
  }

  &__virtual-gallery {
    max-height: 100%;
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  }

  &__card-wrapper {
    height: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    flex-direction: column;
    border-radius: 0.625rem;
    max-width: min(23.4375rem, 100%);
    box-shadow: variables.$elevation-0;

    @media (min-width: variables.$screen-lg-min) {
      margin: 0;
      box-shadow: none;

      &--minimized {
        margin: 0 auto;
      }
    }
  }
}
</style>
