<script setup>
import { computed, toRefs, ref } from 'vue';
import { useFlag } from '@/composables/useFlag';

import BulkDownloadDialog from '../BulkDownloadDialog.vue';
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaTooltip from '@/components/ui_library/SoonaTooltip.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
  selectedAssets: {
    required: true,
    type: Array,
  },
});

const { selectedAssets } = toRefs(props);
const showDownloadDialog = ref(false);

const bulkDownloadFlag = useFlag('pegasus_bulk_download');

const downloadableAssetsSelected = computed(() => {
  return selectedAssets.value?.some(
    da => da.ownership === 'customer' && da.visibility === 'all'
  );
});
</script>

<template>
  <SoonaTooltip
    v-if="bulkDownloadFlag && selectedAssets.length < 100"
    placement="top"
  >
    <template
      #default="{
        setRef,
        mouseenter,
        focus,
        mouseleave,
        blur,
        ariaDescribedby,
      }"
    >
      <SoonaButton
        v-bind="$attrs"
        :ref="setRef"
        variation="icon-transparent"
        :aria-describedby="ariaDescribedby"
        :disabled="!downloadableAssetsSelected"
        @blur="blur"
        @focus="focus"
        @mouseenter="mouseenter"
        @mouseleave="mouseleave"
        @on-click="showDownloadDialog = true"
      >
        <SoonaIcon name="arrow-down-to-bracket" />
        <span class="u-visually-hidden">download</span>
      </SoonaButton>
    </template>

    <template #tooltip-content>download</template>
  </SoonaTooltip>

  <BulkDownloadDialog
    v-if="showDownloadDialog"
    :account-id="accountId"
    :selected-assets="selectedAssets"
    @close="showDownloadDialog = false"
  />
</template>
