<script setup>
import SoonaButton from '@/components/ui_library/SoonaButton.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import { computed } from 'vue';
import { useCreateFavoritesCollectionDigitalAsset } from '@/queries/favorites-collection-digital-assets/useCreateFavoritesCollectionDigitalAsset';
import { useDeleteFavoritesCollectionDigitalAsset } from '@/queries/favorites-collection-digital-assets/useDeleteFavoritesCollectionDigitalAssets';
import { useFavoritesCollection } from '@/queries/favorites_collection/useFavoritesCollection';
import { useMe } from '@/composables/user/useMe';
import { useRoute, useRouter } from 'vue-router';
import { useMediaEditorStore } from '@/components/user/anytime/gallery/media-editor/store/useMediaEditorStore';
import { storeToRefs } from 'pinia';
import { useMediaEditorDigitalAsset } from '@/composables/digital_assets/useMediaEditorDigitalAsset';

const mediaEditorStore = useMediaEditorStore();
const { assetId, assetAccountId, isWide } = storeToRefs(mediaEditorStore);

const { favoriteCDAId, rdaLoading } = useMediaEditorDigitalAsset(
  assetAccountId,
  assetId
);

const route = useRoute();
const router = useRouter();

// me
const { currentAccountId } = useMe();

// favorites
const { data: favoriteCollection } = useFavoritesCollection(assetAccountId, {
  enabled: computed(() => !!assetAccountId.value),
});
const favoriteCollectionId = computed(() => favoriteCollection.value?.id);

const isFavorited = computed(() => !!favoriteCDAId.value);

const { mutate: mutateAddToFavorites, isPending: addToFavoritePending } =
  useCreateFavoritesCollectionDigitalAsset(favoriteCollectionId);

const {
  mutate: mutateRemoveFromFavorite,
  isPending: removeFromFavoritesPending,
} = useDeleteFavoritesCollectionDigitalAsset(
  favoriteCollectionId,
  favoriteCDAId
);

// add remove from favorite
const isFavoritable = computed(() => {
  return (
    favoriteCollection.value && currentAccountId.value === assetAccountId.value
  );
});

const addToFavorites = () => {
  if (!favoriteCollectionId.value) return;

  mutateAddToFavorites({
    digital_asset_id: assetId.value,
  });
};

const removeFromFavorites = () => {
  if (!favoriteCollectionId.value || !favoriteCDAId.value) return;

  mutateRemoveFromFavorite(
    {
      digital_asset_id: assetId.value,
    },
    {
      onSuccess: async () => {
        // if we're in the favorites collection view, the current asset will start to 404, so we need to go back
        if (route.name === 'favorites-assets-media-view') {
          await router.push({
            name: 'favorites-assets',
            params: {
              accountId: assetAccountId.value,
            },
          });
        }
      },
    }
  );
};

const isMutating = computed(
  () =>
    rdaLoading.value ||
    addToFavoritePending.value ||
    removeFromFavoritesPending.value
);
</script>

<template>
  <SoonaButton
    class="favorite-toggle"
    :size="isWide ? 'large' : 'medium'"
    :class="{
      'favorite-toggle--selected': isFavorited,
    }"
    variation="icon-gray-outline"
    :data-cypress="
      isFavorited
        ? `button-collection-un-favorite-${assetId}`
        : 'button-favorite'
    "
    :aria-label="
      isFavoritable
        ? isFavorited
          ? 'unfavorite this asset'
          : 'favorite this asset'
        : undefined
    "
    :title="
      isFavoritable
        ? isFavorited
          ? 'unfavorite this asset'
          : 'favorite this asset'
        : undefined
    "
    :disabled="!isFavoritable"
    :loading="isMutating"
    @on-click="isFavorited ? removeFromFavorites() : addToFavorites()"
  >
    <SoonaIcon
      class="favorite-toggle__icon"
      :name="isFavorited ? 'heart-solid' : 'heart'"
    />
  </SoonaButton>
</template>

<style lang="scss" scoped>
@use '@/variables';

.favorite-toggle {
  pointer-events: all;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 0 0 0 0 variables.$gray-30;
  transition:
    background-color 0.1s ease-out,
    box-shadow 0.1s ease-out;

  &:not(:disabled) {
    &:hover,
    &:focus-visible {
      background-color: rgba(255, 255, 255, 0.85);
      box-shadow: 0 0 0 0.125rem variables.$gray-30;
    }

    &:active {
      transition: none;
      box-shadow:
        0 0 0 0.0625rem variables.$white-default,
        0 0 0 0.125rem variables.$periwink-blue-70;
    }
  }

  &__icon {
    color: variables.$gray-60;
  }

  &--selected,
  &--selected:disabled:not(.soona-button--loading) {
    .favorite-toggle__icon {
      color: variables.$friendly-red-50;
    }
  }
}
</style>
