<script setup>
import { computed } from 'vue';
import { useAccount } from '@/composables/useAccount';
import { useAccount as useAccountQuery } from '@/queries/account/useAccount';
import { useCapability } from '@/composables/useCapability';
import { useContracts } from '@/composables/contracts/useContracts';
import AccountBalance from '@/components/user/anytime/contracts/AccountBalance.vue';
import BillingAndOrderHeading from '@/components/user/anytime/billing_and_orders/BillingAndOrderHeader.vue';
import ContractOrderHistory from '@/components/user/anytime/contracts/ContractOrderHistory.vue';
import ContractSummary from '@/components/user/anytime/contracts/ContractSummary.vue';
import NoContracts from '@/components/user/anytime/contracts/NoContracts.vue';

const props = defineProps({
  accountId: {
    required: true,
    type: [Number, String],
  },
});

const accountId = computed(() => props.accountId);
const { data: account } = useAccountQuery(accountId);
const { isActiveRetainerClient } = useAccount(accountId);

const { hasCapability: isSoonaStaff } = useCapability({
  capability: 'ft_soona_staff',
});

const { activeContract } = useContracts({ accountId, statusues: ['active'] });
</script>

<template>
  <section>
    <BillingAndOrderHeading
      v-if="!isSoonaStaff"
      :account-id="accountId"
      :has-contracts="isActiveRetainerClient"
    />
    <template v-if="isActiveRetainerClient && activeContract">
      <AccountBalance
        :account-balance="activeContract.retainer_amount"
        :account-id="accountId"
      />
      <ContractSummary
        :account-id="accountId"
        :contract-id="activeContract.id"
        :is-soona-staff="isSoonaStaff"
      />
      <ContractOrderHistory :account-id="accountId" />
    </template>
    <NoContracts v-else :account-id="accountId" :account-name="account?.name" />
  </section>
</template>
