import { computed, unref, watchEffect } from 'vue';
import { useInfiniteGalleryWrapper } from '../infinite_asset_gallery/useInfiniteGalleryWrapper';

function useMasonryAssets({
  assets,
  wrapper,
  heightRem = 16.5,
  paddingWidthRem = 0,
  assetWidthMultiplier = 1,
}) {
  watchEffect(() => {
    if (import.meta.env.DEV && !unref(assets).length && !unref(wrapper)) {
      console.error('[useMasonryAssets] assets and wrapper are required');
    }
  });

  const { gutter, offsetTop, rowHeight, rowWidth } = useInfiniteGalleryWrapper({
    gapRem: 1,
    heightRem,
    paddingWidthRem,
    wrapperEl: wrapper,
  });

  const getAssetWidth = asset => {
    const da = asset.digital_asset ?? asset;
    return unref(rowHeight) * (da?.preview?.ratio ?? 1) + unref(gutter);
  };

  const assetRows = computed(() => {
    const list = unref(assets);
    const width = unref(rowWidth);
    const rows = [];

    for (let listIx = 0; listIx < list.length; listIx++) {
      let asset = list[listIx];
      const assetWidth = getAssetWidth(asset) * assetWidthMultiplier;
      asset = { ...asset, width: Math.min(assetWidth - unref(gutter), width) };

      const row = rows.at(-1);
      if (rows.some(row => row.width + assetWidth <= width)) {
        const index = rows.findIndex(row => row.width + assetWidth <= width);
        rows[index].assets.push(asset);
        rows[index].width += assetWidth;
      } else if (row && row.width > 0 && assetWidth + row.width <= width) {
        rows.at(-1).assets.push(asset);
        row.width += assetWidth;
      } else {
        rows.push({
          id: asset.id,
          assets: [asset],
          width: Math.min(assetWidth, width),
        });
      }
    }

    return rows;
  });

  return {
    gutter,
    offsetTop,
    rowHeight,
    assetRows,
  };
}

export { useMasonryAssets };
