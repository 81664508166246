<script setup>
import { computed } from 'vue';
import SoonaButton from 'src/components/ui_library/SoonaButton.vue';
import { useCapability } from 'src/composables/useCapability';
import { useContracts } from '@/composables/contracts/useContracts';
import { useRetainer } from '@/queries/retainers/useRetainer';
import SoonaAlert from 'src/components/ui_library/SoonaAlert.vue';
import { toCurrency } from '@/lib/currency';

const props = defineProps({
  accountId: {
    type: [String, Number],
    required: true,
  },
  amount: {
    type: [String, Number],
    required: true,
  },
  collapsed: {
    type: Boolean,
    required: false,
    default: false,
  },
  taxAmount: {
    type: Number,
    required: false,
    default: 0,
  },
  disableConfirmButton: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['payment-action']);

const accountId = computed(() => props.accountId);

const { hasCapability: canWithdrawFromRetainer } = useCapability({
  capability: 'withdraw_from_retainer',
  subjectType: 'account',
  subjectId: accountId,
});

const { hasCapability: canOverdraftFromRetainer } = useCapability({
  capability: 'overdraft_retainer',
  subjectType: 'account',
  subjectId: accountId,
});

const { data: retainer } = useRetainer({ accountId });

const { activeContract } = useContracts({ accountId, statuses: ['active'] });

const overdraftLimit = computed(() => activeContract.value?.overdraft_limit);

const retainerAmount = computed(() => retainer.value?.current_amount);
const amount = computed(() => parseFloat(props.amount));
const taxAmount = computed(() => props.taxAmount);
const totalAmount = computed(() => amount.value + taxAmount.value);

const isOverdraft = computed(
  () => retainerAmount.value - totalAmount.value < 0
);

const isOverdraftingOverLimit = computed(() => {
  return (
    retainer.value?.current_amount - totalAmount.value < -overdraftLimit.value
  );
});

const remainingBalance = computed(() => {
  return Math.abs(retainerAmount.value - totalAmount.value);
});

const handlePaymentAction = () => {
  emit('payment-action');
};

const disableAction = computed(() => {
  if (isOverdraftingOverLimit.value) {
    return true;
  } else if (canWithdrawFromRetainer.value && isOverdraft.value) {
    return false;
  } else if (canOverdraftFromRetainer.value && !isOverdraft.value) {
    return false;
  } else {
    return true;
  }
});

const disclaimerText = computed(() => {
  if (isOverdraft.value) {
    if (isOverdraftingOverLimit.value) {
      return [
        'You have reached your overdraft limit. Contact your account manager to complete order.',
      ];
    } else if (canOverdraftFromRetainer.value) {
      return [
        'This order will cause an overdraft. Overdrafts will be added to your next invoice.',
      ];
    } else {
      return [
        'You have reached your overdraft limit. Contact your account manager to complete order.',
      ];
    }
  } else {
    return [];
  }
});

const termsOfServiceDate = import.meta.env.VITE_TERMS_OF_SERVICE_DATE;
</script>

<template>
  <div class="soona-retainer">
    <h3 class="soona-retainer__header">Payment</h3>
    <div class="soona-retainer__amounts">
      <p class="soona-retainer__balance">
        <span>Account balance</span>
        <span class="soona-retainer__balance-amount">
          {{ toCurrency(retainerAmount) }}
        </span>
      </p>
      <p class="soona-retainer__order_total">
        <span>Total</span>
        <span class="soona-retainer__balance-amount">
          -{{ toCurrency(totalAmount) }}
        </span>
      </p>
    </div>
    <div class="soona-retainer__remaining_balance">
      <span class="soona-retainer__remaining_balance-label"
        >Remaining balance</span
      >
      <span
        class="soona-retainer__balance-amount"
        :class="{ 'negative-balance': isOverdraft }"
      >
        <span v-if="isOverdraft">-{{ toCurrency(remainingBalance) }} </span>
        <span v-else>{{ toCurrency(remainingBalance) }}</span>
      </span>
    </div>
    <div v-if="isOverdraft" class="soona-retainer__disclaimer">
      <SoonaAlert v-for="text in disclaimerText" :key="text">
        {{ text }}
      </SoonaAlert>
    </div>
    <div class="soona-retainer__action">
      <SoonaButton
        type="button"
        data-cypress="payment-submit"
        :disabled="disableAction || disableConfirmButton"
        variation="solid-black"
        class="soona-retainer__action__submit"
        @click="handlePaymentAction"
      >
        <span>Place order</span>
      </SoonaButton>
    </div>
    <div class="terms-of-use-section">
      <p class="terms-of-use-section__copy">
        Our crew commitment: we believe in quality content for all. if you don’t
        LOVE your content <b>we make it right</b>. please review our
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.soona.co/refund-policy"
          class="terms-of-use-section__link"
          @click="cancellationAndRefundPolicyClicked"
          >cancellation and refund policy</a
        >. by placing an order with soona you agree to our
        <a
          href="https://soona.co/terms/"
          target="_blank"
          class="terms-of-use-section__link"
          >terms of service</a
        >

        ({{ termsOfServiceDate }}).
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/variables';
@use '@/variables_fonts';

.soona-retainer {
  padding: 0.5rem 0;
  justify-content: space-between;
  padding: 0rem;

  &__header {
    display: flex;
    font-size: 1.25rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
  }

  &__amounts {
    display: flex;
    flex-direction: column;
    color: variables.$black-default;
    font-style: normal;
    text-align: center;
    gap: 0.25rem;
  }

  &__balance {
    display: flex;

    &-amount {
      margin-left: auto;
    }
  }

  &__order_total {
    display: flex;
    padding-bottom: 1.5rem;

    &-amount {
      margin-left: auto;
    }
  }

  &__remaining_balance {
    display: flex;
    padding-top: 1.5rem;
    border-top: 0.0625rem solid variables.$gray-30;
    @include variables_fonts.u-button--large-caps;

    &-label {
      @include variables_fonts.u-label--regular;
      font-weight: 900;
    }

    .negative-balance {
      color: variables.$friendly-red-70;
    }
  }

  &__action {
    margin-top: 1.5rem;

    &__submit {
      width: 100%;
    }
  }

  .terms-of-use-section {
    margin-top: 1rem;
    line-height: 1rem;

    &__copy {
      @include variables_fonts.u-small--regular;
    }

    &__link {
      text-decoration: underline;
    }

    @media (min-width: variables.$screen-sm-min) {
      display: block;
    }
  }

  &__disclaimer {
    margin-top: 1rem;
  }
}
</style>
