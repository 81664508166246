<script setup>
import { computed } from 'vue';
import winkImage from '@images/wink.gif';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';

const props = defineProps({
  innerElement: {
    type: String,
    default: 'button',
  },
  tagName: {
    type: String,
    default: 'li',
  },
  album: {
    type: Object,
    required: true,
  },
  size: {
    type: String,
    default: 'medium',
    validator: value => {
      return ['medium', 'large'].includes(value);
    },
  },
});

const animationIntensity = computed(() => {
  switch (props.size) {
    case 'large':
      return 0.5;
    case 'medium':
      return 0.85;
    default:
      return 1;
  }
});
</script>

<template>
  <component :is="tagName" class="album-card" :class="`album-card--${size}`">
    <component :is="innerElement" class="album-card__btn" v-bind="$attrs">
      <div class="album-card__folder" :class="`album-card__folder--${size}`">
        <span class="album-card__folder--body" />
        <span class="album-card__folder--cover">
          <img :src="album.preview?.url || winkImage" alt="" />
        </span>
      </div>

      <div class="album-card__card-info">
        <h3 class="u-small--heavy">
          {{ album.title }}
        </h3>
        <div class="album-card__card-bottom">
          <span class="album-card__assets-count u-small--regular">
            <SoonaIcon
              size="small"
              name="image-square"
              class="card__assets-icon"
            />
            {{ album.totalAssets }}
          </span>
        </div>
      </div>
    </component>
  </component>
</template>

<style lang="scss" scoped>
@use '@/variables';

.album-card {
  list-style: none;
  position: relative;

  &--medium {
    flex: 0 0 7.5rem;
  }
  &--large {
    flex: 0 0 13.125rem;
  }

  &__btn {
    gap: 0.5rem;
    display: flex;
    max-width: 100%;
    flex-direction: column;
  }

  &__folder {
    width: 100%;
    display: block;
    position: relative;
    perspective: 800px;
    min-height: 5.25rem;
    aspect-ratio: 10 / 7;
    will-change: transform;
    transition: transform 0.15s ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.25rem;
      border: 0.125rem solid white;
      box-shadow: 0.15rem 0.15rem 0.25rem rgba(0, 0, 0, 0.04);
    }

    &--body {
      inset: 0;
      position: absolute;
      border-radius: 0.25rem;
      flex-direction: column;
      filter: brightness(0.97);
      transform-origin: 60% 100%;
      color: variables.$black-default;
      transition: transform 0.15s ease;
      background: variables.$periwink-blue-20;

      &::before {
        content: '';
        left: 0;
        width: 33%;
        height: 33%;
        top: -0.5rem;
        position: absolute;
        border-radius: 0.25rem;
        background: variables.$periwink-blue-20;
      }
    }

    &--cover {
      inset: 0;
      position: absolute;
      border-radius: 0.25rem;
      transform-origin: 60% 100%;
      padding: 1.125rem 1.625rem;
      background: variables.$periwink-blue-20;
      transition:
        transform 0.15s ease,
        box-shadow 0.15s ease;
    }

    &:hover {
      transform: scale(1.02) translateZ(calc(10px * v-bind(animationIntensity)));

      .album-card__folder--body {
        transform: skewX(calc(4deg * v-bind(animationIntensity)))
          scale(
            calc(1 - (0.06 * v-bind(animationIntensity))),
            calc(1 - (0.02 * v-bind(animationIntensity)))
          );
      }
      .album-card__folder--cover {
        transform: skewX(calc(-4deg * v-bind(animationIntensity)))
          scale(
            calc(1 - (0.06 * v-bind(animationIntensity))),
            calc(1 - (0.06 * v-bind(animationIntensity)))
          );
        box-shadow: -0.15rem -0.15rem 0.25rem rgba(0, 0, 0, 0.04);
      }
    }
  }

  &__card-info {
    gap: 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__card-bottom {
    width: 100%;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__assets-count {
    gap: 0.25rem;
    display: flex;
    align-items: center;
    color: variables.$gray-60;
  }
}
</style>
