<script setup>
import { computed } from 'vue';
import { useFlag } from '@/composables/useFlag';
import { useRoute } from 'vue-router';
import SoonaBackButton from '@/components/ui_library/SoonaBackButton.vue';
import SoonaTab from '@/components/ui_library/SoonaTab.vue';

const props = defineProps({
  accountId: {
    type: String,
    required: true,
  },
  hasContracts: {
    default: false,
    type: Boolean,
  },
});

const route = useRoute();
const paymentsRetainerMvpFlag = useFlag('payments_retainer_mvp');

const accountId = computed(() => props.accountId);
const backLink = computed(() => `/account/${accountId.value}/profile`);
</script>

<template>
  <header>
    <SoonaBackButton
      element="router-link"
      :to="backLink"
      button-text="profile"
    />
    <h1 class="u-headline--heavy orders-heading">Billing & order history</h1>
    <nav v-if="paymentsRetainerMvpFlag" class="order-invoices-tabs">
      <SoonaTab
        :to="{ path: `/account/${accountId}/orders` }"
        :is-selected="
          route.path === `/account/${accountId}/orders` ||
          route.path === `/account/${accountId}/orders/subscription-invoices`
        "
      >
        <template #copy>Overview</template>
      </SoonaTab>
      <SoonaTab
        :to="{ path: `/account/${accountId}/contracts` }"
        :is-selected="route.path === `/account/${accountId}/contracts`"
      >
        <template #copy>Contracts</template>
      </SoonaTab>
    </nav>
  </header>
</template>

<style lang="scss" scoped>
.orders-heading {
  margin: 0.5rem 0 2rem;
}

.order-invoices-tabs {
  display: flex;
  overflow-x: auto;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}
</style>
