import { toValue } from 'vue';
import { http } from '@/config/vue-axios';
import { queryKeys } from '@/queries/query-keys';
import { useQuery } from '@tanstack/vue-query';
import { mapPaginationHeaders } from '@/lib/api-transforms';

/**
 *
 * @param {Object} [params]
 * @param {MaybeRef<number> | number} [params.defaultType]
 * @param {MaybeRef<number> | number | MaybeRef<string> | string} [params.priceType]
 * @param {MaybeRef<number> | number} [params.productId]
 * @param {MaybeRef<string> | string} [params.productName]
 * @param {MaybeRef<string> | string} [params.productType]
 * @param {MaybeRef<number> | number} [params.recurringInterval]
 * @param {MaybeRef<number> | number} [params.retainerOriented]
 * @param {MaybeRef<number>} [params.itemsPerPage]
 * @param {MaybeRef<number>} [params.currentPage]
 * @param {MaybeRef<string>} [params.orderBy]
 * @param {MaybeRef<string>} [params.orderDirection]
 * @param {Object} [queryOptions]
 */
export function usePrices(
  {
    defaultType,
    priceType,
    productId,
    productName,
    productType,
    recurringInterval,
    retainerOriented,
    itemsPerPage = 25,
    currentPage = 1,
    orderBy,
    orderDirection,
  } = {},
  queryOptions = undefined
) {
  return useQuery({
    queryKey: queryKeys.prices({
      defaultType,
      priceType,
      productId,
      productName,
      productType,
      retainerOriented,
      recurringInterval,
      itemsPerPage,
      currentPage,
      orderBy,
      orderDirection,
    }),
    queryFn: async ({ signal }) => {
      const params = {};

      if (currentPage) params['page'] = toValue(currentPage);
      if (itemsPerPage) params['items'] = toValue(itemsPerPage);
      if (orderBy) params['order_by'] = toValue(orderBy);
      if (orderDirection) params['direction'] = toValue(orderDirection);

      if (!isNaN(toValue(defaultType)))
        params['default'] = toValue(defaultType);
      if (toValue(priceType)) params['price_type'] = toValue(priceType);
      if (toValue(productId)) params['product_id'] = toValue(productId);
      if (toValue(productName)) params['product_name'] = toValue(productName);
      if (toValue(productType)) params['product_type'] = toValue(productType);
      if (toValue(recurringInterval)) {
        params['recurring_interval'] = toValue(recurringInterval);
      }
      if (toValue(retainerOriented)) {
        params['retainer_oriented'] = toValue(retainerOriented);
      }

      const response = await http.get(`/prices`, {
        params,
        signal,
      });

      return {
        pagination: mapPaginationHeaders(response.headers),
        prices: response.data?.prices || [],
      };
    },
    ...queryOptions,
  });
}
