<template>
  <div class="DisplayCrewAssignment">
    <div class="provider-meta-row">
      <a
        class="provider-meta-row__provider-avatar"
        :href="`/user/anytime#/account/${crewAssignment.account_id}/assignments`"
        target="_blank"
      >
        <SoonaAvatar
          :name="crewAssignment.user_name"
          :src="crewAssignment.user_avatar"
          size="2.5rem"
          type="user"
        />
        <span class="u-visually-hidden">{{ crewAssignment.user_name }}</span>
        <AcceptedIcon
          v-if="crewAssignment.status === 'accepted'"
          class="status-icon"
          aria-hidden="true"
        />
        <PendingIcon
          v-else-if="
            crewAssignment.status === 'sent' ||
            crewAssignment.status === 'reminder_sent'
          "
          class="status-icon"
          aria-hidden="true"
        />
      </a>
      <div class="provider-meta-row__name-role">
        <p class="crew-name" data-cypress="crew-assignment-user-name">
          {{ crewAssignment.user_name }}
        </p>
        <p v-if="crewName" class="profile-name">model: {{ crewName }}</p>
        <p>
          <b class="subtitle is-7">{{ crewAssignment.crew_role }}</b>
        </p>
      </div>

      <div v-if="isFtSoonaStaff">
        <SoonaDropdownMenu
          title="update assignment"
          variation="icon-plain-gray"
          size="large"
        >
          <template #trigger-content>
            <SoonaIcon name="dots-vertical" />
          </template>
          <template #default="{ keydown, mouseover, clickCapture }">
            <SoonaDropdownMenuItem>
              <button
                class="update-dropdown-btn"
                role="menuitem"
                @click.prevent="updateCrewAssignment(crewAssignment)"
                @click.capture="clickCapture"
                @keydown="keydown"
                @mouseover="mouseover"
              >
                <SoonaIcon name="pen-square" size="medium" /> edit note
              </button>
            </SoonaDropdownMenuItem>
            <SoonaDropdownMenuItem>
              <button
                class="update-dropdown-btn"
                role="menuitem"
                @click.prevent="removeCrewAssignment(crewAssignment)"
                @click.capture="clickCapture"
                @keydown="keydown"
                @mouseover="mouseover"
              >
                <SoonaIcon name="trash" size="medium" /> remove crew
              </button>
            </SoonaDropdownMenuItem>
          </template>
        </SoonaDropdownMenu>
      </div>
    </div>
    <blockquote
      v-if="!!crewAssignment.note"
      class="assignment-note column is-11"
    >
      {{ crewAssignment.note }}
    </blockquote>
    <CrewAssignmentModal
      v-if="showAssignmentModal"
      :modal-visible="showAssignmentModal"
      :close-modal="closeAssignmentModal"
      :assign-crew-directly="false"
      :edit-assignment="true"
      :reservation-id="crewAssignment.reservation_id"
      :prefilled-pro-service-profile-id="proServiceProfileId"
      :prefilled-user="crewMember"
      :prefilled-reservation-service="{
        title: crewAssignment.requirement_numbered_title,
        role: crewAssignment.crew_role,
        id: crewAssignment.pro_service_requirement_id,
      }"
      :prefilled-note="crewAssignment.note"
      :prefilled-crew-assignment-id="crewAssignment.id"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { mapActions } from 'vuex';
import { useCapability } from '@/composables/useCapability';
import PendingIcon from 'src/components/svgs/PendingIcon.vue';
import AcceptedIcon from 'src/components/svgs/AcceptedIcon.vue';
import CrewAssignmentModal from './CrewAssignmentModal.vue';
import { useGetCrewMember } from 'src/queries/useGetCrewMember';
import SoonaDropdownMenu from 'src/components/ui_library/SoonaDropdownMenu.vue';
import SoonaDropdownMenuItem from 'src/components/ui_library/SoonaDropdownMenuItem.vue';
import SoonaIcon from '@/components/ui_library/soona_icon/SoonaIcon.vue';
import SoonaAvatar from '@/components/ui_library/SoonaAvatar.vue';

export default {
  name: 'DisplayCrewAssignment',
  components: {
    SoonaAvatar,
    SoonaIcon,
    PendingIcon,
    AcceptedIcon,
    CrewAssignmentModal,
    SoonaDropdownMenu,
    SoonaDropdownMenuItem,
  },
  props: {
    crewAssignment: Object,
    reservationId: Number,
    removeCrew: { type: Function },
  },
  setup(props) {
    const assignment = computed(() => props.crewAssignment);
    const assignmentUserId = computed(() => props.crewAssignment.user_id);
    const proServiceProfileId = computed(() => {
      return assignment.value.pro_service_profile?.id
        ? assignment.value.pro_service_profile?.id
        : null;
    });

    const crewName = computed(() => {
      return assignment.value.pro_service_profile?.name
        ? assignment.value.pro_service_profile?.name
        : assignment.value.profile_full_name;
    });
    const { data: crewMember } = useGetCrewMember(assignmentUserId);

    const { hasCapability: isFtSoonaStaff } = useCapability({
      capability: 'ft_soona_staff',
    });

    return {
      crewName,
      crewMember,
      isFtSoonaStaff,
      proServiceProfileId,
    };
  },
  data() {
    return {
      showAssignmentModal: false,
    };
  },
  methods: {
    ...mapActions(['removeCrewAssignmentFromReservation']),
    removeCrewAssignment(crew) {
      this.removeCrew(crew);
    },
    updateCrewAssignment() {
      this.showAssignmentModal = true;
    },
    closeAssignmentModal() {
      this.showAssignmentModal = false;
    },
    openProfile() {
      window.open(
        `/user/anytime#/account/${this.crewAssignment.account_id}/assignments`
      );
    },
  },
};
</script>
<style lang="scss" scoped>
@use '@/variables';

.DisplayCrewAssignment {
  .assignment-note {
    padding: 0;
    padding-left: 0.375rem;
    margin-left: 0.75rem;
    border-left: solid 0.125rem variables.$periwink-blue-70;
  }

  .provider-meta-row {
    display: flex;
    gap: 1rem;

    &__provider-avatar {
      flex: 0 0 2.5rem;
      align-self: flex-start;
      position: relative;

      .status-icon {
        position: absolute;
        bottom: 2rem;
        right: -0.25rem;
      }
    }

    &__name-role {
      flex: 1 1 auto;
    }
  }
  .crew-name {
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }
  .profile-name {
    font-size: 0.75rem;
    color: #4a4a4a;
    font-weight: 800;
  }

  .update-dropdown-btn {
    align-items: center;
    display: flex;
    gap: 0.5rem;
  }
}
</style>
