<script setup>
import { computed, ref, useTemplateRef, watch } from 'vue';
import { useMediaQuery, useTimeoutFn } from '@vueuse/core';
import SoonaSkeleton from 'src/components/ui_library/SoonaSkeleton.vue';
import BeatLoader from '@/components/shared/BeatLoader.vue';
import { Gray40 } from '@/variables.module.scss';

const props = defineProps({
  url: {
    type: String,
    default: undefined,
  },
  previewUrl: {
    type: String,
    default: undefined,
  },
  width: {
    type: Number,
    default: undefined,
  },
  height: {
    type: Number,
    default: undefined,
  },
  isFileLoading: {
    type: Boolean,
    default: false,
  },
  setImgRef: {
    type: Function,
    default: () => {},
  },
});

const emit = defineEmits(['onHighResLoaded', 'onPreviewImgLoaded']);

const url = computed(() => props.url);
const previewUrl = computed(() => props.previewUrl);
const width = computed(() => props.width);
const height = computed(() => props.height);
const isFileLoading = computed(() => props.isFileLoading);

const isPreviewImgLoaded = ref(false);
const isLargerImgLoaded = ref(false);
const showHighResLoading = ref(false);
const matchMediaIsWide = useMediaQuery('(min-width: 48rem)');

const largeImageElement = useTemplateRef('large-image');
const previewImageElement = useTemplateRef('preview-image');

watch(
  isLargerImgLoaded,
  loaded => {
    // we use a v-show below so that the larger image is loaded at the same time
    // as the preview. Need to make sure we set the large img el as the ref once
    // is visible and the preview el is v-if'd right off the page.
    if (loaded) {
      props.setImgRef(largeImageElement.value);
    } else {
      props.setImgRef(previewImageElement.value);
    }
  },
  { immediate: true }
);

const { start: startHighResLoadingTimer, stop: stopHighResLoadingTimer } =
  useTimeoutFn(
    () => {
      showHighResLoading.value = true;
    },
    100,
    { immediate: false }
  );

const assetAspectRatio = computed(() => width.value / height.value);

watch(url, () => {
  isPreviewImgLoaded.value = false;
  isLargerImgLoaded.value = false;
  showHighResLoading.value = false;
});

const handlePreviewImgLoaded = () => {
  isPreviewImgLoaded.value = true;
  startHighResLoadingTimer();
  emit('onPreviewImgLoaded');
};

const handleLargerImgLoaded = () => {
  isLargerImgLoaded.value = true;
  isPreviewImgLoaded.value = true;
  stopHighResLoadingTimer();
  emit('onPreviewImgLoaded');
  emit('onHighResLoaded');
};
</script>

<template>
  <img
    v-show="isLargerImgLoaded"
    ref="large-image"
    :key="url"
    class="media-asset-img__img"
    :src="url"
    :width="width"
    :height="height"
    alt=""
    @load="handleLargerImgLoaded"
  />
  <img
    v-if="!isLargerImgLoaded"
    ref="preview-image"
    :key="previewUrl"
    class="media-asset-img__img"
    :src="previewUrl"
    :width="width"
    :height="height"
    alt=""
    @load="handlePreviewImgLoaded"
  />
  <SoonaSkeleton
    v-if="isFileLoading || !isPreviewImgLoaded"
    class="media-asset-img__skeleton"
  />
  <small
    v-if="showHighResLoading && isPreviewImgLoaded && !isLargerImgLoaded"
    class="media-asset-img__loading-higher-res"
  >
    <BeatLoader :color="Gray40" size="0.625em" />
    <template v-if="matchMediaIsWide">
      loading higher resolution preview
      <BeatLoader :color="Gray40" size="0.625em" />
    </template>
  </small>
</template>

<style lang="scss" scoped>
@use '@/variables';

.media-asset-img {
  &__img {
    display: block;
    max-height: 100%;
    height: 100vh;
    object-fit: contain;
    width: auto;
  }

  &__skeleton {
    --assetAspectRatio: v-bind('assetAspectRatio');
    aspect-ratio: var(--assetAspectRatio, auto);
    position: absolute;
    z-index: -1;
    height: 100%;
  }

  &__loading-higher-res {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 0.5rem;
    position: absolute;
    margin-top: 0.125rem;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    color: variables.$gray-60;
    font-style: italic;
    font-size: 0.625rem;
    line-height: 1;
  }
}
</style>
